import { get, download, execute } from 'services/Middleware';
import { IAddRecipeToMealPlanCommand, IArchiveMealPlanCommand, ICreateMealPlanCommand, IDeleteMealPlanCommand, IRestoreMealPlanCommand, IUpdateExportOptionsCommand, IUpdateMealPlanCommand, IUpdateShoppingListCommand } from './MealPlanCommands';

/* Queries */

export const search = (keywords: string) => {
    let path = `/mealplans/list?keywords=${keywords.toString()}`;
    return get(path);
}

export const getById = (id: string) => {
    const path = `/mealplans/get?id=${id.toString()}`;
    return get(path);
}

export const getRecipeCellDisplay = (id: string) => {
    const path = `/mealplans/recipeCellDisplay?id=${id.toString()}`;
    return get(path);
}

export const getShoppingList = (id: string) => {
    const path = `/mealplans/getShoppingList?id=${id.toString()}`;
    return get(path);
}

export const getExportOptions = (id: string) => {
    const path = `/mealplans/getExportOptions?id=${id.toString()}`;
    return get(path);
}

export const getLastEditedId = () => {
    const path = `/mealplans/getLastEditedMealPlanId`;
    return get(path);
}

export const exportPdf = (id: string) => {
    const path = `/mealplans/exportPdf?id=${id.toString()}`;
    return download(path);
}

/* Commands */

export const create = (command: ICreateMealPlanCommand) => {
    return execute('CreateMealPlan', command);
}

export const update = (command: IUpdateMealPlanCommand) => {
    return execute('UpdateMealPlan', command);
}

export const archive = (command: IArchiveMealPlanCommand) => {
    return execute('ArchiveMealPlan', command);
}

export const restore = (command: IRestoreMealPlanCommand) => {
    return execute('RestoreMealPlan', command);
}

export const del = (command: IDeleteMealPlanCommand) => {
    return execute('DeleteMealPlan', command);
}

export const addRecipe = (command: IAddRecipeToMealPlanCommand) => {
    return execute('AddRecipeToMealPlan', command);
}

export const updateShoppingList = (command: IUpdateShoppingListCommand) => {
    return execute('UpdateShoppingList', command);
}

export const updateExportOptions = (command: IUpdateExportOptionsCommand) => {
    return execute('UpdateExportOptions', command);
}

export const MealPlanService = {
    search,
    getById,
    getRecipeCellDisplay,
    getShoppingList,
    getExportOptions,
    getLastEditedId,
    exportPdf,
    create,
    update,
    archive,
    restore,
    del,
    addRecipe,
    updateShoppingList,
    updateExportOptions,
}