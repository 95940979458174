import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { GridCellProps } from "@progress/kendo-react-grid";

export const GridRowCommandsCell = (onRemove) => (props: GridCellProps) => {

    const deleteItem = () => {
        onRemove(props);
    }

    return (
        <td className='grid-row-commands-cell'>
            <ButtonGroup>
                <Button type={"button"} onClick={deleteItem}><Icon path={mdiDelete} /></Button>
            </ButtonGroup>
        </td>
    );
}