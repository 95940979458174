import { useEffect, useState } from 'react';
import { AuthService } from 'services/AuthService';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { SignUpForm } from './SignUpForm';
import { useStripePublicKey } from 'hooks/useStripePublicKey';

export const SignUp = () => {
    
    // Stripe
    const stripePromise = loadStripe(useStripePublicKey());
    const [clientSecret, setClientSecret] = useState('');
    
    const stripeOptions = {
      clientSecret: clientSecret,
    }

    useEffect(() => {
      AuthService.getPaymentIntent().then(result => {
        if (!!result.clientSecret) {
          setClientSecret(result.clientSecret);
        }
      });
    }, []);

    return (
    <>
    <div className="row">
        <div className="col col-md-12  col-lg-6 offset-lg-3">
          <div className="sign-up-tile">
            <h1>Create Account</h1>
            {!!clientSecret &&
              <Elements stripe={stripePromise} options={stripeOptions}>
                <SignUpForm />
              </Elements>
            }
          </div>
        </div>
    </div>
    </>
    );
}