import { Field, FieldArray, FieldArrayRenderProps, FieldRenderProps, FieldWrapper, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import FileSaver from 'file-saver';
import { MealPlanService } from "./MealPlanService";
import { Alert } from 'components/Alert';
import { useListFunctions } from "hooks/useListFunctions";
import { Card, CardBody, CardFooter, CardHeader, Label, Table, Alert as ReactstrapAlert } from "reactstrap";
import Icon from "@mdi/react";
import { mdiArchive, mdiArrowLeft, mdiCheck, mdiCheckboxBlankOutline, mdiClose, mdiCross, mdiDelete, mdiFilePdfBox, mdiPlus, mdiRestore } from "@mdi/js";
import { IArchiveMealPlanCommand, IDeleteMealPlanCommand, IRestoreMealPlanCommand, IUpdateExportOptionsCommand, IUpdateShoppingListCommand } from "./MealPlanCommands";
import { IApiResponse, IButtonGroupRenderProps, IUserAuth } from "common/Interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { EditMealPlanExportOptionsInitialState, IEditMealPlanExportOptions } from "./MealPlanInterfaces";

export const FormGridContext = createContext<{
    onChangeCover: (chosenIndex: number) => void;
    parentField: string;
  }>({} as any);

export const ExportMealPlan = (props: { auth: IUserAuth }) => {

    const editorRef = useRef<Editor>();
    
    // Setup View
    const { id } = useParams();
    const nav = useNavigate();
    const [view, setView] = useState<IEditMealPlanExportOptions>(EditMealPlanExportOptionsInitialState);
    const [exportingPdf, setExportingPdf] = useState(false);

    // Alerts
    const alertRef = useRef(null);

    // Load Data
    useEffect(() => {
        if (!!id){
            MealPlanService.getExportOptions(id).then(result => {
                setView(result);
            });
        }
    }, []);

    // Actions
    const saveChangesAndExport = (formData: IUpdateExportOptionsCommand) => {
        formData = prepareForSave(formData);
        MealPlanService.updateExportOptions(formData).then(result => {
            handleResponseAndReload(result);
            exportPdf();
        });
    }

    const exportPdf = () => {
        setExportingPdf(true);
        MealPlanService.exportPdf(id).then(result => {
            FileSaver.saveAs(result, 'MealPlan.pdf');
        }).finally(() => setExportingPdf(false));
    }

    const prepareForSave = (formData) => {

        if (editorRef.current) {
            const view = editorRef.current.view;

            if (view) {
                formData.introduction = EditorUtils.getHtml(view.state);
            }
        }
        return formData;
    }

    const closeItem = () => {
        nav('/MealPlans/Edit/' + id);
    }

    const handleResponseAndReload = (data: IApiResponse, successMessage?: string) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }

        alertRef.current.showSuccess(successMessage);
        MealPlanService.getExportOptions(id).then(result => setView(result));
    };

    const CheckboxCell = (props: GridCellProps) => {
        return (
          <td className={'text-center'}>
            <Field
                component={Checkbox}
                name={`recipes[${props.dataIndex}].${props.field}`}
                size={'large'}
            />
          </td>
        );
      };

      const CoverCheckboxCell = (props: GridCellProps) => {
        
        const { onChangeCover } = useContext(FormGridContext);

        const onToggle = () => {
            onChangeCover(props.dataIndex);   
        }

        return (
          <td className={'text-center'}>
            <Field
                component={Checkbox}
                name={`recipes[${props.dataIndex}].${props.field}`}
                size={'large'}
                onChange={onToggle}
            />
          </td>
        );
      };

    const RecipeCell = (props: GridCellProps) => {

        const i = props.dataItem;

        return (
            <td>{i.amount} {i.name} {i.optional && <i>- Optional</i>}</td>
        )
    }

    const ImageDisplayCell = (props: GridCellProps) => {
        return (
            <td>
                <img src={props.dataItem.imageUri} className='recipe-image' />
            </td>
        )
    }

    const RecipeGrid = (props: FieldArrayRenderProps) => {
        const { validationMessage, visited, name, formRenderProps } = props;

        // Update a row in the grid
        const onChangeCover = (chosenIndex) => {

            for (let i = 0; i < view.recipes.length; i++) {
                if (i != chosenIndex) {
                    formRenderProps.onChange('recipes[' + i + '].useAsCover', { value: false });
                }
            }
        };
        
        return (
            <>
                <FormGridContext.Provider value={{ onChangeCover, parentField: name }}>
                    <Grid data={props.value} scrollable={'none'}>
                        <GridColumn field='imageUri' title='Image' cell={ImageDisplayCell} width={100} />
                        <GridColumn field='name' title='Name' cell={RecipeCell} />
                        <GridColumn field='useAsCover' title='Cover' cell={CoverCheckboxCell} width={80} />
                        <GridColumn field='exclude' title='Exclude' cell={CheckboxCell} width={80} />
                    </Grid>
                </FormGridContext.Provider>
            </>
        )
    }

    const Buttons = (buttonRenderProps: IButtonGroupRenderProps) => {
        return (
            <ButtonGroup>
                <Button type={"submit"} hidden={!buttonRenderProps.allowSubmit || exportingPdf}><Icon path={mdiCheck} />Save and Export</Button>
                <Button type={"button"} hidden={buttonRenderProps.allowSubmit || exportingPdf} onClick={exportPdf}><Icon path={mdiCheck} />Export</Button>
                <Button type={"button"} onClick={closeItem}><Icon path={mdiArrowLeft} />Back to Meal Plan</Button>
            </ButtonGroup>
        );
    }

    const RichTextEditor = (fieldRenderProps : FieldRenderProps) => {
        const { value, onChange } = fieldRenderProps;
        const { Undo, Redo, FontSize, FormatBlock, Bold, Italic, Underline, Strikethrough, OrderedList, UnorderedList, Indent, Outdent,
            AlignLeft, AlignCenter, AlignRight, Link, Unlink } = EditorTools;

        return (
            <Editor ref={editorRef} value={value} onChange={onChange}
            defaultEditMode='div'
            tools={[[Undo, Redo],
                [Bold, Italic, Underline, Strikethrough],
                [OrderedList, UnorderedList, Indent, Outdent],
                FontSize,
                [AlignLeft, AlignCenter, AlignRight],
                [Link, Unlink]]} />
        );
    }

    return (
        <div>
            <div className='form-container mealplan-export-container'>
            <Form initialValues={view}
                    key={JSON.stringify(view)}
                    onSubmit={saveChangesAndExport}
                    render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h1>Export Options</h1>
                            </div>
                            <div className='col-md-6 text-right'>
                                <Buttons allowSubmit={formRenderProps.allowSubmit} />
                            </div>
                        </div>
                        <div className='row'>
                            <ReactstrapAlert color='light' className="text-center" hidden={!exportingPdf}>
                                <span className='loader inline mr-1'></span> Creating your PDF. This could take a few minutes.
                            </ReactstrapAlert>
                        </div>
                        {view.mealPlanId && (
                        <>
                            <FieldWrapper>
                                <Label>Meal Plan Notes</Label>
                                <Field name={'introduction'} component={RichTextEditor} />
                            </FieldWrapper>
                            <FieldWrapper>
                                <Label>Recipes</Label>
                                <FieldArray name={'recipes'} component={RecipeGrid} key={'cellRecipeId'} formRenderProps={formRenderProps} />
                            </FieldWrapper>
                        </>
                        )}
                    </FormElement>
                )} />
            <Alert ref={alertRef} />
            </div>
        </div>
    );
}