import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { UserService } from 'services/UserService';
import { UpdatePaymentForm } from './UpdatePaymentForm';
import { IUserAuth } from 'common/Interfaces';
import { Alert, Card, CardBody, CardHeader, Label } from 'reactstrap';
import { SubscriptionDetailsBanner } from 'components/SubscriptionDetailsBanner';
import { EditProfileInitialState, IEditProfile } from './UserInterfaces';
import { useStripePublicKey } from 'hooks/useStripePublicKey';

export const ManageSubscription = (props: { auth: IUserAuth }) => {
    
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState<IEditProfile>(EditProfileInitialState);
    const [lastError, setLastError] = useState('');

    // Stripe
    const stripePromise = loadStripe(useStripePublicKey());
    const [clientSecret, setClientSecret] = useState('');
    
    const stripeOptions = {
      clientSecret: clientSecret,
    }

    useEffect(() => {
      setLoading(true);

      UserService.getProfileForEdit()
            .then(result => setView(result))
            .finally(() => setLoading(false));

      UserService.getUpdateSetupIntent().then(result => {
        if (!result.success && !!result.Title) {
          setLastError(result.Title);
        }
        if (!!result.clientSecret) {
          setClientSecret(result.clientSecret);
          setLastError('');
        }
      });
    }, []);

    return (
    <>
    <div className="row">
      <div className='col-md-12'>
          <h1>Manage Subscription</h1>
          <p>Use this page to manage your subscription and payment details.</p>
          <SubscriptionDetailsBanner expiry={view.expiry} />
          <div className='row'>
            <div className='col-md-6'>
            <Card className='mb-4'>
                <CardHeader>
                    Update Payment Details
                </CardHeader>
                <CardBody>
                {!!clientSecret &&
                  <Elements stripe={stripePromise} options={stripeOptions}>
                    <UpdatePaymentForm />
                  </Elements>
                }
                <Alert isOpen={!!lastError} color="danger">{lastError}</Alert>
                </CardBody>
            </Card>
            </div>
            <div className='col-md-6'>
              
            </div>
          </div>
      </div>
    </div>
    </>
    );
}