import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useDebouncedEffect } from "hooks/useDebouncedEffect";
import { useListFunctions } from "hooks/useListFunctions";
import { MealPlanService } from "./MealPlanService";
import { mdiInformation, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { IUserAuth } from "common/Interfaces";


export const MealPlans = (props: { auth: IUserAuth }) => {

    const list = useListFunctions();

    // Navigation
    const navigate = useNavigate();
    const addMealPlan = () => navigate('/MealPlans/Edit');
    const editMealPlan = (id: string) => navigate('/MealPlans/Edit/' + id);

    const onLoad = useEffect(() => {
        runQuery();
    }, []);

    // Search
    useDebouncedEffect(() => {
        runQuery();
    }, [list.searchText], 1000, list.firstCallMade);

    const runQuery = () => {
        list.setFirstCallMade(true);
        list.setLoading(true);
        list.setRows([]);

        MealPlanService.search(list.searchText).then(results => list.setRows(results));
    };

    // Meal Plan Limits
    const maxAllowed = 100;
    const canCreateMore = maxAllowed > list.rows.length;

    return (
        <div>
            <div className='row mealplan-list'>
                <div className='col-md-6'>
                    <h1>Meal Plans</h1>
                </div>
                <div className='col-md-6 text-right'>
                    <div className='created-totals'>{list.rows.length} meal plan{list.rows.length == 1 ? '' : 's'} created out of {maxAllowed} available</div>
                    <ButtonGroup>
                        <Button type={"button"} hidden={!canCreateMore} onClick={() => addMealPlan()}><Icon path={mdiPlus} />Create</Button>
                    </ButtonGroup>
                </div>
            </div>
            <Grid data={list.rows.slice(list.page.skip, list.page.take + list.page.skip)}
                pageable={true} skip={list.page.skip} take={list.page.take} total={list.rows.length} onPageChange={list.gridPageChange}
                onRowClick={(e) => editMealPlan(e.dataItem.id)}>
                <Column field="name" title="Meal Plan" />
            </Grid>
        </div>
    );
}