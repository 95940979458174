import 'assets/scss/Variables.scss';
import "@progress/kendo-theme-bootstrap/dist/all.scss";
import 'bootstrap/scss/bootstrap.scss';
import 'assets/scss/App.scss';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import _ from "lodash";
import { AuthService } from 'services/AuthService';
import { Layout } from 'components/Layout';
import { Overview } from 'pages/Overview/Overview';
import { Ingredients } from 'pages/Ingredients/Ingredients';
import { Login } from 'pages/Authentication/Login';
import { Logout } from 'pages/Authentication/Logout';
import { EditIngredient } from 'pages/Ingredients/EditIngredient';
import { Users } from 'pages/Users/Users';
import { EditUser } from 'pages/Users/EditUser';
import { Recipes } from 'pages/Recipes/Recipes';
import { ViewRecipe } from 'pages/Recipes/ViewRecipe';
import { EditRecipe } from 'pages/Recipes/EditRecipe';
import { MealPlans } from 'pages/MealPlans/MealPlans';
import { EditMealPlan } from 'pages/MealPlans/EditMealPlan';
import { ShoppingList } from 'pages/MealPlans/ShoppingList';
import { ExportMealPlan } from 'pages/MealPlans/ExportMealPlan';
import { MealPlansRedirect } from 'pages/MealPlans/MealPlansRedirect';
import { IUserAuth } from 'common/Interfaces';
import { EditProfile } from 'pages/Users/EditProfile';
import { EditPassword } from 'pages/Users/EditPassword';
import { Tutorials } from 'pages/Tutorials/Tutorials';
import { PrivacyPolicy } from 'pages/Overview/PrivacyPolicy';
import { TermsAndConditions } from 'pages/Overview/TermsAndConditions';
import { SignUp } from 'pages/Authentication/SignUp';
import { PaymentStatus } from 'pages/Authentication/PaymentStatus';
import { ManageSubscription } from 'pages/Users/ManageSubscription';
import { UpdatePaymentResult } from 'pages/Users/UpdatePaymentResult';
import { Home } from 'pages/Authentication/Home';

export const App = () => {

    const navigate = useNavigate();

    const unauthenticatedUser: IUserAuth = {
      authenticated: false,
      username: '',
      role: '',
      isAdmin: false,
      isActiveSubscription: false,
    }

    const [auth, setAuth] = useState<IUserAuth>(unauthenticatedUser);

    useEffect(() => {
        AuthService.currentUser.subscribe(data => {          
          
          if(!!data) {

            // Valid signed in user
            const details = AuthService.getUserDetails(data.token);

            setAuth({
              authenticated: true,
              username: details.unique_name,
              role: details.role,
              isAdmin: details.role === 'Admin',
              isActiveSubscription: details.isActiveSubscription === "True",
            });

          } else {

            // Not signed in
            setAuth(unauthenticatedUser);

            const pathRequiresLogin = _.filter(publicPages, p => p == window.location.pathname).length == 0;

            if (pathRequiresLogin) {
              navigate('/Login');
            }
          }
        });
      }, []);

    const publicPages = [
      '/',
      '/SignUp',
      '/PrivacyPolicy',
      '/TermsAndConditions',
      '/PaymentStatus',
    ];

    return (
        <Routes>
            <Route path='/' element={<Layout auth={auth} />}>
                <Route index element={<Home />} />
                <Route path='Overview' element={<Overview auth={auth} />} />
                <Route path='Admin/Ingredients' element={<Ingredients auth={auth} />} />
                <Route path='Admin/Ingredients/Edit' element={<EditIngredient auth={auth} />} />
                <Route path='Admin/Ingredients/Edit/:id' element={<EditIngredient auth={auth} />} />
                <Route path='Admin/Users' element={<Users auth={auth} />} />
                <Route path='Admin/Users/Edit' element={<EditUser auth={auth} />} />
                <Route path='Admin/Users/Edit/:id' element={<EditUser auth={auth} />} />
                <Route path='Login' element={<Login/>} />
                <Route path='Logout' element={<Logout/>} />
                <Route path='SignUp' element={<SignUp/>} />
                <Route path='PaymentStatus' element={<PaymentStatus/>} />
                <Route path='MealPlans' element={<MealPlansRedirect/>} />
                <Route path='MealPlans/List' element={<MealPlans auth={auth} />} />
                <Route path='MealPlans/Edit' element={<EditMealPlan auth={auth} />} />
                <Route path='MealPlans/Edit/:id' element={<EditMealPlan auth={auth} />} />
                <Route path='MealPlans/ShoppingList/:id' element={<ShoppingList auth={auth} />} />
                <Route path='MealPlans/Export/:id' element={<ExportMealPlan auth={auth} />} />
                <Route path='Recipes' element={<Recipes auth={auth} />} />
                <Route path='Recipes/View/:id' element={<ViewRecipe auth={auth} />} />
                <Route path='Recipes/Edit/:id' element={<EditRecipe auth={auth} />} />
                <Route path='Recipes/Edit' element={<EditRecipe auth={auth} />} />
                <Route path='EditProfile' element={<EditProfile auth={auth} />} />
                <Route path='EditPassword' element={<EditPassword auth={auth} />} />
                <Route path='ManageSubscription' element={<ManageSubscription auth={auth} />} />
                <Route path='UpdatePaymentResult' element={<UpdatePaymentResult />} />
                <Route path='Tutorials' element={<Tutorials auth={auth} />} />
                <Route path='PrivacyPolicy' element={<PrivacyPolicy />} />
                <Route path='TermsAndConditions' element={<TermsAndConditions />} />
            </Route>
        </Routes>
    )
}