import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { IngredientService } from 'services/IngredientService';
import { useNavigate } from 'react-router-dom';
import { mdiPlus } from '@mdi/js'; 
import { Icon } from '@mdi/react';
import { useListFunctions } from 'hooks/useListFunctions';
import { useState } from 'react';
import { IUserAuth } from 'common/Interfaces';

export const Ingredients = (props: { auth: IUserAuth }) => {

    const list = useListFunctions();
    const [onlyCustom, setOnlyCustom] = useState(false);

    // Navigation
    const navigate = useNavigate();
    const addIngredient = () => navigate('/Admin/Ingredients/Edit');
    const editIngredient = (id: string) => navigate('/Admin/Ingredients/Edit/' + id);

    const editInNewTab = (id: string) => {
        window.open('/Admin/Ingredients/Edit/' + id, '_blank');
    }

    useDebouncedEffect(() => {
        runQuery(list.searchText);
    }, [list.searchText, onlyCustom], 1000, list.firstCallMade);

    const runQuery = (text) => {
        list.setFirstCallMade(true);
        list.setLoading(true);
        list.setRows([]);

        IngredientService.search(text, onlyCustom).then(results => list.setRows(results));
    };

    return (
        <div>
            <div className='row'>
                <div className='col-md-6'>
                    <h1>Ingredients</h1>
                </div>
                <div className='col-md-6 text-right'>
                    <ButtonGroup>    
                        <Button type={"button"} onClick={(e) => addIngredient()}><Icon path={mdiPlus} />Create</Button>
                    </ButtonGroup>
                </div>
            </div>
            <div className='row recipe-filters'>
                <div className='col col-md-3'>
                    <Input onChange={list.searchTextChange} placeholder='Search...' />
                </div>
                <div className='col col-md-3'>
                    <Checkbox type='checkbox' value={onlyCustom} onChange={(e) => setOnlyCustom(e.value)} label='Exclude Food Standards' />
                </div>
            </div>
            <Grid data={list.rows.slice(list.page.skip, list.page.take + list.page.skip)}
                pageable={true} skip={list.page.skip} take={list.page.take} total={list.rows.length} onPageChange={list.gridPageChange}
                onRowClick={(e) => editInNewTab(e.dataItem.id)}>
                <Column field="name" title="Description" width='700' />
                <Column field="energy" title="Energy (cal)" width='100' />
                <Column field="carbohydrates" title="Carbs" width='100' />
                <Column field="protein" title="Protein" width='100' />
                <Column field="fat" title="Fat" width='100' />
                <Column field="publicFoodKey" title="Key" />
            </Grid>
        </div>
    );
}