import { useEffect, useState } from 'react';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { UserService } from 'services/UserService';
import { useNavigate } from 'react-router-dom';
import { mdiPlus } from '@mdi/js'; 
import { Icon } from '@mdi/react';
import { useListFunctions } from 'hooks/useListFunctions';
import { IUserAuth } from 'common/Interfaces';

export const Users = (props: { auth: IUserAuth }) => {

    const list = useListFunctions();

    // Navigation
    const navigate = useNavigate();
    const addUser = () => navigate('/Admin/Users/Edit');
    const editUser = (id:string) => navigate('/Admin/Users/Edit/' + id);

    useDebouncedEffect(() => {
        runQuery(list.searchText);
    }, [list.searchText], 1000, list.firstCallMade);

    const runQuery = (text) => {
        list.setFirstCallMade(true);
        list.setLoading(true);
        list.setRows([]);

        UserService.search(text).then(results => list.setRows(results));
    };

    const ExpiryCell = (props: GridCellProps) => {

        if (!props.dataItem.expiry) return <td>-</td>;

        var expiry = new Date(props.dataItem.expiry);
        var expiryString = expiry.toLocaleString();
        return <td>{expiryString}</td>;
    }

    const ArchivedCell = (props: GridCellProps, booleanField: boolean) => {
        return (props.dataItem.archived) ? <td>Yes</td> : <td>-</td>
    }

    return (
        <div>
            <div className='row'>
                <div className='col-md-6'>
                    <h1>Users</h1>
                </div>
                <div className='col-md-6 text-right'>
                    <ButtonGroup>    
                        <Input onChange={list.searchTextChange} placeholder='Search...' />
                        <Button type={"button"} onClick={(e) => addUser()}><Icon path={mdiPlus} />Create</Button>
                    </ButtonGroup>
                </div>
            </div>
            <Grid data={list.rows.slice(list.page.skip, list.page.take + list.page.skip)}
                pageable={true} skip={list.page.skip} take={list.page.take} total={list.rows.length} onPageChange={list.gridPageChange}
                onRowClick={(e) => editUser(e.dataItem.id)}>
                <Column field="userName" title="UserName" />
                <Column field="email" title="Email" />
                <Column field="role" title="Role" />
                <Column field="expiry" title="Expiry (UTC)" cell={ExpiryCell} />
                <Column field="archived" title="Archived" cell={ArchivedCell} />
            </Grid>
        </div>
    );
}