import { IUserAuth } from "common/Interfaces";
import { Icon } from '@mdi/react';
import { mdiYoutube } from "@mdi/js";

export const Tutorials = (props: { auth: IUserAuth }) => {

    return (
        <div className='tutorials-page'>
        <div className='row'>
            <div className='col-md-12'>
                <h1>Tutorials</h1>
            </div>
            <div className='col-md-6'>
                <h3>Step-by-step guides on this page</h3>
                <ol>
                    <li><a href='#1'>Setting up your profile (adding name and logo)</a></li>
                    <li><a href='#2'>Searching the database</a></li>
                    <li><a href='#3'>Creating a Meal Plan</a></li>
                    <li><a href='#4'>Adding a recipe to a Meal Plan</a></li>
                    <li><a href='#5'>Meal Plans - weekly view</a></li>
                    <li><a href='#6'>Meal Plans - serving sizes, leftovers, exclusing nutritional data</a></li>
                    <li><a href='#7'>Meal Plans - exporting a Meal Plan</a></li>
                </ol>
            </div>
            <div className='col-md-6 youtube-links'>
                <h3>Video Tutorials</h3>
                <p>(Opens in new window)</p>
                <p><a target='_blank' href='https://youtu.be/Zqdihr6xTEU'><Icon path={mdiYoutube} />Signing in and Editing your Profile</a></p>
                <p><a target='_blank' href='https://youtu.be/FdziVwEPzhA'><Icon path={mdiYoutube} />Searching for recipes using the filters</a></p>
                <p><a target='_blank' href='https://youtu.be/eF38tutosCY'><Icon path={mdiYoutube} />How to create a meal plan</a></p>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <ol>
                    <h3 id='1'><li>Setting up your profile (adding name and logo)</li></h3>
                    <p>
                        <ul>
                            <li>Select your username - in this case it is <b>TEST01</b>.</li>
                            <li>Select <b>EDIT PROFILE</b></li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-01-01.png' />
                        <ul>
                            <li>Enter your <b>Email</b>.</li>
                            <li>Enter your <b>First</b> and <b>Last</b> name.</li>
                            <li>Using the <b>Select files</b> button, select and insert your logo or branding.</li>
                            <li>Select <b>Save Changes</b> button.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-01-02.png' />
                    </p>
                    <h3 id='2'><li>Searching the database</li></h3>
                    <p>
                        There are 4 ways to search the <b>Wholistic Meal Plans</b> recipe database.
                        <ul>
                            <li>Title - enter an ingredient or part of the recipe name into the <b>Title</b> field.</li>
                            <li>Intolerance - enter an intolerance into the <b>Tag</b> field.</li>
                            <li>Exclusion - enter an ingredient or food you wish to exclude in the <b>Exclusions</b> field.</li>
                            <li>Time - use the slider to adjust cooking <b>Time limit</b>.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-02-01.png' />
                        As an example, we have entered:
                        <ul>
                            <li>Two <b>Tags</b> (low glycaemic and dinner).</li>
                            <li>The ingredient beef in the <b>Exclusions</b> field.</li>
                            <li>There are now 237 recipes in the <b>Results Found</b>.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-02-02.png' />
                    </p>
                    <h3 id='3'><li>Creating a Meal Plan</li></h3>
                    <p>
                        <ul>
                            <li>Select <b>MEAL PLANS</b> from the menu.</li>
                            <li>Select <b>Create</b>.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-03-01.png' />
                        <ul>
                            <li>Type in the <b>Profile Name</b> of the Meal Plan.</li>
                            <li>Select the number of days in the Meal Plan.</li>
                            <li>Select <b>Create</b>.</li>
                            <li>Select <b>Close</b>.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-03-02.png' />
                    </p>
                    <h3 id='4'><li>Adding a Recipe to a Meal Plan</li></h3>
                    <p>
                        <ul>
                            <li>Search & click on a recipe.</li>
                            <li>Select <b>+ Meal Plan</b>.</li>
                            <li>Select Client from drop down menu, in this case <b>Nina Proudman</b>.</li>
                            <li>Select which meal to place the recipe in, in this case, breakfast.</li>
                            <li>Select <b>Add to Meal Plan</b>.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-04-01.png' />
                        <ul>
                            <li>When adding meals to a meal plan, sometimes the selection is greyed out. This means there is already a meal plan in that position.</li>
                            <li>You can still add another "meal", "side" or "condiment" by clicking on the greyed out area - and a white tick will appear.</li>
                            <li>Then select <b>Add to Meal Plan</b>.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-04-02.png' />
                    </p>
                    <h3 id='5'><li>Meal Plan - weekly view</li></h3>
                    <p>
                        <ul>
                            <li>You can edit or rename the wording in Days of Week & Meals.</li>
                            <li>You can also delete entire rows if you are not providing snack options or other meal time options.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-05-01.png' />
                    </p>
                    <h3 id='6'><li>Meal Plan - servings, leftovers & excluding nutritional data</li></h3>
                    <p>
                        <ul>
                            <li>Increase or decrease the <b>Servings</b> to suit your client.</li>
                            <li><b>Select as Leftover</b>, for occasions where the meal can be used as another meal or on the following day. This will dull the selection to give you a visual indicator in the Meal Plan view.</li>
                            <li>Select <b>Exclude from Nutrition</b> if you wish to exclude the nutritional data from a meal plan.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-06-01.png' />
                    </p>
                    <h3 id='7'><li>Meal Plan - exporting to PDF</li></h3>
                    <p>
                        <ul>
                            <li>Select <b>Export</b>.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-07-01.png' />
                        <ul>
                            <li>You can than describe the Meal Plan and create an introduction for your client in the Export Options. You can also add hyperlinks.</li>
                            <li>Choose a Meal Plan cover photo by selecting Cover.</li>
                            <li>Exclude a recipe from the Meal Plan if you've used the same recipe multiple times across the week.</li>
                        </ul>
                        <img className='tutorial-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/tutorial-screen-07-02.png' />
                    </p>
                </ol>
            </div>
        </div>       
        </div>
    );
}