export interface ISignUpViewModel {
    setupIntent: string;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    phoneNumber: string;
    modality: string;
    modalityOther: string;
}

export const SignUpViewModelInitialState : ISignUpViewModel = {
    setupIntent: '',
    userName: '',
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    modality: 'Nutritionist',
    modalityOther: '',
}