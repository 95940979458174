import { useNavigate, useParams } from 'react-router-dom';
import _ from "lodash";
import { IRecipe, IRecipeIngredientProps, IRecipeIngredientCategoryProps, RecipeInitialState, INutritionSummary, NutritionSummaryInitialState, IRecipeInstructionProps } from 'pages/Recipes/RecipeInterfaces';
import { RecipeService } from 'pages/Recipes/RecipeService';
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Slider } from '@progress/kendo-react-inputs';
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesLabels } from "@progress/kendo-react-charts";
import { Card, CardHeader, CardBody, Collapse, Alert as ReactstrapAlert } from 'reactstrap';
import { useEffect, useState } from 'react';
import { mdiBlender, mdiCheckboxBlankOutline, mdiClose, mdiFilePdfBox, mdiPencil, mdiStove } from '@mdi/js';
import { Icon } from '@mdi/react';
import Fraction from 'fraction.js';
import FileSaver from 'file-saver';
import { AddToMealPlanButton } from 'pages/MealPlans/AddToMealPlanButton';
import { Spinner } from 'components/Spinner';
import { IUserAuth } from 'common/Interfaces';
import { useSubscription } from 'hooks/useSubscription';

export const ViewRecipe = (props: { auth: IUserAuth }) => {

    // Setup View
    const { id } = useParams();
    const nav = useNavigate();
    const [view, setView] = useState<IRecipe>(RecipeInitialState);
    const [sliderValue, setSliderValue] = useState<number>(2);
    const [displayServings, setDisplayServings] = useState<number>(1);
    const [loading, setLoading] = useState(true);
    const [exportingPdf, setExportingPdf] = useState(false);

    // Check Subscription
    useSubscription(props.auth);

    // Load Data
    useEffect(() => {
            RecipeService.getById(id).then(result => {
                setView(result);
                setDisplayServings(result.servings);
                calculateNutrition(result);
            }).finally(() => setLoading(false));
    }, []);

    const closeItem = () => {
        nav('/Recipes');
    };

    const editItem = () => {
        nav('/Recipes/Edit/' + view.id);
    }

    const exportPdf = () => {
        setExportingPdf(true);
        RecipeService.exportPdf(id).then(result => {
            FileSaver.saveAs(result, 'Recipe Export - ' + view.name + '.pdf');
        }).finally(() => setExportingPdf(false));
    }

    const minSlider = view.servings == 1 ? 2 : 1;

    const recalculateServings = (slider: number) => {
        setSliderValue(Math.floor(slider / 1));

        switch (sliderValue){
            case 1: setDisplayServings(Math.ceil(view.servings / 2)); break;
            case 3: setDisplayServings(view.servings * 2); break;
            default: setDisplayServings(view.servings); break;
        }
    }

    // Notes
    const [notesExpanded, setNotesExpanded] = useState(true);
    const toggleNotes = () => {
        setNotesExpanded(!notesExpanded);
    }

    const [nutrition, setNutrition] = useState<INutritionSummary>(NutritionSummaryInitialState);
    const [chartData, setChartData] = useState([]);
    const calculateNutrition = (recipe: IRecipe) => {

        if (!recipe.ingredients.length) {
            return;
        }

        // Sum all values
        const reducer = (total, current) => total + current;

        const ingredients = recipe.ingredients.map(i => i.ingredient);

        const result: INutritionSummary = {
            energy: ingredients.map(i => i.energyCalories).reduce(reducer),
            protein: ingredients.map(i => i.protein).reduce(reducer),
            fat: ingredients.map(i => i.fat).reduce(reducer),
            sugars: ingredients.map(i => i.sugars).reduce(reducer),
            carbohydrates: ingredients.map(i => i.carbohydrates).reduce(reducer),
            calcium: ingredients.map(i => i.calcium).reduce(reducer),
            copper: ingredients.map(i => i.copper).reduce(reducer),
            iodine: ingredients.map(i => i.iodine).reduce(reducer),
            iron: ingredients.map(i => i.iron).reduce(reducer),
            magnesium: ingredients.map(i => i.magnesium).reduce(reducer),
            potassium: ingredients.map(i => i.potassium).reduce(reducer),
            selenium: ingredients.map(i => i.selenium).reduce(reducer),
            sodium: ingredients.map(i => i.sodium).reduce(reducer),
            zinc: ingredients.map(i => i.zinc).reduce(reducer),
            retinol: ingredients.map(i => i.retinol).reduce(reducer),
            thiamin: ingredients.map(i => i.thiamin).reduce(reducer),
            riboflavin: ingredients.map(i => i.riboflavin).reduce(reducer),
            niacin: ingredients.map(i => i.niacin).reduce(reducer),
            pantothenicAcid: ingredients.map(i => i.pantothenicAcid).reduce(reducer),
            pyridoxine: ingredients.map(i => i.pyridoxine).reduce(reducer),
            biotin: ingredients.map(i => i.biotin).reduce(reducer),
            cobalamin: ingredients.map(i => i.cobalamin).reduce(reducer),
            folateNatural: ingredients.map(i => i.folateNatural).reduce(reducer),
            vitaminC: ingredients.map(i => i.vitaminC).reduce(reducer),
            cholecalciferol: ingredients.map(i => i.cholecalciferol).reduce(reducer),
            vitaminE: ingredients.map(i => i.vitaminE).reduce(reducer),
            fattyAcidsTotalSaturated: ingredients.map(i => i.fattyAcidsTotalSaturated).reduce(reducer),
            fattyAcidsTotalMonounsaturated: ingredients.map(i => i.fattyAcidsTotalMonounsaturated).reduce(reducer),
            fattyAcidsTotalPolyunsaturated: ingredients.map(i => i.fattyAcidsTotalPolyunsaturated).reduce(reducer),
        };
        setNutrition(result);
        setChartData([
            { "macro": "Protein", "proportion": result.protein },
            { "macro": "Carbs", "proportion": result.carbohydrates },
            { "macro": "Fat", "proportion": result.fat },
        ]);
    }

    const labelContent = (e) => e.category + '\n' + e.dataItem.proportion + 'g';

    const donutCenter = () => (
        <p>Energy<br/><b>{nutrition.energy}kJ</b></p>
    );

    const chartSeriesColours: string[] = ["#76796e","#2A2B27","#B9BBB4"];

    const RecipeIngredients = (props: IRecipeIngredientCategoryProps) => {

        const categories = _.map(_.groupBy(props.recipeIngredients, ri => ri.category), ri => ri);

        const Category = (categoryProps: IRecipeIngredientCategoryProps) => {

            const heading = categoryProps.recipeIngredients[0].category;
            const showHeading = !!heading;
            return (
                <>
                    {showHeading && <tr><td colSpan={3} className='ingredient-category'>{heading}</td></tr>}
                    {categoryProps.recipeIngredients.map((i) => <RecipeIngredientItem recipeIngredient={i} />)}
                </>
            )
        }
        
        return (
            <table className='ingredients-table'>
                {categories.map((c) => <Category recipeIngredients={c}/>)}
            </table>
        )
    }

    const RecipeIngredientItem = (props: IRecipeIngredientProps) => {

        const ri = props.recipeIngredient;

        const displayName = !!ri.friendlyName
            ? ri.friendlyName
            : !!ri.ingredient.shortName ?
                ri.ingredient.shortName
                : ri.ingredient.name;

        const roundTo2DecimalPlaces = (num: number) => {
            var m = Number((Math.abs(num) * 100).toPrecision(15));
            return Math.round(m) / 100 * Math.sign(num);
        }

        // Ratio for custom servings
        const servingsAdjustment = displayServings / view.servings;
        let amount = roundTo2DecimalPlaces(ri.amount * servingsAdjustment);
        const mls = Math.round(ri.millilitres * servingsAdjustment);

        const grams = (ri.grams * servingsAdjustment);
        let gramsDisplay = (grams % 1 != 0 && grams < 2) ? grams.toFixed(2) : grams.toFixed(0);

        let amountDisplay = '';
        if (!!amount){
            ////==========================================
            //// This section might need to be added back!
            ////==========================================
            // if ((amount % 1).toFixed(2) == '0.33') {
            //     let wholeNumber = Math.floor(amount);
            //     amountDisplay = (!!wholeNumber ? wholeNumber + ' ' : '') + new Fraction(1, 3).toFraction(true);
            // }
            // else if ((amount % 1).toFixed(2) == '0.66' || (amount % 1).toFixed(2) == '0.67') {
            //     let wholeNumber = Math.floor(amount);
            //     amountDisplay = (!!wholeNumber ? wholeNumber + ' ' : '') + new Fraction(2, 3).toFraction(true);
            // }
            // else if (amount % 1 != 0) {
            //     if (displayServings != view.servings){
            //         amountDisplay = new Fraction(amount.toFixed(1)).toFraction(true);
            //     } else {
            //         amountDisplay = new Fraction(amount).toFraction(true);
            //     }
            // }
            // else {
            //     amountDisplay = amount.toString();
            // }

            let fraction = amount % 1;
            if (fraction == 0) {
                amountDisplay = amount.toString();
            } else {
                if (displayServings == view.servings){
                    amountDisplay = new Fraction(amount).toFraction(true);
                } else {
                    const roundedFraction = (1 > fraction && fraction >= 0.875 ) ? 0.875
                        : (0.875 > fraction && fraction >= 0.75) ? 0.75
                        : (0.75 > fraction && fraction >= 0.625) ? 0.625
                        : (0.625 > fraction && fraction >= 0.5) ? 0.5
                        : (0.5 > fraction && fraction >= 0.375) ? 0.375
                        : (0.375 > fraction && fraction >= 0.25) ? 0.25
                        : (0.25 > fraction && fraction >= 0.001) ? 0.125
                        : 0;
                    amountDisplay = new Fraction(Math.floor(amount) + roundedFraction).toFraction(true);
                }
            }
        }

        let unit = '';
        switch(ri.measurementUnit) {
            case 'Cup': unit = 'cup'; break;
            case 'Litre': unit = amount > 1 ? 'litres' : 'litre'; break;
            case 'Tablespoon': unit = 'tbsp'; break;
            case 'Teaspoon': unit = 'tsp'; break;
            default: break;
        }

        const MeasurementDisplay = () => {
            return ri.displayAsLiquid && !!ri.millilitres
                ? <span>({mls} ml)</span>
                : <span>({gramsDisplay} g)</span>
        }

        return (
            <tr>
                <td className='step'><Icon path={mdiCheckboxBlankOutline} /></td>
                <td className='ingredient'>{amountDisplay} {unit} <MeasurementDisplay /> {displayName} {ri.optional && <i>- Optional</i>}</td>
            </tr>
        );
    }

    const RecipeInstructionItem = (props: IRecipeInstructionProps) => {
        return (
            <tr>
                <td className='step'>{props.index + 1}</td>
                <td className='instruction'>{props.instruction}</td>
            </tr>
        );
    }

    const RecipeImage = () => {

        if (view.imageUri == null){
            return <></>;
        }
        else
        {
            return (
                <Card className='mb-4'>
                    <CardBody>
                        <img src={view.imageUri} className={'recipe-main-image'} />
                    </CardBody>
                </Card>
            );
        }
    }

    const IngredientsCard = () => {
        return (
            <Card className='mb-4'>
                <CardHeader>
                    Ingredients
                </CardHeader>
                <CardBody>
                    <RecipeIngredients recipeIngredients={view.ingredients} />
                </CardBody>
            </Card>
        );
    }

    const ServesAndTimesCard = () => {
        return (
            <Card className='mb-4'>
                <CardBody>
                    <div className='row mb-4'>
                        <div className='col-md-3'>
                            <h5>Serves: {displayServings}</h5>
                        </div>
                        <div className='servings col-md-4'>
                            <Slider value={sliderValue} min={minSlider} max={3} onChange={e => recalculateServings(e.value)} />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>
                            <h5><Icon path={mdiBlender} className='inline' />Prep Time:</h5>
                        </div>
                        <div className='col-md-9'>
                            <h5>{view.preparationTime} mins</h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3'>
                        <h5><Icon path={mdiStove} className='inline' />Cook Time:</h5>
                        </div>
                        <div className='col-md-9'>
                            <h5>{view.cookingTime} mins</h5>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }

    const DirectionsCard = () => {
        return (
            <Card className='mb-4'>
                <CardHeader>
                    Directions
                </CardHeader>
                <CardBody>
                    <table className='instructions-table'>
                        <tbody>
                            {view.instructions.map((i, index) => <RecipeInstructionItem instruction={i} index={index} />)}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        );
    }

    const NutritionCard = () => {
        return (
            <Card className='mb-4' hidden={!view.ingredients.length}>
                <CardHeader>
                    Nutritional profile (per serve)
                </CardHeader>
                <CardBody>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='container nutrition-table'>
                        <div className='row'>
                            <div className='col-md-2'>Sugars:</div>
                            <div className='col-md-1'>{nutrition.sugars}g</div>
                            <div className='col-md-2'>Calcium:</div>
                            <div className='col-md-1'>{nutrition.calcium}mg</div>
                            <div className='col-md-2'>Copper:</div>
                            <div className='col-md-1'>{nutrition.copper}mg</div>
                            <div className='col-md-2'>Iodine:</div>
                            <div className='col-md-1'>{nutrition.iodine}ug</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-2'>Saturated Fat:</div>
                            <div className='col-md-1'>{nutrition.fattyAcidsTotalSaturated}g</div>
                            <div className='col-md-2'>Iron:</div>
                            <div className='col-md-1'>{nutrition.iron}mg</div>
                            <div className='col-md-2'>Zinc:</div>
                            <div className='col-md-1'>{nutrition.zinc}mg</div>
                            <div className='col-md-2'>Magnesium:</div>
                            <div className='col-md-1'>{nutrition.magnesium}mg</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-2'>Monounsaturated Fat:</div>
                            <div className='col-md-1'>{nutrition.fattyAcidsTotalMonounsaturated}g</div>
                            <div className='col-md-2'>Potassium:</div>
                            <div className='col-md-1'>{nutrition.potassium}mg</div>
                            <div className='col-md-2'>Selenium:</div>
                            <div className='col-md-1'>{nutrition.selenium}ug</div>
                            <div className='col-md-2'>Sodium:</div>
                            <div className='col-md-1'>{nutrition.sodium}mg</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-2'>Polyunsaturated Fat:</div>
                            <div className='col-md-1'>{nutrition.fattyAcidsTotalPolyunsaturated}g</div>
                            <div className='col-md-2'>Retinol (Vit A):</div>
                            <div className='col-md-1'>{nutrition.retinol}ug</div>
                            <div className='col-md-2'>Thiamin (B1):</div>
                            <div className='col-md-1'>{nutrition.thiamin}mg</div>
                            <div className='col-md-2'>Riboflavin (B2):</div>
                            <div className='col-md-1'>{nutrition.riboflavin}mg</div>
                        </div>
                        <div className='row'>                                    
                            <div className='col-md-2'>Niacin (B3):</div>
                            <div className='col-md-1'>{nutrition.niacin}mg</div>
                            <div className='col-md-2'>Pantothenic Acid (B5):</div>
                            <div className='col-md-1'>{nutrition.pantothenicAcid}mg</div>
                            <div className='col-md-2'>Pyridoxine (B6):</div>
                            <div className='col-md-1'>{nutrition.pyridoxine}mg</div>
                            <div className='col-md-2'>Biotin:</div>
                            <div className='col-md-1'>{nutrition.biotin}ug</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-2'>Cobalamin (B12):</div>
                            <div className='col-md-1'>{nutrition.cobalamin}ug</div>
                            <div className='col-md-2'>Natural Folate:</div>
                            <div className='col-md-1'>{nutrition.folateNatural}ug</div>
                            <div className='col-md-2'>Vitamin C:</div>
                            <div className='col-md-1'>{nutrition.vitaminC}mg</div>
                            <div className='col-md-2'>Cholecalciferol (Vit D):</div>
                            <div className='col-md-1'>{nutrition.cholecalciferol}ug</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-2'>Vitamin E:</div>
                            <div className='col-md-1'>{nutrition.vitaminE}mg</div>
                        </div>
                        </div>
                    </div>
                </div>
                </CardBody>
            </Card>
        );
    }

    const NotesCard = () => {
        return (
            <Card className='mb-4' hidden={!view.notes}>
                <CardHeader onClick={toggleNotes} po>
                    Notes
                </CardHeader>
                <Collapse isOpen={notesExpanded}>
                    <CardBody>
                        <p>{view.notes}</p>
                    </CardBody>
                </Collapse>
            </Card>
        );
    }

    const KeyNotesCard = () => {
        return (
            <Card className='mb-4' hidden={!view.keyNotes}>
                <CardHeader>
                    Key notes - for the nutritionist
                </CardHeader>
                <CardBody>
                    <p>{view.keyNotes}</p>
                </CardBody>
            </Card>
        );
    }

    const DonutCard = () => {
        return (
            <Card className='mb-4'>
                <CardHeader>
                    Macros (per serve)
                </CardHeader>
                <CardBody>
                <Chart donutCenterRender={donutCenter} transitions={false} className='donut-chart' seriesColors={chartSeriesColours}>
                    <ChartSeries>
                        <ChartSeriesItem type="donut" data={chartData} categoryField='macro' field='proportion' padding={0}>
                            <ChartSeriesLabels color="#FFF" background="none" content={labelContent} />
                        </ChartSeriesItem>
                    </ChartSeries>
                    <ChartLegend visible={false} />
                </Chart>
                </CardBody>
            </Card>
        )
    }

    const MacrosCard = () => {
        return (
            <Card className='mb-4'>
                <CardHeader>
                    Macros (per serve)
                </CardHeader>
                <CardBody>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 text-center'>Energy: {nutrition.energy} cal</div>
                        <div className='col-md-3 text-center'>Carbs: {nutrition.carbohydrates} g</div>
                        <div className='col-md-3 text-center'>Protein: {nutrition.protein} g</div>
                        <div className='col-md-3 text-center'>Fat: {nutrition.fat} g</div>
                    </div>
                </div>
                </CardBody>
            </Card>
        )
    }

    const PdfExportingIndicator = () => {
        return (
            <div className='row'>
                <ReactstrapAlert color='light' className="text-center" hidden={!exportingPdf}>
                    <span className='loader inline mr-1'></span> Creating your PDF. This could take a few minutes.
                </ReactstrapAlert>
            </div>
        );
    }

    return (
        <>
        <div className='recipe-display' id={'RecipeDisplay'} hidden={loading}>
            <div className='row'>
                <div className='col-md-8'>
                    <h1>{view.name}</h1>
                </div>
                <div className='col-md-4 text-right'>
                    <ButtonGroup>
                        <AddToMealPlanButton recipeId={id} smallButton={false} />
                        <Button type={"button"} onClick={exportPdf}><Icon path={mdiFilePdfBox} />Export</Button>
                        <Button type={"button"} onClick={editItem} hidden={!props.auth.isAdmin}><Icon path={mdiPencil} />Edit</Button>
                        <Button type={"button"} onClick={closeItem}><Icon path={mdiClose} />Close</Button>
                    </ButtonGroup>
                </div>
            </div>
            <PdfExportingIndicator />
            <div className='row mt-5'>
                <div className='col-md-6'>
                    <ServesAndTimesCard />
                    <MacrosCard />
                    <IngredientsCard />
                </div>
                <div className='col-md-6'>
                    <RecipeImage />
                    <DirectionsCard />
                </div>
            </div>
            <div className='row' hidden={!view.ingredients.length}>
                <div className='col-md-12'>
                    <NutritionCard />
                    <NotesCard />
                    <KeyNotesCard />
                </div>
            </div>
        </div>
        <Spinner loading={loading} />
        </>
    )
}