/* https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook */

import { useEffect } from "react";

export const useDebouncedEffect = (effect, deps, delay, firstCallMade: boolean) => {
    useEffect(() => {

        // If first call, then don't delay
        if (!firstCallMade) {
            delay = 0;
        }

        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps || [], delay]);
}