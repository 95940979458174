import { get, execute, download } from 'services/Middleware';
import { ICreateRecipeCommand, IUpdateRecipeCommand, IArchiveRecipeCommand, IRestoreRecipeCommand, IDeleteRecipeCommand } from 'pages/Recipes/RecipeCommands';

/* Queries */

export const search = (keywords: string, tags: string[], excludeTags: string[], maxTime: number | null) => {
    let path = `/recipes/list?keywords=${keywords.toString()}`;
    if (tags.length) path += `&tags=${tags.join(',')}`;
    if (excludeTags.length) path += `&excludeTags=${excludeTags.join(',')}`;
    if (!!maxTime) path += `&maxTime=${maxTime}`;
    return get(path);
}

export const getById = (id: string) => {
    const path = `/recipes/get?id=${id.toString()}`;
    return get(path);
}

export const getForEdit = (id: string) => {
    const path = `/recipes/getForEdit?id=${id.toString()}`;
    return get(path);
}

export const getTags = () => {
    const path = `/recipes/getTags`;
    return get(path);
}

export const exportPdf = (id: string) => {
    const path = `/recipes/exportPdf?id=${id.toString()}`;
    return download(path);
}

/* Commands */

export const create = (command: ICreateRecipeCommand) => {
    return execute('CreateRecipe', command);
}

export const update = (command: IUpdateRecipeCommand) => {
    return execute('UpdateRecipe', command);
}

export const archive = (command: IArchiveRecipeCommand) => {
    return execute('ArchiveRecipe', command);
}

export const restore = (command: IRestoreRecipeCommand) => {
    return execute('RestoreRecipe', command);
}

export const del = (command: IDeleteRecipeCommand) => {
    return execute('DeleteRecipe', command);
}

export const RecipeService = {
    search,
    getById,
    getForEdit,
    getTags,
    exportPdf,
    create,
    update,
    archive,
    restore,
    del,
}