import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MealPlanService } from "./MealPlanService";

/// <summary>
/// Redirect to last edited meal plan.
/// Fall back to meal plan list if no meal plan found.
/// </summary>
export const MealPlansRedirect = () => {

    const nav = useNavigate();

    useEffect(() => {
        MealPlanService.getLastEditedId().then(id => {
            if (!!id && id !== '00000000-0000-0000-0000-000000000000'){
              nav('/MealPlans/Edit/' + id);
            } else {
              nav('/MealPlans/List');
            }
          });
    }, []);

    return <></>
}