import { useEffect } from "react";
import { IUserAuth } from "common/Interfaces";
import { useSubscription } from "hooks/useSubscription";

export const Overview = (props: { auth: IUserAuth }) => {

  // Check Subscription
  useSubscription(props.auth);

    return (
      <div className='row'>
        <div className='col-md-12'>
          <h1>Welcome!</h1>
          <p></p>
          <div className='overview-image-container'>
            <img className='overview-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/overview-splash.jpg'/>
            <p>Founder - Danyel Waters</p>
          </div>

          <p>We are so excited to have you here at <b><i>Wholistic Meal Plans</i></b>!</p>
          <p>Wholistic Meal Plans was born from the frustration experienced by our founder, Clinical Nutritionist - Danyel Waters, at the amount of time it took to develop and deliver nourishing, nutrient dense meal plans to patients with complex food intolerances or preferences.</p>
          <p>Wholistic Meal Plans is the only Australian-based, <b>practitioner exclusive</b> meal planning program helping Nutritionists, Dieticians, Naturopaths, Health Coaches and Personal Trainers, deliver customised meal plans for even the most complex clients.</p>
          <p>This recipe database is full of nutritionally dense, intolerance friendly meals that have been developed & adapted by our talented in-house chefs and Nutritionists. From Anti-candida to low histamine, we've created hundreds of recipes for over 25 commonly diagnosed food intolerances, providing meal options for a range of breakfasts, lunches, sides, dinners, salads, snacks, desserts and beverages.</p>
          <p><b>How does it work?</b> Simply filter your intolerance(s), exclude any ingredients you may not like, find your recipes, and add the recipe to your clients meal plan. For example, you have a client who is gluten and dairy intolerant who is also histamine intolerant and doesn't like capsicum? Simple. Enter those details into the search field and only the meals that exclude those parameters will show. Allowing you to design a nutritionally balanced meal plan that takes all the guess work out of the equation. All meals have major macros per serving calculated as well as vitamins and minerals levels listed.</p>
          <p>Once your meal plan is complete, it can be exported to a wonderful PDF booklet that is customised with your Business Logo.</p>
          <p>Every single recipe listed in the Wholistic Meal Plan database has been cooked in our kitchen and the end result of your client's cooking experience will look and taste exactly how we made it. Making it easy and simple to follow for your client, creating greater adherence to treatment plans. We aim to become a Health Practitioner's best resource and an additional income for you as you add on customised Meal Plans to your services.</p>
          <p>We currently have over 560 recipes and are adding to these every month based on your feedback.</p>
          <p>If you have any questions or feedback you'd like to send us as you think of it, please reach out to us at <a href="mailto:support@wholisticmealplans.com.au">support@wholisticmealplans.com.au</a></p>
          <p>We look forward to working with you!</p>
          <img className='signoff-image' src='https://wmpproduction.blob.core.windows.net/tutorial-images/danyel-signoff.png'/>
          <p></p>
        </div>
      </div>
    );
  }