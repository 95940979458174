import { FormRenderProps } from '@progress/kendo-react-form';
import { IIngredient } from 'pages/Ingredients/IngredientInterfaces';

export interface IRecipe {
    id: string,
    name: string,
    externalId: string,
    notes: string,
    keyNotes: string,
    servings: number,
    preparationTime: number,
    cookingTime: number,
    instructions: string[],
    tags: string[],
    ingredients: IRecipeIngredient[],
    imageUri: string,
    archived: boolean,
}

export interface IEditRecipe {
    id: string,
    name: string,
    externalId: string,
    notes: string,
    keyNotes: string,
    servings: number,
    preparationTime: number,
    cookingTime: number,
    instructions: string[],
    tags: string[],
    ingredients: IEditRecipeIngredient[],
    imageId: string,
    imageUri: string,
    archived: boolean,
}

export interface IRecipeListItem {
    id: string,    
    name: string,
    externalId: string,
    time: number,
    servings: number,
    imageUri: string,
    tags: string[],
    isArchived: boolean,
}

export interface IRecipeIngredient {
    id: string,
    friendlyName: string,
    category: string,
    measurementUnit: string,
    amount: number,
    grams: number | undefined,
    millilitres: number | undefined,
    ingredient: IIngredient,
    displayAsLiquid: boolean,
    optional: boolean,
}

export interface IRecipeIngredientCategory {
    category: string,
    recipeIngredients: IRecipeIngredient[];
}

export interface IEditRecipeInstruction {
    text: string;
}

export const EditRecipeInstructionInitialState: IEditRecipeInstruction = {
    text: '',
}

export interface IEditRecipeIngredient {
    id: string,
    friendlyName: string,
    category: string,
    measurementUnit: string,
    amount: number,
    grams: number | undefined,
    millilitres: number | undefined,
    ingredient: string,
    volumeToWeightRatio: number,
    inEdit: boolean,
    optional: boolean,
    order: number,
}

export const EditRecipeIngredientInitialState: IEditRecipeIngredient = {
    id: '',
    friendlyName: '',
    category: '',
    measurementUnit: 'Unit',
    amount: 0,
    grams: 0,
    millilitres: 0,
    ingredient: '',
    volumeToWeightRatio: 0,
    inEdit: true,
    optional: false,
    order: 0,
}

export const RecipeInitialState: IRecipe = {
    id: '',
    name: '',
    externalId: '',
    notes: '',
    keyNotes: '',
    servings: undefined,
    preparationTime: undefined,
    cookingTime: undefined,
    instructions: [],
    tags: [],
    ingredients: [],
    imageUri: '',
    archived: false,
}

export const EditRecipeInitialState: IEditRecipe = {
    id: '',
    name: '',
    externalId: '',
    notes: '',
    keyNotes: '',
    servings: undefined,
    preparationTime: undefined,
    cookingTime: undefined,
    instructions: [],
    tags: [],
    ingredients: [],
    imageId: '',
    imageUri: '',
    archived: false,
}

export interface IRecipeIngredientProps {
    recipeIngredient: IRecipeIngredient,
}

export interface IRecipeIngredientCategoryProps {
    recipeIngredients: IRecipeIngredient[],
}

export interface IRecipeInstructionProps {
    instruction: string;
    index: number;
}

export interface IImageSelectorProps {
    formRenderProps: FormRenderProps,
}

export interface INutritionSummary {
    energy: number | undefined;
    protein: number | undefined;
    fat: number | undefined;
    sugars: number | undefined;
    carbohydrates: number | undefined;
    calcium: number | undefined;
    copper: number | undefined;
    iodine: number | undefined;
    iron: number | undefined;
    magnesium: number | undefined;
    potassium: number | undefined;
    selenium: number | undefined;
    sodium: number | undefined;
    zinc: number | undefined;
    retinol: number | undefined;
    thiamin: number | undefined;
    riboflavin: number | undefined;
    niacin: number | undefined;
    pantothenicAcid: number | undefined;
    pyridoxine: number | undefined;
    biotin: number | undefined;
    cobalamin: number | undefined;
    folateNatural: number | undefined;
    vitaminC: number | undefined;
    cholecalciferol: number | undefined;
    vitaminE: number | undefined;
    fattyAcidsTotalSaturated: number | undefined;
    fattyAcidsTotalMonounsaturated: number | undefined;
    fattyAcidsTotalPolyunsaturated: number | undefined;
}

export const NutritionSummaryInitialState: INutritionSummary = {
    energy: undefined,
    protein: undefined,
    fat: undefined,
    sugars: undefined,
    carbohydrates: undefined,
    calcium: undefined,
    copper: undefined,
    iodine: undefined,
    iron: undefined,
    magnesium: undefined,
    potassium: undefined,
    selenium: undefined,
    sodium: undefined,
    zinc: undefined,
    retinol: undefined,
    thiamin: undefined,
    riboflavin: undefined,
    niacin: undefined,
    pantothenicAcid: undefined,
    pyridoxine: undefined,
    biotin: undefined,
    cobalamin: undefined,
    folateNatural: undefined,
    vitaminC: undefined,
    cholecalciferol: undefined,
    vitaminE: undefined,
    fattyAcidsTotalSaturated: undefined,
    fattyAcidsTotalMonounsaturated: undefined,
    fattyAcidsTotalPolyunsaturated: undefined,
}

export interface ITagsFilterProps {
    label: string,
    allTagOptions: [],
}