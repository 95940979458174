export interface IIngredient {
    id: string;
    name: string;
    category: string;
    shortName: string;
    publicFoodKey: string;
    classificationId: number | undefined;
    energy: number | undefined;
    energyCalories: number | undefined;
    protein: number | undefined;
    fat: number | undefined;
    sugars: number | undefined;
    carbohydrates: number | undefined;
    calcium: number | undefined;
    copper: number | undefined;
    iodine: number | undefined;
    iron: number | undefined;
    magnesium: number | undefined;
    potassium: number | undefined;
    selenium: number | undefined;
    sodium: number | undefined;
    zinc: number | undefined;
    retinol: number | undefined;
    thiamin: number | undefined;
    riboflavin: number | undefined;
    niacin: number | undefined;
    pantothenicAcid: number | undefined;
    pyridoxine: number | undefined;
    biotin: number | undefined;
    cobalamin: number | undefined;
    folateNatural: number | undefined;
    vitaminC: number | undefined;
    cholecalciferol: number | undefined;
    vitaminE: number | undefined;
    fattyAcidsTotalSaturated: number | undefined;
    fattyAcidsTotalMonounsaturated: number | undefined;
    fattyAcidsTotalPolyunsaturated: number | undefined;
}

export const IngredientInitialState: IIngredient = {
    id: '',
    name: '',
    category: '',
    shortName: '',
    publicFoodKey: ',',
    classificationId: undefined,
    energy: undefined,
    energyCalories: undefined,
    protein: undefined,
    fat: undefined,
    sugars: undefined,
    carbohydrates: undefined,
    calcium: undefined,
    copper: undefined,
    iodine: undefined,
    iron: undefined,
    magnesium: undefined,
    potassium: undefined,
    selenium: undefined,
    sodium: undefined,
    zinc: undefined,
    retinol: undefined,
    thiamin: undefined,
    riboflavin: undefined,
    niacin: undefined,
    pantothenicAcid: undefined,
    pyridoxine: undefined,
    biotin: undefined,
    cobalamin: undefined,
    folateNatural: undefined,
    vitaminC: undefined,
    cholecalciferol: undefined,
    vitaminE: undefined,
    fattyAcidsTotalSaturated: undefined,
    fattyAcidsTotalMonounsaturated: undefined,
    fattyAcidsTotalPolyunsaturated: undefined,
}