import { useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { UserMenu } from 'components/UserMenu';
import { Icon } from '@mdi/react';
import { mdiGauge, mdiBookOpenPageVariantOutline, mdiCalendarTextOutline, mdiHelpCircleOutline } from '@mdi/js';
import logo from '../assets/images/wmp-logo.png';
import { IUserAuth } from 'common/Interfaces';

export const NavMenu = (props: { auth: IUserAuth }) => {

  // Navigation
  const nav = useNavigate();

  // Location
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  const showSignInButton = (!props.auth || !props.auth.authenticated) && location.pathname != '/Login';

  const NotSignedInNavItems = () => (
    <div className='not-signed-in hide-on-mobile'>
      <span className='small-text'>Already a member? </span>
      <Button onClick={() => nav('/Login')}>Sign In</Button>
    </div>
  );

  const SignedInNavItems = () => (
    <>
      <NavItem>
        <NavLink tag={Link} to="/Overview"><Icon path={mdiGauge} />Overview</NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} to="/Tutorials"><Icon path={mdiHelpCircleOutline} />Tutorials</NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} to="/Recipes"><Icon path={mdiBookOpenPageVariantOutline} />Recipes</NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} to="/MealPlans"><Icon path={mdiCalendarTextOutline} />Meal Plans</NavLink>
      </NavItem>
    </>
  );

  const AuthenticatedContent = () => {
    return (
      <>
      <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
        <ul className="navbar-nav flex-grow">
          {props.auth.isActiveSubscription && <SignedInNavItems />}
          <UserMenu auth={props.auth} />
        </ul>
      </Collapse>
      </>
    )
  }

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3" dark>
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img src={logo} alt="Wholistic Meal Plans"/>
          </NavbarBrand>
          {showSignInButton && <NotSignedInNavItems />}
          {!!props.auth.authenticated && <AuthenticatedContent/>}
        </Container>
      </Navbar>
    </header>
  );
}
