import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Align, Popup } from "@progress/kendo-react-popup";
import Icon from "@mdi/react";
import { mdiCheck, mdiClose, mdiPlus } from "@mdi/js";
import { Card, CardBody, CardFooter } from "reactstrap";
import { MealPlanService } from "./MealPlanService";
import { IEditMealPlan, IMealPlanSelectOption } from "./MealPlanInterfaces";
import { on } from "events";

export interface AddToMealPlanButtonProps {
    recipeId: string;
    mealPlanId?: string;
    buttonLabel?: string;
    buttonIcon?: string;
    smallButton?: boolean;
    onCopyComplete?: any;
    disabled?: boolean;
}

export const AddToMealPlanButton = (props: AddToMealPlanButtonProps) => {

    const [mealPlanSelection, setMealPlanSelection] = useState<IMealPlanSelectOption>(null);
    const [mealPlan, setMealPlan] = useState<IEditMealPlan>(null);
    
    const [selectedCells, setSelectedCells] = useState([]);

    const anchor = useRef<any>();
    const [popupVisible, setPopupVisible] = useState(false);
    const [firstOpen, setFirstOpen] = useState(true);

    const buttonLabel = props.buttonLabel ?? 'Meal Plan';
    const buttonIcon = props.buttonIcon ?? mdiPlus;

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
        setSelectedCells([]);

        if (firstOpen || !mealPlanOptions.length) {
            loadMealPlanOptions();
            setFirstOpen(false);
        }
    }

    const [mealPlanOptions, setMealPlanOptions] = useState([]);

    const allowSubmit = !!mealPlanSelection && !!mealPlanSelection.id && selectedCells.length > 0;

    useEffect(() => {
        if (!!mealPlanSelection){
            MealPlanService.getById(mealPlanSelection.id).then(result => {
                if (!!result) {
                    setMealPlan(result);
                }
            });
        }
    }, [mealPlanSelection]);

    const loadMealPlanOptions = () => {
        MealPlanService.search('').then(results => {
            setMealPlanOptions(results);
            if (!!results && results.length > 0){

                const currentMealPlanInResults = _.find(results, r => r.id == props.mealPlanId);
                if (!!currentMealPlanInResults){
                    setMealPlanSelection(currentMealPlanInResults);
                }
                else {
                    setMealPlanSelection(results[0]);
                }
            }
        });
    }

    const handleMealPlanChange = (event: DropDownListChangeEvent) => {
        setMealPlanSelection(event.target.value);
    }

    const onCloseClick = () => {
        setPopupVisible(false);
    }

    const add = () => {
        MealPlanService.addRecipe({
            recipeId: props.recipeId,
            mealPlanId: mealPlanSelection.id,
            cellIds: selectedCells,
        }).then(ok => {
            if (!!props.onCopyComplete){
                props.onCopyComplete();    
            }
            if (!!mealPlanSelection){
                MealPlanService.getById(mealPlanSelection.id).then(result => {
                    if (!!result) {
                        setMealPlan(result);
                    }
                });
            }
            setPopupVisible(false);
        })
    }

    const toggleCheckbox = (add, cellId: string) => {
        if (add) {
            var temp = selectedCells.concat(cellId);
            setSelectedCells(temp);
        } else {
            var temp = selectedCells.filter(c => c != cellId);
            setSelectedCells(temp);
        }
    }

    const anchorAlign: Align = {
        horizontal: 'center',
        vertical: 'top',
    }

    return (

        <>
            <div ref={anchor}>
                <Button type={"button"} onClick={togglePopup} size={props.smallButton ? "small" : "medium"}
                    className={'w-100'} disabled={props.disabled}><Icon path={buttonIcon}/>{buttonLabel}</Button>
            </div>
            <Popup anchor={anchor.current} show={popupVisible}
                popupAlign={anchorAlign}
                popupClass={'add-to-mealplan-popup'}>
                    <Card className={'recipe-popup-content'}>
                        <CardBody>
                        <div className='row mb-2'>
                            <div className='col col-md-12'>
                                <DropDownList data={mealPlanOptions} textField="name" dataItemKey="id"
                                    disabled={selectedCells.length > 0}
                                    value={mealPlanSelection} onChange={handleMealPlanChange} />
                                {!!mealPlan &&
                                <table>
                                    {mealPlan.rows.map((row) => 
                                        <tr>
                                            <td className="name-wrapper">
                                                {row.name}
                                            </td>
                                            {row.cells.map((cell) => 
                                                <td className="checkbox-wrapper">
                                                    <Checkbox size={"large"} className={cell.recipes.length ? 'has-items' : ''}
                                                        onChange={(e) => toggleCheckbox(e.target.value, cell.id)} hidden={cell.recipes.length >= 3} />
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </table>}
                            </div>
                        </div>
                        </CardBody>
                        <CardFooter>
                            <ButtonGroup>
                                <Button onClick={add} size={"small"} disabled={!allowSubmit}><Icon path={mdiCheck} />Add to Meal Plan</Button>
                                <Button onClick={onCloseClick} size={"small"}><Icon path={mdiClose}/>Close</Button>
                            </ButtonGroup>
                        </CardFooter>
                    </Card>
                <div>

                </div>
            </Popup>
        </>
    );
}