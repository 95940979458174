
export const TermsAndConditions = () => {
    return (
<div className="row">
    <div className="col-md-12">
        <h1>Terms and Conditions</h1>
        <p></p>

<p><strong>TERMS OF USE</strong></p>

<p>Wholistic Meal Plans Pty Ltd (ACN 662 157 735) and its associated entities (together, &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo; or &ldquo;<strong>our</strong>&rdquo;) provide you, a health industry professional, various information as well as the ability to create food intolerance friendly meal plans for your clients and patients. These Terms of Use are intended to explain our obligations and your obligations as a User of our Site.</p>

<p>These Terms of Use are binding on any use of our Site on any Device and apply to you from the time that we provide you with access to any of the Site. These Terms of Use will govern any other terms and conditions provided by us that replace and/or supplement the service, unless such other terms and conditions are accompanied by a separate agreement stating otherwise, in which case the terms of that agreement will govern and be paramount to the extent of any inconsistency.</p>

<p>You accept that your use or access of our Site and on any Device includes your unreserved acceptance of these Terms of Use and our Privacy Policy.</p>

<p>By using or accessing our Site, you acknowledge that you have read and understood and agree to be bound by these Terms of Use in their entirety in addition to any other applicable laws and regulations and that you have the authority to act on behalf of any person or entity for whom you are using the Site and you agree to these Terms of Use personally and on behalf that person or entity.</p>

<p>If you do not agree to any of these Terms of Use, you must not use the Site in any manner.</p>

<p>If you have any particular questions, please contact us by email at&nbsp;support@wholisticmealplans.com.au.</p>

<p><strong>1.&nbsp;PRIVACY&nbsp;POLICY</strong></p>

<p>Your privacy is very important to us. Please refer to our Privacy Policy at for further information about how we collect, use, store, process and disclose your personal information.</p>

<p><strong>2.&nbsp;DEFINITIONS</strong></p>

<p>Unless the context provides otherwise, the following capitalised terms have the following meaning in these Terms of Use:</p>

<p>&ldquo;<strong>Confidential Information</strong>&rdquo; means the confidential information of a party which relates to the subject matter of these Terms of Use and includes:</p>

<p>(a) confidential information relating to the technology and design of our Site including, but not limited to, algorithms, manuals, designs, diagrams and training videos of the Site;</p>

<p>(b) the Data;</p>

<p>(c) information relating to our personnel, policies or business strategies;</p>

<p>(d) information relating to the terms upon which the Site is provided to you;</p>

<p>(e) all information exchanged between the parties to these Terms of Use, whether in writing, electronically or orally, including the Site but does not include information which is, or becomes, publicly available other than through unauthorised disclosure by the other party.</p>

<p>&ldquo;<strong>Data</strong>&rdquo; means any data inputted by you or with your authority through the use of the Site and includes without limitation data owned or supplied by you or data which may otherwise be generated, compiled, arranged or developed using the Site by the User pursuant to these Terms of Use.</p>

<p>&ldquo;<strong>Device</strong>&rdquo; means any type of device including a computer, mobile phone, tablet or console.</p>

<p>&ldquo;<strong>GST</strong>&rdquo; has the meaning given by section 195-1 of the GST Act.</p>

<p>&ldquo;<strong>GST Act</strong>&rdquo; means the&nbsp;<em>A New Tax System (Goods and Services) Tax Act</em>&nbsp;1999 (Cth) as amended or replaced from time to time.</p>

<p>&ldquo;<strong>Intellectual Property Rights</strong>&rdquo; means all intellectual property rights, including all copyright, patents, trademarks, design rights, trade secrets, domain names, know how and other rights of a similar nature, whether registrable or not and whether registered or not, and any applications for registration or rights to make such an application.</p>

<p>&ldquo;<strong>Moral Rights</strong>&rdquo; has the meaning given under the&nbsp;<em>Copyright Act</em>&nbsp;1968 (Cth) and includes any similar rights existing in other countries.</p>

<p>&ldquo;<strong>Site</strong>&rdquo; means the website operating from the domain at https://wholisticmealplans.com.au/.</p>

<p>&ldquo;<strong>Terms of Use</strong>&rdquo; means these terms of use (as may be changed or updated from time to time by us.</p>

<p>&ldquo;<strong>User</strong>&rdquo; means any person, and where the context permits, includes any entity on whose behalf that person who uses (whether as a registered or unregistered user) or accesses the Site on any Device.</p>

<p>&ldquo;<strong>you</strong>&rdquo; means the User, and where the context permits, any person you authorise to use the Site on your behalf.</p>

<p>&ldquo;<strong>your</strong>&rdquo; has a corresponding meaning.</p>

<p><strong>3.&nbsp;USE OF SITE</strong></p>

<p>We grant you the right to access and use the Site. This right is non-exclusive, non-transferable, and limited by and subject to these Terms of Use.</p>

<p>We reserve the right to change, suspend, remove, or disable access to any part of the Site at any time without notice. In no event will we be liable for the removal of or disabling of access to any such part of the Site. We may also impose limits on the use of, or access to, the Site in any case and without any notice or liability.</p>

<p><strong>4.&nbsp;ELIGIBILITY</strong></p>

<p>As a condition of your using and accessing the Site, you represent and warrant that you:</p>

<p>(a) are aware and comply with all relevant and applicable laws regarding use of the information contained within the site;</p>

<p>(b) will only provide Data and other information that is complete, accurate and up to date;</p>

<p>(c) will not falsify any of your Data or other information;</p>

<p>(d) will only maintain one account at any given time, and if your account is ever suspended or terminated for any reason, you will not create another account;</p>

<p>(e) are not currently prohibited or otherwise restricted from using or accessing the Site;</p>

<p>(f) are not a competitor of us, and are not using or accessing the Site for the purposes of competing with our business;</p>

<p>(g) will not violate any of our or any other person&rsquo;s rights, including but not limited to, Intellectual Property Rights; and</p>

<p>(h) have full authority to enter into any agreement with us in connection with using or accessing the Site, including but not limited to, agreeing to be bound by these Terms of Use, and that doing so does not violate any other agreement which you have with any other party.</p>

<p><strong>5.&nbsp;YOUR&nbsp;OBLIGATIONS</strong></p>

<p><strong>5.1.&nbsp;You&nbsp;</strong>must<strong>&nbsp;not:</strong></p>

<p>(a) use the Site for any purpose or in any manner other than as set out in these Terms of Use;</p>

<p>(b) use the Site in any way that could damage our reputation or the goodwill or other rights associated with the Services; or</p>

<p>(c) permit any third party to use the Site other than as set out in these Terms of Use;</p>

<p>(d) attempt to undermine the security or integrity of our computing systems or networks or, where the Site are hosted by a third party, that third party&rsquo;s computing systems and networks;</p>

<p>(e) use, or misuse, the Site in any way which may impair the functionality of the Site, or other systems used to deliver the Site or impair the ability of any other user to use the Site;</p>

<p>(f) attempt to gain unauthorised access to any materials other than those to which you have been given express permission to access or to the Device on which the Site is hosted;</p>

<p>(g) transmit, or input into the Site, any files that may damage any other person&rsquo;s computing devices or software, content that may be offensive, or material or Data in violation of any law (including Data or other material protected by copyright or trade secrets which you do not have the right to use); and/or</p>

<p>(h) unless you have our prior written consent, rent, lease, lend, sell, redistribute or sublicense the Site, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Site, any updates, or any part thereof or any computer programs used to deliver and/or operate the Site (except as and only to the extent any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open sourced components included with the Site).</p>

<p>5.2. You must only use the Site for your own lawful personal or business purposes, in accordance with these Terms of Use and any notice sent by us and any other additional terms, conditions, policies, rules, disclaimers and notices displayed elsewhere on the Site may be changed or updated from time to time by us. You may use the Site on behalf of others or in order to provide services to others but if you do so you must ensure that you are authorised to do so and that all persons for whom or to whom goods and/or services are provided, comply with and accept all Terms of Use that apply to you.</p>

<p>5.3. You must ensure you protect the Site at all times from unauthorised access, use or damage and you must ensure that all usernames and passwords required to access the Site are kept secure and confidential. You must immediately notify us of any unauthorised use of your passwords or any other breach of security and you must reset your password and you must take all other actions that we reasonably deem necessary to maintain or enhance the security of our computing systems and networks and your access to the Site.</p>

<p>5.4. As a condition of these Terms of Use, when using or accessing the Site the User must pay all monies as and when due to us.</p>

<p>5.5. Your use of any third party application from within the Site is subject to that party&rsquo;s terms of use and conditions and you confirm that you have read and accepted that party&rsquo;s terms of use and conditions.</p>

<p>5.6. You will be responsible for providing your own Device and other access facilities (including terminal, software, internet access, modem and telecommunications facilities) necessary for utilising the Site. You must ensure that any Device on which the Site is used are in good, up to date working order and operating condition. We accept no responsibility or liability for any deficiency relating to your Device and other access facilities. Additionally, you acknowledge and agree that you will be solely liable for any fees or other charges incurred by you in connection with using or accessing the Site, including but not limited to software, hardware, equipment and internet usage charges.</p>

<p><strong>6.&nbsp;USAGE</strong>&nbsp;<strong>LIMITATIONS</strong></p>

<p>Use of the Site may be subject to limitations. Any such limitations will be advised.</p>

<p><strong>7.&nbsp;SUITABILITY&nbsp;OF GOODS</strong></p>

<p>You agree and&nbsp;<strong>acknowledge</strong>&nbsp;that:</p>

<p>(a) save for any express representations and warranties, we make no representations as to the suitability, quality or acceptability of any meal plans generated and/or with regard to your or your clients particular needs (including any allergies or illnesses you or they may have);</p>

<p>(b) if there are any issues with regard to the suitability, quality or acceptability of any meal plan provided to you, you will contact us directly in respect of such issues;</p>

<p><strong>8.&nbsp;FEES&nbsp;AND&nbsp;PAYMENTS</strong></p>

<p>8.1. Wholistic Meal Plans subscribers can purchase monthly or yearly membership options. By purchasing one of those options, you are committing to that option of term chosen. Memberships are billed on a recurring basis, and at the end of your nominated term, our payment provider will process the applicable membership fee. This processing of fee will continue to occur unless you cancel your subscription before the next billing date. You can find your billing information the profile page. We do not refund any subscriptions for late cancellations or unused membership time. You may cancel your membership at anytime by emailing us at <a data-darkreader-inline-color="" href="mailto:support@wholisticmealplans.com.au">support@wholisticmealplans.com.au</a>. Charges are in Australian dollars (AUD). It is the responsibility of purchasers outside of Australia to pay the applicable taxes in their state or country.</p>

<p>&nbsp;</p>

<p>8.2. You acknowledge that where you purchase any membership through our Site, you will be responsible for payment in full (without any set-off or deduction) before an account can be established or continued.</p>

<p>8.3. Where such payments are taken through the Site, you acknowledge and accept that such payments are not processed or taken by us and instead are processed by a third party payment gateway (and you agree to be bound by any policies and terms applicable to that payment gateway).</p>

<p><strong>9.&nbsp;CONFIDENTIALITY</strong></p>

<p>9.1. Unless the&nbsp;<strong>relevant</strong>&nbsp;party has the prior written consent of the other or unless required to do so by law:</p>

<p>(a) each party will preserve the confidentiality of all Confidential Information of the other obtained in connection with these Terms of Use. Neither party will, without the prior written consent of the other, disclose or make any Confidential Information available to any person, or use the same for its own benefit, other than as contemplated by these Terms of Use;</p>

<p>(b) each party&rsquo;s obligations under this clause will survive termination of these Terms of Use; and</p>

<p>(c) you shall take all reasonable steps to ensure that your employees, agents, subcontractors or related entities, do not make public or disclose our Confidential Information. If you become aware that any of your employees, agents, sub-contractors or related entities passed on any Confidential Information to any other party, then you shall notify us in writing immediately.</p>

<p>9.2. The&nbsp;<strong>provisions</strong>&nbsp;of this clause shall not apply to any information which:</p>

<p>(a) is or becomes public knowledge other than by a breach of this clause;</p>

<p>(b) is received from a third party who lawfully acquired it and who is under no obligation restricting its disclosure;</p>

<p>(c) is in the possession of the receiving party without restriction in relation to disclosure before the date of receipt from the disclosing party; or</p>

<p>(d) is independently developed without access to the Confidential Information.</p>

<p><strong>10.&nbsp;INTELLECTUAL&nbsp;PROPERTY</strong></p>

<p>10.1. Title to, and all Intellectual Property Rights in the Site and any documentation relating to the Site remain our (and/or our licensors) sole property.</p>

<p>10.2. Nothing in these Terms of Use constitutes a transfer of any Intellectual Property Rights.</p>

<p>10.3. Nothing transfers to you ownership of the Site or the recipes or our Intellectual Property Rights in relation to the Site or the recipes.</p>

<p>10.4. You acknowledge that we (and/or our licensors) own all the Intellectual Property Rights in the Site and the recipes.</p>

<p>10.5. We retain full legal rights in and title to the Site and recipes whether in its original form or as modified by you or us.</p>

<p>10.6. You will not directly or indirectly do anything that would or might invalidate or put in dispute our title in the Site and the recipes.</p>

<p>10.7. If any person makes any claim alleging that any information of the Site infringes any Intellectual Property Rights or Moral Rights of any person, you must:</p>

<p>(a) promptly notify us in writing; and</p>

<p>(b) co-operate with, assist and act at all times in accordance with our reasonable instructions, in relation to the claim and any consequent investigations, negotiations, settlement and dispute resolution proceedings.</p>

<p>10.8. You must not:</p>

<p>(a) permit any person to link to any page containing any part of the Site (including via a hyperlink or RSS feed) without our prior written consent;</p>

<p>(b) except as expressly permitted by these Terms of Use, and except to the extent that applicable laws, including the&nbsp;<em>Copyright Act</em>&nbsp;<em>1968</em>&nbsp;(Cth), prevent us restraining you from doing so:</p>

<p>(c) reproduce, make error corrections to or otherwise modify or adapt the Site or the recipes or create any derivative works based upon the Site or the recipes;</p>

<p>(d) de-compile, disassemble or otherwise reverse engineer the Site or the recipes or permit any third party to do so; or</p>

<p>(e) modify or remove any copyright or proprietary notices on the Site or the recipes.</p>

<p>10.9. The materials displayed on, or contained within Site, or exported from the site including without limitation all software, design, text, editorial materials, informational text, photographs, illustrations, images, tools, artwork and other graphic materials, and names, logos, trademarks and service marks (the &ldquo;Materials&rdquo;), are the property of Wholistic Meal Plans and are protected by copyright, trade-mark or other intellectual property laws. Our materials can be used on the Site or in the original Portable Document Format (&quot;PDF&quot;) but any use of the materials outside of the Site or the original PDF file generated from (or on) the Site without express written consent of Wholistic Meal Plans is prohibited. Usage of our photography, recipes or any other Materials for marketing purposes, outside of the original PDF is prohibited.</p>

<p><strong>11. DATA</strong></p>

<p>11.1. You agree that we may collect, process, use, disclose, store, and back-up your Data for any purpose (including the purpose of enabling you to use or access the Site and purchase the Products and any other purpose related to provision of services to you) subject to our Privacy Policy.</p>

<p>11.2. You acknowledge and agree that:</p>

<p>(a) where you create a user account or user profile, we may collect information from you (including personal information) in support of your registration. You authorise us to retain such inputted information;</p>

<p>(b) where you interact with our Site, with or without a user account or profile, we may cache any transmitted data for a period of up to 24 hours (following which such data will be erased); and</p>

<p>(c) where we collect, process, use, disclose, store, and back-up any of your personal information we will do so in accordance with our Privacy Policy.</p>

<p>11.3. You agree that we may collect and use the Data and other technical and related information, including but not limited to information about your Device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to you (if any) related to the Site. We may use this information, as long as it is in a form that does not personally identify you, to improve our products or to provide services or technologies to you.</p>

<p><strong>13.&nbsp;THIRD&nbsp;PARTY APPLICATIONS&nbsp;AND YOUR DATA</strong></p>

<p>13.1. If you enable third party applications for use in conjunction with the Site you acknowledge that we may allow the providers of those third party applications to access your Data as required for the interoperation of such third party applications with the Site.</p>

<p>13.2. You agree that where you use any third party applications or services to interact with our Site in any way, you do so at your own risk and on the understanding that we are not responsible for any claims or loss arising out of, or in any way related to, your use of such third party applications or services and/or the interaction of such third party applications or services with our Site.</p>

<p><strong>14.&nbsp;WARRANTIES&nbsp;AND ACKNOWLEDGEMENTS</strong></p>

<p>14.1. We do not warrant that the use of the Site will be uninterrupted or error free. Among other things, the operation and availability of the systems used for accessing the Site, including public telephone services, computer networks, the internet and any Device, can be unpredictable and may from time to time interfere with or prevent access to the Site. We are not in any way responsible for any such interference or prevention of your use of or access to the Site.</p>

<p>14.2. You warrant and acknowledge that:</p>

<p>(a) you are authorised to use and access the Site and to access the information and Data that you input into the Site, including any information or Data input into the Site by any person you have authorised to use the Site.</p>

<p>(b) you are authorised to access the processed information and Data that is made available to you through your use of the Site (whether that information and Data is your own or that of anyone else);</p>

<p>(c) we have no responsibility to any person other than you and nothing in these Terms of Use confers, or purports to confer, a benefit on any person other than you;</p>

<p>(d) you are responsible for authorising any person who is given access to information or Data, and you agree that we have no obligation to provide any person access to such information or Data without your authorisation and may refer any requests for information to you to address; and</p>

<p>(e) the provision of, access to, and use of, the Site is on an &ldquo;as is&rdquo; basis and at your own risk;</p>

<p>(f) it is your sole responsibility to determine that the Site meets your personal needs and requirements and are suitable for the purposes for which they are used;</p>

<p>(g) you remain solely responsible for complying with laws applicable to you; and</p>

<p>(h) it is your responsibility to check that storage of and access to your Data via the Site will comply with laws applicable to you (including any laws requiring you to retain records).</p>

<p>14.3. You acknowledge and warrant that if you use our Site on behalf of or for the benefit of anyone other than yourself (whether a body corporate or otherwise):</p>

<p>(a) you are responsible for ensuring that you have the right to do so;</p>

<p>(b) you agree that you have obtained that person&rsquo;s authority and consent to use the Site on their behalf and have the authority to agree to these Terms of Use on behalf of that person;</p>

<p>(c) you agree that by registering to use the Site you bind the person on whose behalf you act to the performance of any and all obligations that you become subject to by virtue of these Terms of Use, without limiting your own personal obligations under these Terms of Use; and</p>

<p>(d) the provision of, access to, and use of, the Site is on an &ldquo;as is&rdquo; basis and at your (and that person&rsquo;s) own risk.</p>

<p><strong>15.&nbsp;OUR&nbsp;LIABILITY</strong></p>

<p>15.1. You acknowledge and agree that the Site and meal plans cannot be tested in every possible combination, operating condition or application, and how and for what purpose the Site and the meal plans are used by you is not within our control.</p>

<p>15.2. To the maximum extent permitted by law:</p>

<p>(a) we exclude all warranties and representations as to the correctness, accuracy, adequacy, completeness, currency, reliability, timeliness or usefulness of any information or other content posted on the Site;</p>

<p>(b) we do not guarantee that the Site, or the server supporting the Site, is free from defects, viruses or other harmful components, or will be uninterrupted or error free;</p>

<p>(c) we do not guarantee that the meal plans are free from any defects or errors;</p>

<p>(d) we accept no liability for the loss of Data where such loss is wholly or partly caused by factors beyond our reasonable control including, but not limited to, failure or fault in the hosted environment of the Site, defective network or internet connections, defective equipment utilised by you or incorrect operation by you of your own Device or other access facilities;</p>

<p>(e) we shall not be responsible for any disclosure, use, modification or deletion of your Data resulting from any access by third party application providers; and</p>

<p>(f) neither us, nor our directors, officers, employees, contractors or agents, will be liable for any direct, indirect, consequential or other loss or damage to any person or entity, however caused (whether by negligence or otherwise), arising in connection with your use of, or inability to use, the Site or the meal plans, or reliance upon any of the content or other information posted on the Site or the meal plans.</p>

<p>15.3. Save for any terms, conditions, guarantees, warranties, indemnities or other rights which may arise under the Australian Consumer Law or other legislation and which cannot be excluded:</p>

<p>(a) all warranties, whether express or implied including warranties as to fitness for any purpose and merchantability are expressly excluded; and</p>

<p>(b) to the extent legally possible any liability that we may have to the User in relation to the Site which cannot be excluded shall be limited to, at our discretion:</p>

<p>(i) either the supplying of the meal plan again; or</p>

<p>(ii) the payment of the cost of having the meal plan supplied again.</p>

<p>15.4. We shall not be liable for:</p>

<p>(a) any injury, loss, expense or damages (including loss of life) of any kind whatsoever or however arising whether directly, indirectly, consequently or contingently to any person or property and without limiting the generality of the foregoing; and</p>

<p>(b) any losses, expenses or damages caused by delays or any other reasons or additional expenses incurred by the User including losses of prospective profits or actual profits incurred by the User.</p>

<p>15.5. <strong>The site provides </strong>nutrition(al) data gathered primarily from the Food Standards Australia New Zealand (FSANZ) database, whenever available, or otherwise other online calculators.</p>

<p>(a) Nutrition information can vary for a recipe based on factors such as precision of measurements, brands, ingredient freshness, or the source of nutrition data. We strive to keep the information as accurate as possible, but make no warranties regarding its accuracy. We encourage users to make their own calculations based on the actual ingredients used in the recipe using your preferred nutrition calculator.</p>

<p>(b) All the recipes contained within the site are sourced and adapted to be intolerance friendly. However, we do not have control over the ingredients you use to make these recipes nor the environment in which you are making them. If we list specific foods or brand of ingredients, the user acknowledges that companies do change their products and ingredients at times, and you should always read labels. If you are following a gluten-free diet, for example, check on the gluten-free status of a product or ingredient yourself.</p>

<p>(c) When an ingredient is mentioned that may contain, for example, gluten (such as oats or tamari), I am always referring to a gluten-free version of that ingredient whether noted or not. I am not responsible or liable for any reactions that might result from following the posted recipes. Your clients health is their and your responsibility.</p>

<p><strong>16.&nbsp;INDEMNITY</strong></p>

<p>You agree to indemnify and hold us and our officers, employees and agents harmless (&ldquo;<strong>those indemnified</strong>&ldquo;) from and against any action, liability, claim, loss, damage, proceeding, expense (including reasonable legal costs and expenses) suffered or incurred by any of those indemnified, whether directly or indirectly, in connection with:</p>

<p>(a) your breach or non-observance of any of these Terms of Use;</p>

<p>(b) your breach of any obligation you may have to us;</p>

<p>(c) any breach or inaccuracy in any of your representations or warranties;</p>

<p>(d) your use or access of the Site or the meal plans;</p>

<p>(e) your or any third party&rsquo;s instructions on the meal plans;</p>

<p>(f) any Data submitted by you;</p>

<p>(g) our possession, processing, use or other handling of the Data or related data, documentation or records;</p>

<p>(h) our refusal to provide any person access to your information or Data in accordance with these Terms of Use; and</p>

<p>(i) us making available information or Data to any person with your authorisation.</p>

<p><strong>17.&nbsp;ACKNOWLEDGEMENT AND RELEASE</strong></p>

<p>17.1. You&nbsp;<strong>acknowledge</strong>&nbsp;and agree that:</p>

<p>(a) By using or accessing the Site, you may encounter content that may be deemed offensive, indecent, or objectionable, which content may or may not be identified as having explicit language, and that the results of any search or entering of a particular URL may automatically and unintentionally generate links or references to objectionable material. Nevertheless, you agree to use or access the Site at your sole risk and that we shall not have any liability to you for any content that may be found to be offensive, indecent, or objectionable.</p>

<p>17.2. You agree that, to the maximum extent permitted by law, you release us from any and all Claims suffered by you, in connection with:</p>

<p>(a) any loss, cost or damage you suffer as a result of (or in any way connected to) any meal plan or recipe;</p>

<p>(b) your use of our Site or the meal plans or recipes (except in the manner contemplated by these Terms of Use);</p>

<p>(c) any collection, use, processing or disclosure of your personal information in accordance with our Privacy Policy; and</p>

<p>(d) the protection and privacy of your personal information (except as set out in our Privacy Policy).</p>

<p><strong>18.&nbsp;FEEDBACK AND REVIEW</strong></p>

<p>18.1. From time to time, we may request that you provide us with feedback or reviews on the Site. You are not obliged to provide such feedback.</p>

<p>18.2. From time to time, we may also request that you provide us with feedback or reviews with respect to the meal plans and recipes on our Site. You are not obliged to provide such feedback.</p>

<p>18.3. You acknowledge and agree that we, at our sole discretion, may determine whether your comment is to be published on our Site.</p>

<p>18.4. You acknowledge and agree that where you intend to post any negative review or comment with respect to the Site, recipes or meal plans on any external review site or social media platform, you will contact us and provide us with an opportunity to discuss your concerns before publishing such negative review or comment.</p>

<p><strong>19.&nbsp;SOCIAL MEDIA</strong></p>

<p>19.1. You agree that, where our Site integrates in any way with social media, with respect to such integrations, you will adhere to these Terms of Use and any other social media policy enacted by us from time to time.</p>

<p>19.2. With respect to social media, for the purposes of marketing and/or promotion, you hereby acknowledge and authorise us to:</p>

<p>(a) upload, publish, post or repost any content which directly or indirectly references content that you have uploaded, published, posted or reposted in respect of us and/or our Site, recipes or meal plans;</p>

<p>(b) make use of any publicly available information (including venue information and imagery) for the purposes of any publication, post or repost.</p>

<p>19.3. For the avoidance of doubt, by using the Site, you agree and consent to us undertaking any of the activities contemplated by clause 20.2 without the need to obtain any further consent from you.</p>

<p><strong>20.&nbsp;TERMINATION</strong></p>

<p>20.1. Your rights under these Terms of Use to use the Site will terminate automatically without notice from us if you fail to comply with these Terms of Use.</p>

<p>20.2. Upon termination, you shall cease all use of the Site.</p>

<p>20.3. Any termination shall not affect any accrued rights or liabilities of either party, nor shall it affect any provision of these Terms of Use which is expressly or by implication intended to continue in force after such termination.</p>

<p><strong>21.&nbsp;ASSIGNMENT&nbsp;AND NOVATION</strong></p>

<p>You may not assign or transfer any rights under these to any other person without our prior written consent.</p>

<p><strong>22.&nbsp;OUR&nbsp;RIGHTS</strong></p>

<p>Any express statement of a right belonging to us under these Terms of Use is without prejudice to any of our other rights expressly stated in these Terms of Use or existing at law.</p>

<p><strong>23.&nbsp;GOVERNING&nbsp;LAW</strong></p>

<p>The parties to these Terms of Use shall be bound by the laws of the State of Victoria in relation to all matters arising from all contracts between the parties and the parties agree to submit to the non-exclusive jurisdiction of the Court of Victoria and the Federal Courts of Australia and that any legal proceedings may be heard in these Courts.</p>

<p><strong>24.&nbsp;SEVERABILITY</strong></p>

<p>If any part or provision of these Terms of Use is invalid, unenforceable or in conflict with the law, that part or provision is replaced with a provision which, as far as possible, accomplishes the original purpose of that part or provision. The remainder of these Terms of Use will be binding on the parties.</p>

<p><strong>25.&nbsp;NOTICES</strong></p>

<p>25.1. Any notice given under these Terms of Use by either party to the other must be in writing by email and will be deemed to have been given on transmission.</p>

<p>25.2. Notices must be sent to support@wholisticmealplans.com.au&nbsp;or to any other email address notified by email to you by us.</p>

<p>25.3. Notices to you may be sent via electronic messages, including email, text message/SMS, or mobile push notifications, to the email address or numbers which you provided when setting up your access to the Site.</p>

<p><strong>26.&nbsp;RIGHTS&nbsp;OF THIRD PARTIES</strong></p>

<p>A person who is not a party to these Terms of Use has no right to benefit under or to enforce any term of these Terms of Use.</p>

<p><strong>27.&nbsp;VARIATION&nbsp;TO TERMS</strong></p>

<p>We reserve the right to change, modify, add or remove portions of these Terms of Use at any time without notice to you, effective upon posting it on the Site. By continuing to use or access the Site and purchasing the Products following any changes to these Terms of Use, you signify that you have read, understood and agree to be bound by the updated Terms of Use.</p>

    </div>
</div>
    );
}