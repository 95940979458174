import { useNavigate } from 'react-router';
import { Button } from '@progress/kendo-react-buttons';
import logo from '../../assets/images/wmp-logo.png';
import section2aus from '../../assets/images/section-2-aus.png';
import section2branded from '../../assets/images/section-2-branded.png';
import section2chef from '../../assets/images/section-2-chef.png';
import section2diet from '../../assets/images/section-2-diet.png';
import section3mealplans from '../../assets/images/section-3-mealplans.png';
import section3tags from '../../assets/images/section-3-tags.jpg';
import section3recipes from '../../assets/images/section-3-recipes.jpg';
import section5feature from '../../assets/images/section-5-feature.png';


export const Home = () => {
    const navigate = useNavigate();

    const signUp = () => {
      navigate('/SignUp');
    }

    return (
        <div className="homepage">
          <div className="row">
            <div className="col col-md-12 offset-md-0">
              <div className="section-1">
                <div className="leading-image-1"></div>
                <div className="main-box">
                  <div className="row">
                    <div className="col col-md-12">
                      <p className="big-bold">Meal Planning Software for Health Professionals who want to create customised meal plans for their clients</p>
                      <p>Fully customised meal planning software with personal business branding making it easy for you to uplevel your services.</p>
                    </div>
                    <div className="col col-md-12 button-container">
                      <Button type={"button"} onClick={signUp}>Sign Up</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-2">
                <div className="row">
                  <div className="col col-md-3">
                    <div className="main-box">
                      <div className="image-container">
                        <img src={section2aus} />
                      </div>
                      <p className="big-bold">Designed for the AUS/NZ Market</p>
                      <p className="small-text">Meals and ingredients that are relevant for your Australian and New Zealand clients.</p>
                    </div>
                  </div>
                  <div className="col col-md-3">
                    <div className="main-box">
                    <div className="image-container">
                        <img src={section2chef} />
                      </div>
                      <p className="big-bold">Recipes developed by nutritionists and chefs</p>
                      <p className="small-text">Every recipe is tried and tested in our kitchen by our Chefs and Nutritionists ensuring each recipe works, tastes great and is nutritionally balanced.</p>
                    </div>
                  </div>
                  <div className="col col-md-3">
                  <div className="main-box">
                  <div className="image-container">
                        <img src={section2branded} />
                      </div>
                      <p className="big-bold">Develop your own business branded meal plans</p>
                      <p className="small-text">Each meal plan includes your own branding.</p>
                    </div>
                  </div>
                  <div className="col col-md-3">
                    <div className="main-box">
                    <div className="image-container">
                        <img src={section2diet} />
                      </div>
                      <p className="big-bold">Create plans for even the most complex dietary requirements</p>
                      <p className="small-text">Search filters making it easy to source and include meals for clients with complex dietary needs and preferences.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-3">
                <div className="row">
                  <div className="col col-lg-6 hide-on-mobile">
                  </div>
                  <div className="col col-lg-6 col-md-12">
                    <p className="big-bold">A recipe database full of nutritionally dense, intolerance friendly meals that have been developed and adapted by our expert in-house Chefs and Nutritionists</p>
                  </div>
                </div>
                <div className="row white-bg">
                  <div className="col col-lg-5 offset-lg-1">
                    <div className="main-box">
                      <img className="tags" src={section3tags}></img>
                    </div>
                    <div className="small-text narrow">From Anti-candida to low histamine, we've created hundreds of recipes for over <b>25 commonly diagnosed food intolerances</b>, providing meal options for a range of breakfasts, lunches, sides, dinners, salads, snacks, desserts and beverages.</div>
                    <div className="green-box">
                      <img className="customised-mealplans" src={section3mealplans} />
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <img className="how-it-works" src={section3recipes}></img>
                    <div className="small-text"><b>How does it work?</b> Simply filter your intolerance(s), exclude any ingredients you may not like, find your recipes, and add the recipe to your clients meal plan. For example, you have a client who is gluten and dairy intolerant who is also histamine intolerant and doesn't like capsicum? Simple. Enter those details into the search field and only the meals that match those parameters will show, allowing you to design a nutritionally balanced meal plan that takes all the guess work out of the equation. All meals have macros per serving calculated as well as vitamins and minerals levels listed. Once your meal plan is complete, it can be exported to a wonderful PDF booklet that is customised with your Business Logo ready to send to your client.</div>
                    <div className="button-container">
                      <Button type={"button"} onClick={signUp}>Sign Up</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-4-header">
                <p className="big-bold">Features and Benefits</p>
              </div>
              <div className="section-4">
                <div className="row">
                  <div className="col col-md-4">
                    <div className="main-box">
                      <p className="big-bold">Simple and streamlined</p>
                      <ul>
                        <li>100's of meals to choose from with more added monthly</li>
                        <li>Nutritional breakdown for each meal provided from the Food AU/NZ database</li>
                        <li>Automatic shopping list created for each meal plan</li>
                        <li>Top tier customer service from our in-house business and tech support</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <div className="main-box">
                      <p className="big-bold">Use the software for</p>
                      <ul>
                        <li>1:1 clients</li>
                        <li>Group programs and online courses</li>
                        <li>Workshops, cooking classes and corporate wellness programs</li>
                        <li>Producing digital products</li>
                        <li>Increasing your service offerings</li>
                        <li>Generating leads</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <div className="main-box">
                      <p className="big-bold">Ensuring success for you and your client</p>
                      <ul>
                        <li>Saves you time and increases your earnings</li>
                        <li>Helps you deliver the highest quality of care for your clients</li>
                        <li>Improves treatment plan compliance</li>
                        <li>Improves client retention</li>
                        <li>Produces plans with your logo</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-5">
                <div className="row">
                  <div className="col col-md-7">
                    <p className="uppercase">Why we developed this for you</p>
                    <p className="big-bold">A program designed to save you time, increase your income and improve client health outcomes</p>
                    <p className="small-text">Wholistic Meal Plans was born from the frustration experienced by our founder, Clinical Nutritionist - Danyel Waters, at the amount of time it took to develop and deliver nourishing, nutrient dense meal plans for patients with complex food intolerances or preferences.</p>
                    {/* <div className="button-container">
                      <Button type={"button"}>Learn More</Button>
                    </div> */}
                  </div>
                  <div className="col col-md-5">
                    <img className="feature-image" src={section5feature} />
                  </div>
                </div>
              </div>
              <div className="section-6">
                <div className="row">
                  <div className="col col-md-12">
                    <p className="header">What our Health Professional users are saying</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-6 col-md-12 review">
                    <p className="big-bold">"It was such a pleasure to work with the Wholistic Meal Plans program"</p>
                    <p className="small-text">Easy to locate recipes, navigate the system to create meal plans that are enjoyable and adhere to my client's dietary intolerances and all the while being (and for me this was the winner) an <strong>Australian-based</strong> program. Thank you, Team Wholistic Meal Plans, I look forward to continuing to use the program in my clinic.</p>
                    <p className="author">Dr. Sara Fieschi</p>
                    <div className="circle-image sara"></div>
                  </div>
                  <div className="col col-lg-6 col-md-12 review">
                    <p className="big-bold">"I've been able to add an additional add on service without increasing my hours in Clinic"</p>
                    <p className="small-text">Being able to offer customised meal planning as a service has increased the value of my treatment plans without adding hours of work outside of consultations. I love being able to filter the recipes by intolerance to really support my clients at that next level. Its so easy to use!</p>
                    <p className="author">Bec<br/>Nutritionist</p>
                    <div className="circle-image bec"></div>
                  </div>
                  <div className="col col-lg-6 col-md-12 review">
                    <p className="big-bold">"This is saving me so much time!"</p>
                    <p className="small-text">I love using this program to create meal plans I know my clients will love. Before using this program I avoided writing meal plans because of the time involved, Wholistic Meal Plans solved that problem!</p>
                    <p className="author">Courtney<br/>Naturopath and Nutritionist</p>
                    <div className="circle-image courtney"></div>
                  </div>
                  <div className="col col-lg-6 col-md-12 review">
                    <p className="big-bold">"The Meal Plans are super detailed for clients and so easy to use"</p>
                    <p className="small-text">I was lucky enough to be part of the original beta testing team for the Wholistic Meal Plans software. It has helped me in my business saving so much time and is super easy to use. I am excited to continue using the software to uplevel my clients' meal plans and save me loads of time in the process.</p>
                    <p className="author">Erin<br/>Personal Trainer</p>
                    <div className="circle-image empty"></div>
                  </div>
                </div>
              </div>
              <div className="section-7">
                <div className="row">
                  <div className="col col-md-12">
                    <p className="header">Launching Sale Price</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <div className="membership-circle">
                      <div className="name">Foundation</div>
                      <div className="price">$39 AUD</div>
                      <div className="duration">per month</div>
                    </div>
                    <p className="big-bold hide-on-mobile">Foundation Membership</p>
                    <p className="small-text"><i>Cancel at any time</i></p>
                    <p className="price-green">$39 AUD</p>
                    <div className="row">
                    <div className="col col-md-12">
                      <div className="button-container">
                        <Button type={"button"} onClick={signUp}>Get Started</Button>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div className="col col-md-4 md-offset-2 main-box">
                    <p className="big-bold">Foundation Membership</p>
                    <p className="small-text"><i>Limited Time Offer</i></p>
                    {/* <p className="small-text"><a href='mailto:support@wholisticmealplans.com.au' >Contact us for more info</a></p> */}
                  </div>
                </div>
                
                
              </div>
              <div className="section-8">
                <div className="row">
                  <div className="col col-lg-12">
                    <p className="header">Frequently Asked Questions</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-12">
                    <p className="big-bold">Am I locked into a contract or can I cancel at any time?</p>
                    <p>You can cancel your subscription at any time, without any cancellation fees. Your subscription will continue until the end of your current billing cycle, although we think you’ll love it just as much as we do, and it will become a valuable part of your everyday profession.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-12">
                    <p className="big-bold">What intolerances do you cater for?</p>
                    <p>At present, we have hundreds of recipes catering for intolerances such as; anti-candida, auto immune, celiac, dairy free, low glycaemic, egg free, fodmap, gaps, gluten free, grain free, high protein, keto, low carb, low histamine, low lectin, low oxalate, nightshade free, nut free, paleo, pescatarian, pork free, refined sugar free, sibobiphasic1, sibobiphasic2, sulphite free, vegan, vegetarian.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-12">
                    <p className="big-bold">Is there a cap on the amount of Meal Plans I can produce?</p>
                    <p>No, there are no caps on the amount of meal plans you can create and export. You can save up to 100 meal plans under your account.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-12">
                    <p className="big-bold">I have multiple practitioners at my clinic, can we all use the same login?</p>
                    <p>Your user login is specific and unique to you. Please contact us for more information on our Clinic Pricing schedule.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-12">
                    <p className="big-bold">Can we edit the current recipes or add our own recipes?</p>
                    <p>We are currently working on this functionality, and will have updates very soon.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        );
}