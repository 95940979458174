import { useState } from 'react';
import { Alert } from 'reactstrap';
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

export const UpdatePaymentForm = () => {

  const [loading, setLoading] = useState(false);

    // Stripe
    const stripe = useStripe();
    const elements = useElements();

    const [stripeError, setStripeError] = useState('');

    const handleSubmit = async (formData) => {

        let returnUrl = window.location.origin;
        returnUrl += `/UpdatePaymentResult`;
        
        if (!stripe || !elements) {          
          return;
        }

        setLoading(true);

        const {error} = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: returnUrl,
          },
        });
  
        if (error) {
          setStripeError(error.message);
        }

        setLoading(false);
    }

  const UpdateButton = () => {
      if (loading) {
          return <Button type={'button'} disabled={true}><span className='loader inline mr-1'></span> Updating...</Button>
      } else {
          return <Button type={'button'} onClick={handleSubmit}>Update my Payment Details</Button>
      }
  }
    
      return (
        <>
          <Form render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <PaymentElement className='mt-3 mb-4' />
              <UpdateButton />
              <Alert className={'mt-3'} isOpen={!!stripeError} color="danger">{stripeError}</Alert>
            </FormElement>
          )} />
        </>
      )
}