export const notEmpty = (value: string) => {
    if (value == undefined || value == null || value == '') {
        return 'Field is required.';
    }
}

export const numberOrNull = (value: string) => {
    
    if (value == undefined || value == null || value == '') return '';
    
    if (isNaN(parseFloat(value))) return 'Not a valid number.';

    return '';
}

export const numberZeroOrGreater = (value: string) => {
    if (value == undefined || value == null) return 'Field is required.';

    if (isNaN(parseFloat(value))) return 'Not a valid number.';

    if (parseFloat(value) < 0) return 'Value must be a positive number.';

    return '';
}

export const numberGreaterThanZero = (value: string) => {
    if (value == undefined || value == null) return 'Field is required.';

    if (isNaN(parseFloat(value))) return 'Not a valid number.';

    if (parseFloat(value) < 1) return 'Value must be a positive number.';

    return '';
}

export const tagsValid = (values: string[]) => {

    for (let i = 0; i < values.length; i++){

        const tag = values[i];

        if (tag.length > 32) {
            return `"${tag}" is too long. Tags must 32 characters or less.`;
        }

        if (!tag.match(/^[A-Za-z0-9]+$/)) {
            return `"${tag}" is not valid. Tags may only contain alphabets and numbers.`;
        }
    }

    return '';
}

export const validPassword = (value: string) => {

    const requiredLength = 8;

    if (value == undefined || value == null) {
        return 'Password is required.';
    }
    
    if (value.length < requiredLength) {
        return `Password needs to be at least ${requiredLength} characters.`;
    }

    if (value.toLowerCase() === value) {
        return 'Password must have atleast one uppercase character.';
    }

    if (value.toUpperCase() === value) {
        return 'Password must have atleast one lowercase character.';
    }

    if (!/\d/.test(value)) {
        return 'Password must have atleast one numeric character.';
    }
    
    return '';
}

export const validUserName = (value: string) => {
    notEmpty(value);

    const alphaNumericRegex = /^[A-Za-z0-9]+$/;

    const minLength = 5;
    const maxLength = 20;

    if (value.length < minLength) {
        return `Username needs to be between ${minLength} and ${maxLength} characters.`;
    }

    if (value.indexOf(' ') !== -1) {
        return `Username cannot contains spaces.`;
    }

    if (!alphaNumericRegex.test(value)) {
        return `Username can only contains alphabets and numbers.`;
    }
}

export const validPhoneNumber = (value: string) => {
    notEmpty(value);

    // Taken from https://uibakery.io/regex-library/phone-number
    const regex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    if (!regex.test(value)){
        return `Phone number is not valid.`;
    }
}

export const validEmail = (value: string) => {
    notEmpty(value);

    // Taken from https://uibakery.io/regex-library/email
    const regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    if (!regex.test(value)){
        return `Email address is not valid.`;
    }
}

export const FormValidation = {
    notEmpty,
    numberOrNull,
    numberZeroOrGreater,
    numberGreaterThanZero,
    tagsValid,
    validPassword,
    validUserName,
    validPhoneNumber,
    validEmail,
}