import { get, execute } from 'services/Middleware';
import { ICreateIngredientCommand, IDeleteIngredientCommand, IUpdateIngredientCommand } from 'pages/Ingredients/IngredientCommands';

/* Queries */

export const search = (keywords: string, onlyCustom: boolean) => {
    const path = `/ingredients/list?keywords=${keywords.toString()}&onlyCustom=${onlyCustom}`;
    return get(path);
}

export const getById = (id: string) => {
    const path = `/ingredients/get?id=${id.toString()}`;
    return get(path);
}

export const getSelectItems = (keywords: string) => {
    const path = `/ingredients/getSelectItems?keywords=${keywords.toString()}`;
    return get(path);
}

/* Commands */

export const create = (command: ICreateIngredientCommand) => {
    return execute('CreateIngredient', command);
}

export const update = (command: IUpdateIngredientCommand) => {
    return execute('UpdateIngredient', command);
}

export const del = (command: IDeleteIngredientCommand) => {
    return execute('DeleteIngredient', command);
}

export const IngredientService = {
    search,
    getById,
    getSelectItems,
    create,
    update,
    del,
}