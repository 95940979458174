export interface IEditMealPlan {
    id: string,
    name: string,
    numberOfDays: number,
    rows: IEditMealPlanRow[],
    columnLabels: IColumnLabel[],
    archived: boolean,
    macroSummaries: IMacroSummary[],
    shoppingListItems: IShoppingListItem[],
}

export interface IColumnLabel {
    order: number,
    label: string,
}

export interface IEditMealPlanRow {
    id: string,
    name: string,
    index: number,
    excludeNutrition: boolean,
    cells: IEditMealPlanCell[],
}

export interface IEditMealPlanCell {
    id: string,
    mealPlanRowId: string,
    index: number,
    recipes: IEditMealPlanCellRecipe[],
}

export interface IEditMealPlanCellRecipe {
    id: string,
    mealPlanCellId: string,
    recipeId: string,
    servings: number,
    index: number,
    leftover: boolean,
    condiment: boolean,
    recipeDisplay: IRecipeDisplay,
}

export interface IRecipeDisplay {
    id: string,
    name: string,
    imageUri: string,
    energy: number,
    protein: number,
    fat: number,
    carbohydrates: number,
    sugars: number,
}

export interface IMacroSummary {
    order: number,
    energy: number,
    protein: number,
    fat: number,
    carbohydrates: number,
    sugars: number,
}

export interface IEditShoppingList {
    mealPlanId: string,
    items: IShoppingListItem[],
}

export interface IShoppingListItem {
    ingredientId: string,
    name: string,
    recipe: string,
    amount: string,
    category: string,
    optional: boolean,
}

export interface IEditMealPlanExportOptions {
    mealPlanId: string,
    introduction: string,
    imageUri: string,
    recipes: IEditMealPlanExportRecipe[]
}

export interface IEditMealPlanExportRecipe {
    cellRecipeId: string,
    recipeId: string,
    name: string,
    dayOfWeek: string,
    row: string,
    exclude: boolean,
}

export interface IMealPlanSelectOption {
    id: string,
    ownedBy: string,
    name: string,
}

export const EditMealPlanInitialState : IEditMealPlan = {
    id: "",
    name: "",
    numberOfDays: 7,
    rows: [],
    columnLabels: [],
    archived: false,
    macroSummaries: [],
    shoppingListItems: [],
}

export const EditShoppingListInitialState : IEditShoppingList = {
    mealPlanId: '',
    items: [],
}

export const EditMealPlanExportOptionsInitialState : IEditMealPlanExportOptions = {
    mealPlanId: '',
    introduction: '',
    imageUri: '',
    recipes: [],
}