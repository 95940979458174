import { BehaviorSubject } from 'rxjs';
import ServiceConfig from 'services/ServiceConfig';
import jwt_decode from 'jwt-decode';
import { IJwtClaims } from 'common/Interfaces';
import { ISignUpViewModel } from 'pages/Authentication/AuthViewModels';
import { get } from './Middleware';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser') as string));
const currentUsernameSubject = new BehaviorSubject(localStorage.getItem('currentUsername') as string);
const currentRoleSubject = new BehaviorSubject(localStorage.getItem('currentRole') as string);

export const login = async (username: string, password: string) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  const response = await fetch(`${ServiceConfig.apiUrl}/account/token`, requestOptions);
  if (response.status != 200) {
    const error = await response.text();
    return error;
  }

  const text = await response.text();
  const token = JSON.parse(text);
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem('currentUser', JSON.stringify(token));

  var decoded: IJwtClaims = jwt_decode(token.token);
  localStorage.setItem('currentUsername', decoded.unique_name);
  localStorage.setItem('currentRole', decoded.role);

  currentUserSubject.next(token);

  return 'success';
}

export const getUserDetails = (token: string) => {
  const decoded: IJwtClaims = jwt_decode(token);
  return decoded;
}

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  localStorage.removeItem('currentUsername');
  localStorage.removeItem('currentRole');
  currentUserSubject.next(null);
}

export const signUp = (model: ISignUpViewModel) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(model),
  };

  return fetch(ServiceConfig.apiUrl + '/account/signUp', requestOptions)
   .then(handleResponse);
}

export const getPaymentIntent = () => {
  return get('/account/paymentIntent');
}

export function handleResponse(response: Response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    return data;
  });
}

export const AuthService = {
  login,
  logout,
  getUserDetails,
  signUp,
  getPaymentIntent,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value },
  currentUsername: currentUsernameSubject.asObservable(),
  get currentUsernameValue() { return currentUsernameSubject.value },
  currentRole: currentRoleSubject.asObservable(),
  get currentRoleValue() { return currentRoleSubject.value },
};