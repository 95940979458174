import { useState } from 'react';
import { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { PageChangeEvent } from "@progress/kendo-react-data-tools";
import { PageState } from 'common/Interfaces';



export const useListFunctions = () => {

    // Data
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [firstCallMade, setFirstCallMade] = useState(false);

    // Pagination
    const initialDataState: PageState = { skip: 0, take: 10 };
    const [page, setPage] = useState<PageState>(initialDataState);
    const pageChange = (event: PageChangeEvent) => {
        setPage({ skip: event.skip, take: event.take })
    };
    const gridPageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    // Search
    const [searchText, setSearchText] = useState('');
    const searchTextChange = (event: InputChangeEvent) => {
        setSearchText(event.value);
    }

    return {
        rows,
        setRows,
        loading,
        setLoading,
        firstCallMade,
        setFirstCallMade,
        page,
        setPage,
        pageChange,
        gridPageChange,
        searchText,
        setSearchText,
        searchTextChange,
    }
}

