import { get, execute } from 'services/Middleware';
import { ICreateUserCommand, IUpdateUserCommand, IArchiveUserCommand, IRestoreUserCommand, IDeleteUserCommand, IUpdateProfileCommand, IUpdatePasswordCommand, IUpdatePaymentMethodCommand } from 'pages/Users/UserCommands';

/* Queries */

export const search = (keywords: string) => {
    const path = `/users/list?keywords=${keywords.toString()}`;
    return get(path);
}

export const getById = (id: string) => {
    const path = `/users/get?id=${id.toString()}`;
    return get(path);
}

export const getRoleOptions = () => {
    const path = `/users/getRoleOptions`;
    return get(path);
}

export const getProfileForEdit = () => {
    const path = `/users/getProfileForEdit`;
    return get(path);
}

export const getUpdateSetupIntent = () => {
    return get('/users/getUpdateSetupIntent');
}

/* Commands */

export const create = (command: ICreateUserCommand) => {
    return execute('CreateUser', command);
}

export const update = (command: IUpdateUserCommand) => {
    return execute('UpdateUser', command);
}

export const archive = (command: IArchiveUserCommand) => {
    return execute('ArchiveUser', command);
}

export const restore = (command: IRestoreUserCommand) => {
    return execute('RestoreUser', command);
}

export const del = (command: IDeleteUserCommand) => {
    return execute('DeleteUser', command);
}

export const updateProfile = (command: IUpdateProfileCommand) => {
    return execute('UpdateProfile', command);
}

export const updatePassword = (command: IUpdatePasswordCommand) => {
    return execute('UpdatePassword', command);
}

export const updatePaymentMethod = (command: IUpdatePaymentMethodCommand) => {
    return execute('UpdatePaymentMethod', command);
}

export const UserService = {
    search,
    getById,
    getRoleOptions,
    getProfileForEdit,
    getUpdateSetupIntent,
    create,
    update,
    archive,
    restore,
    del,
    updateProfile,
    updatePassword,
    updatePaymentMethod,
}