import { useState, forwardRef, useImperativeHandle } from 'react';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

export interface IAlertState {
    success: boolean;
    error: boolean;
    message: string;
}

export const defaultAlertState: IAlertState = {
    success: false,
    error: false,
    message: '',
}

export const Alert = forwardRef((props, ref) => {
 
    // Functions to be called by parent
    useImperativeHandle(ref, () => ({
        showSuccess(message?: string) {
            if (!message) {
                message = "Changes saved.";
            }
            setAlertState({...alertState, success: true, message: message});
        },
        showError(message?: string) {
            if (!message) {
                message = "Error. Your changes were not saved.";
            }
            setAlertState({...alertState, error: true, message: message});
        }
    }));

    const [alertState, setAlertState] = useState<IAlertState>(defaultAlertState);
    const { success, error, message } = alertState;

    const closeAlert = () => setAlertState({...alertState, success: false, error: false, message: ''});

    return (
    <NotificationGroup style={{right: 0, bottom: 0, alignItems: "flex-start", flexWrap: "wrap-reverse"}}>
        <Fade>
            {success && (
                <Notification type={{ style: "success", icon: true }} closable={true} onClose={closeAlert}>
                    <span>{message}</span>
                </Notification>
            )}
        </Fade>
        <Fade>
            {error && (
                <Notification type={{ style: "error", icon: true }} closable={true} onClose={closeAlert}>
                    <span>{message}</span>
                </Notification>
            )}
        </Fade>
    </NotificationGroup>
    );
});