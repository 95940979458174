import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStripePublicKey } from "hooks/useStripePublicKey";
import { useEffect, useState } from "react";
import { UpdatePaymentResultChild } from "./UpdatePaymentResultChild";

export const UpdatePaymentResult = () => {
    
    const stripePromise = loadStripe(useStripePublicKey());
    const [clientSecret, setClientSecret] = useState('');
    const stripeOptions = { clientSecret: clientSecret }

    useEffect(() => {
        const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');
        setClientSecret(clientSecret);
    }, []);

    return (
        <Elements stripe={stripePromise} options={stripeOptions}>
            <UpdatePaymentResultChild clientSecret={clientSecret}/>
        </Elements>
    );
}