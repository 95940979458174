import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Alert } from 'reactstrap';
import { Button } from '@progress/kendo-react-buttons';
import { AuthService } from 'services/AuthService';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input } from "@progress/kendo-react-inputs";

export const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [lastError, setLastError] = useState('');
    const [loading, setLoading] = useState(false);
  
    const submit = async (e: SyntheticEvent) => {
      e.preventDefault();
      setLoading(true);
      const response = await AuthService.login(username, password);
      if (response == 'success'){
        handleSuccess();
      } else {
        handleFailure(response);
      }
      setLoading(false);
    }

    function handleSuccess(){
        setLastError('');
        navigate('/Overview');
    }

    function handleFailure(error: string){
        setLastError(error);
    }
    
    useEffect(() => {
        if (!!AuthService.currentUserValue){
            handleSuccess();
        }
    }, []);

    const SignInButton = () => {
      if (loading) {
        return <Button type={"submit"} disabled={true}><span className='loader inline mr-1'></span> Signing you in...</Button>
      } else {
        return <Button type={"submit"} disabled={!username || !password}>Sign In</Button>
      }
    }

    return (
        <>
        <div className="row">
          <div className='col col-lg-6 offset-lg-3 col-md-12 offset-md-0'>
            
            <div className='sign-up-tile'>
              <h1>Existing User</h1>
              <form onSubmit={submit}>
              <FieldWrapper>
                <Input name='username' label='Username' onChange={e => setUsername(e.target.value.toString())} />
              </FieldWrapper>
              <FieldWrapper>
                <Input name='password' label='Password' type='password' onChange={e => setPassword(e.target.value.toString())} />
              </FieldWrapper>
              <hr />
              <SignInButton />
              <Alert isOpen={!!lastError} color="danger">{lastError}</Alert>
            </form>
            </div>
          </div>
        </div>
        </>
        );
}