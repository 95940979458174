import { mdiImage } from "@mdi/js";
import Icon from "@mdi/react";
import { ListViewItemProps } from "@progress/kendo-react-listview";
import { AddToMealPlanButton } from "pages/MealPlans/AddToMealPlanButton";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, CardImg } from "reactstrap";
import { IRecipeListItem } from "./RecipeInterfaces";

export const RecipeListItemImage = (props: { item: IRecipeListItem }) => {

    // Navigation
    const navigate = useNavigate();
    const viewRecipe = (id: string) => navigate('/Recipes/View/' + id);

    if (props.item.imageUri == null) {
        return (
            <CardBody className='card-img-placeholder' onClick={(e) => viewRecipe(props.item.id)}>
                <Icon path={mdiImage} />
            </CardBody>
        );
    }

    return <CardImg width={'100%'} src={props.item.imageUri} onClick={(e) => viewRecipe(props.item.id)} />;
}

export const RecipeListItem = (props: ListViewItemProps) => {

    // Navigation
    const navigate = useNavigate();
    const viewRecipe = (id: string) => navigate('/Recipes/View/' + id);

    let item: IRecipeListItem = props.dataItem;

    return (
        <Card className='recipe-card'>
            <CardHeader onClick={(e) => viewRecipe(props.dataItem.id)}>
                {item.name}{item.isArchived && <span>&nbsp;(Archived)</span>}
            </CardHeader>
            <RecipeListItemImage item={item} />
            <CardFooter>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <small className='text-muted'>
                        {item.time} minutes
                    </small>
                </div>
                <div className='col-md-4 text-center'>
                    <small className='text-muted'>
                        {item.servings} servings
                    </small>
                </div>
                <div className='col-md-4'>
                    <AddToMealPlanButton recipeId={item.id} smallButton={true}/>
                </div>
            </div>
            </CardFooter>
        </Card>
    );
}