import { Field, FieldArray, FieldArrayRenderProps, FieldWrapper, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { MealPlanService } from "./MealPlanService";
import { Alert } from 'components/Alert';
import Icon from "@mdi/react";
import { mdiArchive, mdiArrowLeft, mdiCheck, mdiCheckboxBlankOutline, mdiClose, mdiCross, mdiDelete, mdiFilePdfBox, mdiPlus, mdiRestore } from "@mdi/js";
import { IArchiveMealPlanCommand, IDeleteMealPlanCommand, IRestoreMealPlanCommand, IUpdateShoppingListCommand } from "./MealPlanCommands";
import { IApiResponse, IButtonGroupRenderProps, IUserAuth } from "common/Interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { createContext, useEffect, useRef, useState } from "react";
import { EditShoppingListInitialState, IEditShoppingList } from "./MealPlanInterfaces";

export const FormGridContext = createContext<{
    parentField: string;
  }>({} as any);

export const ShoppingList = (props: { auth: IUserAuth }) => {

    // Setup View
    const { id } = useParams();
    const nav = useNavigate();
    const [view, setView] = useState<IEditShoppingList>(EditShoppingListInitialState);

    // Alerts
    const alertRef = useRef(null);

    // Load Data
    useEffect(() => {
        if (!!id){
            MealPlanService.getShoppingList(id).then(result => {
                setView(result);
            });
        }
    }, []);

    // Actions
    const saveChanges = (formData: IUpdateShoppingListCommand) => {
        MealPlanService.updateShoppingList(formData).then(result => handleResponseAndReload(result));
    }

    const closeItem = () => {
        nav('/MealPlans/Edit/' + id);
    }

    const handleResponseAndReload = (data: IApiResponse, successMessage?: string) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }

        alertRef.current.showSuccess(successMessage);
        MealPlanService.getShoppingList(id).then(result => setView(result));
    };

    const CheckboxCell = (props: GridCellProps) => {
      
        return (
          <td className={'text-center'}>
            <Field
                component={Checkbox}
                name={`items[${props.dataIndex}].${props.field}`}
                size={'large'}
            />
          </td>
        );
      };

    const IngredientItemCell = (props: GridCellProps) => {

        const i = props.dataItem;

        return (
            <td>{i.amountDisplay} {i.name} {i.optional && <i>- Optional</i>}</td>
        )
    }

    const ShoppingListGrid = (props: FieldArrayRenderProps) => {
        const { validationMessage, visited, name, formRenderProps } = props;

        return (
            <>
                <FormGridContext.Provider value={{ parentField: name }}>
                    <Grid data={props.value} scrollable={'none'}>
                        <GridColumn field='category' title='Category' />
                        <GridColumn field='name' title='Item' cell={IngredientItemCell} />
                        <GridColumn field='recipe' title='Recipe' />
                        <GridColumn field='excludeFromShoppingList' title='Exclude' cell={CheckboxCell} width={80} />
                    </Grid>
                </FormGridContext.Provider>
            </>
        )
    }

    const Buttons = (buttonRenderProps: IButtonGroupRenderProps) => {
        return (
            <ButtonGroup>
                <Button type={"submit"} disabled={!buttonRenderProps.allowSubmit}><Icon path={mdiCheck} />Save Changes</Button>
                <Button type={"button"} onClick={closeItem}><Icon path={mdiArrowLeft} />Back to Meal Plan</Button>
            </ButtonGroup>
        );
    }

    return (
        <div>
            <div className='form-container'>
            <Form initialValues={view}
                    key={JSON.stringify(view)}
                    onSubmit={saveChanges}
                    render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h1>Shopping List</h1>
                            </div>
                            <div className='col-md-6 text-right'>
                                <Buttons allowSubmit={formRenderProps.allowSubmit} />
                            </div>
                        </div>
                        {view.mealPlanId && (
                            <FieldWrapper>
                            <FieldArray name={"items"} component={ShoppingListGrid} key={"id"} />
                        </FieldWrapper>
                        )}
                    </FormElement>
                )} />
            <Alert ref={alertRef} />
            </div>
        </div>
    );
}