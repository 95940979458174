import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Dropdown, Label } from 'reactstrap';
import { Button } from '@progress/kendo-react-buttons';
import { AuthService } from 'services/AuthService';
import { Field, FieldRenderProps, FieldWrapper, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Error } from "@progress/kendo-react-labels";
import { Input, RadioGroup } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import ServiceConfig from 'services/ServiceConfig';
import { FormValidation } from 'services/FormValidation';
import { ISignUpViewModel, SignUpViewModelInitialState } from './AuthViewModels';
import { get } from 'services/Middleware';

export const SignUpForm = () => {

    const [loading, setLoading] = useState(false);
    const [lastError, setLastError] = useState('');

    const [view, setView] = useState<ISignUpViewModel>(SignUpViewModelInitialState);

    const modalityOptions = [
      { label: 'Nutritionist', value: 'Nutritionist' },
      { label: 'Naturopath', value: 'Naturopath' },
      { label: 'Dietician', value: 'Dietician' },
      { label: 'General Practitioner', value: 'General Practitioner' },
      { label: 'Integrative GP', value: 'Integrative GP' },
      { label: 'Allied Health Professional', value: 'Allied Health Professional' },
      { label: 'Health Coach', value: 'Health Coach' },
      { label: 'Personal Trainer', value: 'Personal Trainer' },
      { label: 'Other', value: 'Other' },
    ];

    // Stripe
    const stripe = useStripe();
    const elements = useElements();

    const [stripeError, setStripeError] = useState('');

    const handleSubmit = async (formData: ISignUpViewModel) => {
      
        if (await isUserNameTaken(formData.userName)) {
          setLastError(`Username \'${formData.userName}\' has already been taken.`);
          return;
        }

        if (await isEmailTaken(formData.email)){
          setLastError(`Email \'${formData.email}\' is already registered to another user.`);
          return;
        }

        let returnUrl = window.location.origin;
        returnUrl += `/PaymentStatus`;
        returnUrl += (`?userName=${formData.userName}`);
        returnUrl += (`&email=${formData.email}`);
        returnUrl += (`&firstName=${formData.firstName}`);
        returnUrl += (`&lastName=${formData.lastName}`);
        returnUrl += (`&companyName=${formData.companyName}`);
        returnUrl += (`&phoneNumber=${formData.phoneNumber}`);
        returnUrl += (`&modality=${formData.modality}`);
        returnUrl += (`&modalityOther=${formData.modalityOther}`);
        
        if (!stripe || !elements) {
          return;
        }

        const {error} = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: returnUrl,
          },
        });
  
        if (error) {
          setStripeError(error.message);
        }
    }

    const isUserNameTaken = (userName: string) : Promise<boolean> => {
      return get('/account/userNameExists?userName=' + userName)
        .then(exists => {
          return exists;
        });
    }
    const isEmailTaken = (email: string) : Promise<boolean> => {
      return get('/account/emailExists?email=' + email)
        .then(exists => {
          return exists;
        });
    }

    const InputWithValidation = (fieldRenderProps: FieldRenderProps) => {
      const { validationMessage, visited, ...others } = fieldRenderProps;
      return (
        <div>
          <Input {...others} />
          {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
      );
    }

    const RegisterButton = () => {
        if (loading) {
            return <Button type={"submit"} disabled={true}><span className='loader inline mr-1'></span> Signing you up...</Button>
        } else {
            return <Button type={"submit"}>Create my Account</Button>
        }
    }
    
      return (
        <>
          <Form initialValues={view}
          key={JSON.stringify(view)}
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement className='mb-5'>
              <FieldWrapper>
                <Field name={'userName'} component={InputWithValidation} label='Username' validator={FormValidation.validUserName} />
              </FieldWrapper>
              <FieldWrapper>
                <Field name={'email'} component={InputWithValidation} label='Email' validator={FormValidation.validEmail} />
              </FieldWrapper>
              <FieldWrapper>
                <Field name={'firstName'} component={InputWithValidation} label='First Name' validator={FormValidation.notEmpty} />
              </FieldWrapper>
              <FieldWrapper>
                <Field name={'lastName'} component={InputWithValidation} label='Last Name' validator={FormValidation.notEmpty} />
              </FieldWrapper>
              <FieldWrapper>
                <Field name={'companyName'} component={InputWithValidation} label='Company' />
              </FieldWrapper>
              <FieldWrapper>
                <Field name={'phoneNumber'} component={InputWithValidation} label='Phone Number' validator={FormValidation.validPhoneNumber} />
              </FieldWrapper>
              <hr />
              <FieldWrapper className='mt-5'>
                <Label>What best describes your role?</Label>
                <Field name={'modality'} component={RadioGroup} layout={'horizontal'} data={modalityOptions} />
              </FieldWrapper>
              <FieldWrapper>
                <Field name={'modalityOther'} component={Input} hidden={!(formRenderProps.valueGetter('modality') == 'Other')} />
              </FieldWrapper>
              <hr />
              <h3 className='mt-5'>Payment Details</h3>
              <p>You will be making a payment of AU$39 monthly for this subscription.</p>
              <PaymentElement className='mt-3' />
              <RegisterButton />
              <Alert isOpen={!!lastError} color="danger">{lastError}</Alert>
              <Alert isOpen={!!stripeError} color="danger">{stripeError}</Alert>
            </FormElement>
          )} />
        </>
      )
}