import { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from "@progress/kendo-react-buttons";
import Icon from "@mdi/react";
import { mdiCheck, mdiCancel } from "@mdi/js";

interface IModalOkCancelProps {
    title: string;
    description: string;
    okText: string;
    cancelText: string;
    onOkClick: () => void;
}

export const ModalOkCancel = forwardRef((props: IModalOkCancelProps, ref) => {

    const [visible, setVisible] = useState(false);

    const toggle = () => setVisible(!visible);

    // Functions to be called by parent
    useImperativeHandle(ref, () => ({
      showModal() {
        setVisible(true);
      },
      hideModal() {
        setVisible(false);
      }
    }));

    const ok = props.okText || 'Ok';
    const cancel = props.cancelText || 'Cancel';
    
    return (
        <Modal isOpen={visible} toggle={toggle}>
          <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
          <ModalBody>{props.description}</ModalBody>
          <ModalFooter>
            <Button onClick={props.onOkClick}><Icon path={mdiCheck} />{ok}</Button>
            <Button onClick={toggle}><Icon path={mdiCancel} />{cancel}</Button>
          </ModalFooter>
        </Modal>
    );
});