import { Alert } from "reactstrap";

export const SubscriptionDetailsBanner = (props: { expiry: string }) => {

    if (!props.expiry) return <></>;

    const expiry = new Date(props.expiry);
    const expiryString = expiry.toLocaleDateString();
    const showDanger = expiry < new Date();

    const hidden = !props.expiry;
    const color = showDanger ? 'danger' : 'success';

    const message = showDanger
        ? `Your subscription has expired on ${expiryString}. Please update your payment details.`
        : `Your subscription is valid till ${expiryString}, at which point it will auto-renew.`;

    return (
        <Alert hidden={hidden} color={color}>{message}</Alert>
    );
}