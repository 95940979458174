import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FileSaver from 'file-saver';
import _ from "lodash";
import { Field, FieldArray, FieldArrayProps, FieldArrayRenderProps, FieldWrapper, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { EditMealPlanInitialState, IEditMealPlan, IEditMealPlanCell, IMacroSummary, IShoppingListItem } from "./MealPlanInterfaces";
import { MealPlanService } from "./MealPlanService";
import { FormValidation } from "services/FormValidation";
import { Alert } from 'components/Alert';
import { useListFunctions } from "hooks/useListFunctions";
import { Card, CardBody, CardFooter, CardHeader, Dropdown, Label, Table } from "reactstrap";
import { IApiResponse, IButtonGroupRenderProps, IUserAuth } from "common/Interfaces";
import Icon from "@mdi/react";
import { mdiArchive, mdiCart, mdiCheck, mdiCheckboxBlankOutline, mdiClose, mdiContentCopy, mdiCross, mdiDelete, mdiFilePdfBox, mdiPlus, mdiRestore } from "@mdi/js";
import { IArchiveMealPlanCommand, IDeleteMealPlanCommand, IRestoreMealPlanCommand } from "./MealPlanCommands";
import { Popup } from "@progress/kendo-react-popup";
import { AddToMealPlanButton } from "./AddToMealPlanButton";
import { ModalOkCancel } from "components/ModalOkCancel";
import { Spinner } from "components/Spinner";

export const FormGridContext = createContext<{
    onReplace: (dataItem: any, dataIndex: number) => void;
    parentField: string;
  }>({} as any);

export const EditMealPlan = (props: { auth: IUserAuth }) => {

    const formRef = useRef(null);

    const list = useListFunctions();

    // Setup View
    const { id } = useParams();
    const nav = useNavigate();
    const [view, setView] = useState<IEditMealPlan>(EditMealPlanInitialState);
    const [columns, setColumns] = useState<number>(7);
    const [loading, setLoading] = useState(false);

    const numberOfDaysOptions = [7, 14];

    const [week, setWeek] = useState(1);
    const showWeek1 = week == 1;
    const showWeek2 = week == 2;
    
    // Alerts
    const alertRef = useRef(null);

    // Delete Modal
    const deleteModalRef = useRef(null);

    // Load Data
    useEffect(() => {
        if (!!id){
            setLoading(true);
            MealPlanService.getById(id).then(result => {
                setView(result);
            }).finally(() => setLoading(false));
        }
    }, []);

    // Actions
    const saveChanges = (formData: IEditMealPlan) => {
        
        formData = prepareForSave(formData);

        if (!view.id) {
            MealPlanService.create(formData).then(result => handleResponseAndClose(result));
        } else {
            MealPlanService.update(formData).then(result => handleResponseAndReload(result));
        }
    }

    const exportPdf = () => {
        MealPlanService.exportPdf(id).then(result => {
            FileSaver.saveAs(result, 'MealPlan.pdf');
        });
    }

    const openShoppingList = () => {
        nav('/MealPlans/ShoppingList/' + view.id);
    }

    const openExportOptions = () => {
        nav('/MealPlans/Export/' + view.id);
    }

    const archiveItem = () => {
        let command: IArchiveMealPlanCommand = { id: view.id };
        MealPlanService.archive(command).then(result => handleResponseAndReload(result, 'Meal plan archived.'));
    }

    const restoreItem = () => {
        let command: IRestoreMealPlanCommand = { id: view.id };
        MealPlanService.restore(command).then(result => handleResponseAndReload(result, 'Meal plan restored.'));
    }

    const showDeleteModal = () => {
        deleteModalRef.current.showModal();
    }

    const confirmDeleteItem = () => {
        let command: IDeleteMealPlanCommand = { id: view.id };
        MealPlanService.del(command).then(result => handleResponseAndClose(result));
    }

    const handleResponseAndReload = (data: IApiResponse, successMessage?: string) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }

        alertRef.current.showSuccess(successMessage);
        MealPlanService.getById(view.id).then(result => setView(result));
    };

    const handleResponseAndClose = (data: IApiResponse) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }
        closeItem();
    };

    const closeItem = () => {
        nav('/MealPlans/List');
    };

    // Adjust the model before saving
    const prepareForSave = (formData: IEditMealPlan) => {

        formData.rows.forEach((i, index) => {
            i.index = index;
        });

        return formData;
    }

    const title = (!!id)
        ? (view.archived) ? `${view.name} (Archived)` : `${view.name}`
        : 'Create Meal Plan';

    const saveLabel = (!!view.id) ? 'Save Changes' : 'Create';

    const RowDetailCell = (props: GridCellProps) => {
        return (
            <td className='mealplan-row-details'>
                <Field
                    component={Input}
                    name={`rows[${props.dataIndex}].${props.field}`}
                />
            </td>
        );
    };

    const GridRowCommandsCell = (onRemove) => (props: GridCellProps) => {

        const deleteRow = () => {
            onRemove(props);
        }
    
        return (
            <td className='mealplan-commands-cell'>
                <div className='mealplan-commands'>
                    <Button type={"button"} onClick={deleteRow} size='small'><Icon path={mdiDelete}/> Delete Row</Button>
                </div>
            </td>
        );
    }

    
    // Recipe Display
    const RecipeAddedToCellDisplay = (props: {
        gridCellProps: GridCellProps,
        rowIndex: number,
        cellIndex: number,
        recipeIndex: number,
    }) => {
    
        const { gridCellProps, rowIndex, cellIndex, recipeIndex } = props;

        const { onReplace } = useContext(FormGridContext);
    
        const anchor = useRef<any>();
        const [popupVisible, setPopupVisible] = useState(false);
        const togglePopup = () => setPopupVisible(!popupVisible);
    
        let recipe = props.gridCellProps.dataItem.cells[cellIndex].recipes[recipeIndex].recipeDisplay;
        let isLeftover = props.gridCellProps.dataItem.cells[cellIndex].recipes[recipeIndex].leftover;

        // Close pop up on click outside
        const contentRef = useRef<any>();
        const timeoutRef = useRef<any>();

        const onOpen = () => contentRef.current.focus();
        const onFocus = () => clearTimeout(timeoutRef.current);
        const onBlurTimeout = () => setPopupVisible(false);

        const onBlur = () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(onBlurTimeout, 200);
        }

        // Close button
        const onCloseClick = () => {
            setPopupVisible(false);
        }

        const onCopyComplete = () => {
            MealPlanService.getById(id).then(result => {
                setView(result);
            });
        }

        const onDeleteClick = useCallback((e) => {
            e.preventDefault();
            setPopupVisible(false);

            // Change the row item
            let change = gridCellProps.dataItem;
            change.cells[cellIndex].recipes.splice(recipeIndex, 1);

            // Replace existing row with new one
            onReplace(change, gridCellProps.dataIndex);

        }, [gridCellProps.dataItem, onReplace]);

        if (!recipe) {
            return (
                <></>
            );
        }

        return (
            <>
                <div className={`recipe-button ${isLeftover ? 'leftover' : ''}`} onClick={togglePopup} ref={anchor} title={recipe.name}
                    style={{backgroundImage : `url(${recipe.imageUri})`}}>
                    <div className='recipe-label'>{recipe.name}</div>
                </div>
                <Popup anchor={anchor.current} show={popupVisible} onOpen={onOpen}
                    className={'recipe-popup-wrapper'} popupClass={'recipe-popup'}>
                    <div ref={contentRef} tabIndex={0} onFocus={onFocus} onBlur={onBlur}>
                    <Card className={'recipe-popup-content'}>
                        <CardHeader>
                            {recipe.name}
                            <Button className='close-top-left' onClick={onCloseClick}><Icon path={mdiClose}/></Button>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Field
                                        component={NumericTextBox} width={60}
                                        name={`rows[${rowIndex}].cells[${cellIndex}].recipes[${recipeIndex}].servings`}
                                        min={1} max={5} size={'small'}
                                    />
                                    <span className='servings-label'>Servings</span>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-md-12'>
                                    <Field
                                        component={Checkbox}
                                        name={`rows[${rowIndex}].cells[${cellIndex}].recipes[${recipeIndex}].leftover`}
                                        label='Select as Leftover'
                                    />
                                </div>
                                <div className='col-md-12'>
                                    <Field
                                        component={Checkbox}
                                        name={`rows[${rowIndex}].cells[${cellIndex}].recipes[${recipeIndex}].condiment`}
                                        label='Exclude from Nutrition'
                                    /> 
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <ButtonGroup>
                                <AddToMealPlanButton mealPlanId={view.id} recipeId={recipe.id} buttonIcon={mdiContentCopy} buttonLabel='Copy To'
                                smallButton={true} onCopyComplete={onCopyComplete} disabled={!_.isEmpty(formRef.current.modified)}/>
                                <Button onClick={onDeleteClick} size='small'><Icon path={mdiDelete}/>Delete</Button>
                            </ButtonGroup>
                        </CardFooter>
                    </Card>
                    </div>
                </Popup>
            </>
        )
    }

    // Meal Plan Cell
    const MealPlanCell = (props: {
        gridCellProps: GridCellProps,
        dataItem: any,
        rowIndex: number,
        cellIndex: number,
    }) => {

        const hiddenCell = (props.cellIndex >= 7 && showWeek1) || (props.cellIndex < 7 && showWeek2);

        const cell: IEditMealPlanCell = props.gridCellProps.dataItem.cells[props.cellIndex];
        const orderedCellRecipes = _.sortBy(cell.recipes, cr => cr.index);

        return (
            <div className="mealplan-cell" hidden={hiddenCell}>
                <div>
                    {orderedCellRecipes.map((cellRecipe, index) => <RecipeAddedToCellDisplay
                        gridCellProps={props.gridCellProps}
                        rowIndex={props.rowIndex}
                        cellIndex={props.cellIndex}
                        recipeIndex={index}
                        key={index}
                        />)}
                </div>
            </div>
        );
    }

    // Meal Plan Cells Container
    const MealPlanCellsArrayGridCell = (props: GridCellProps) => {

        const row = props.dataItem;

        // If row saved, show cells
        if (!!props.dataItem.id){
            return (
                <td className="mealplan-cell-container">
                    <div>
                        {row.cells.map((cell, index) => <MealPlanCell
                            gridCellProps={props}
                            dataItem={row}
                            rowIndex={props.dataIndex}
                            cellIndex = {index}
                            key={index} />)}
                    </div>
                </td>
            );
        }
        // If not saved, show a message
        else {
            return (
                <td className="mealplan-cell-container">
                    <div className="mealplan-row-uncreated">
                        Save changes to add meals to this row.
                    </div>
                </td>
            );
        }
    }

    const MealPlanGrid = (props: FieldArrayRenderProps) => {
        const { validationMessage, visited, name, formRenderProps } = props;

        const generateNewRow = () => {
            return {
                id: "",
                name: "",
                index: 0,
                excludeNutrition: false,
                cells: [
                    { id: "", mealPlanRowId: "", index: 0, recipes: [] },
                    { id: "", mealPlanRowId: "", index: 1, recipes: [] },
                    { id: "", mealPlanRowId: "", index: 2, recipes: [] },
                    { id: "", mealPlanRowId: "", index: 3, recipes: [] },
                    { id: "", mealPlanRowId: "", index: 4, recipes: [] },
                    { id: "", mealPlanRowId: "", index: 5, recipes: [] },
                    { id: "", mealPlanRowId: "", index: 6, recipes: [] },
                ],
            }
        }
        
        // Add row at bottom
        const onAdd = useCallback(e => {
            e.preventDefault();
            props.onPush({ value: generateNewRow() });
        }, [props.onPush]);

        // Remove row
        const onRemove = useCallback(e => {
            props.onRemove({ index: e.dataIndex });
        }, [props.onRemove]);

        // Update a row in the grid
        const onReplace = useCallback((dataItem, dataIndex) => {
            props.onReplace({ value: dataItem, index: dataIndex});
        }, [props.onReplace]);

        const hideAddbutton = !!view && !!view.rows && view.rows.length >= 7;

        return (
            <>
                <FormGridContext.Provider value={{ onReplace, parentField: name }}>
                <Grid data={props.value} scrollable={'none'}>
                    <GridColumn field='name' title='Meal' cell={RowDetailCell} width={150}/>
                    <GridColumn cell={GridRowCommandsCell(onRemove)} width={0} />
                    <GridColumn field='cells' title='Cells' cell={MealPlanCellsArrayGridCell}/>
                </Grid>
                </FormGridContext.Provider>
                <Button className='w-100 mt-2' onClick={onAdd} hidden={hideAddbutton}><Icon path={mdiPlus} />Add Row</Button>
            </>
        );
    }

    const NutritionTable = () => {
        return (
            <div className='col-md-12 macro-summaries-container'>
                <div className='macro-summaries'>
                    <WeekButtons />
                    {view.macroSummaries.map((summary: IMacroSummary, index) => (
                        <div className='day' hidden={(showWeek1 && index >= 7) || (showWeek2 && index < 7)} key={summary.order}>
                            <div className='row'>
                                <div className='col-md-5'>Energy</div>
                                <div className='col-md-7 text-right'>{summary.energy} cal</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-5'>Protein</div>
                                <div className='col-md-7 text-right'>{summary.protein} g</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-5'>Fat</div>
                                <div className='col-md-7 text-right'>{summary.fat} g</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-5'>Sugars</div>
                                <div className='col-md-7 text-right'>{summary.sugars} g</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-5'>Carbs</div>
                                <div className='col-md-7 text-right'>{summary.carbohydrates} g</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    const DayOfWeekLabels = (props: FieldArrayRenderProps) => {

        const { value } = props;

        return (
            <div className='col-md-12'>
                <div className='day-of-week-container'>
                    {value.map((label, index) => 
                        <Field hidden={(showWeek1 && index >= 7) || (showWeek2 && index < 7)}
                            component={Input}
                            name={`columnLabels[${index}].label`}
                            key={`columnLabels[${index}].order`}
                        />
                    )}
                </div>
            </div>
        )
    }

    const ShoppingList = () => {

        const categories = _.map(_.groupBy(view.shoppingListItems, li => li.category), li => li);

        const Category = (categoryProps: { items: IShoppingListItem[] }) => {
            const heading = categoryProps.items[0].category;
            const showHeading = !!heading;

            return (
                <>
                    {showHeading && <tr><td colSpan={3} className='ingredient-category'>{heading}</td></tr>}
                    {categoryProps.items.map((i) => <tr>
                        <td className='step'><Icon path={mdiCheckboxBlankOutline} /></td>
                        <td className='ingredient'>{i.amount} {i.name} {i.optional && <i>- Optional</i>}</td>
                        <td className='from-recipe'>{i.recipe}</td>
                    </tr>)}
                </>
            )
        }

        return (
            <Card>
                <CardHeader>Shopping List</CardHeader>
                <CardBody>
                    <table className='ingredients-table'>
                        {categories.map((c) => <Category items={c} />)}
                    </table>
                </CardBody>
            </Card>
            
        );
    }

    const WeekButtons = () => {
        return (
            <div className='week-buttons'>
                <div hidden={view.numberOfDays <= 7}>
                    <ButtonGroup>
                        <Button type={"button"} onClick={() => setWeek(1)} disabled={week == 1}>Week 1</Button>
                        <Button type={"button"} onClick={() => setWeek(2)} disabled={week == 2}>Week 2</Button>
                    </ButtonGroup>
                </div>
            </div>
        )
    }

    const Buttons = (buttonRenderProps: IButtonGroupRenderProps) => {
        if (view.archived) {
            return (
                <ButtonGroup>
                    <Button type={"button"} onClick={restoreItem} hidden={!view.id}><Icon path={mdiRestore} />Restore</Button>
                    <Button type={"button"} onClick={closeItem}><Icon path={mdiClose} />Close</Button>
                </ButtonGroup>
            );
        } else {
            return (
                <ButtonGroup>
                    <Button type={"submit"} disabled={!buttonRenderProps.allowSubmit} hidden={!!view.id}><Icon path={mdiCheck} />{saveLabel}</Button>
                    <Button type={"button"} onClick={openExportOptions} hidden={!view.id}><Icon path={mdiFilePdfBox} />Export</Button>
                    <Button type={"button"} onClick={openShoppingList} hidden={!view.id}><Icon path={mdiCart} />Shopping List</Button>
                    {/* <Button type={"button"} onClick={archiveItem} hidden={!view.id}><Icon path={mdiArchive} />Archive</Button> */}
                    <Button type={"button"} onClick={showDeleteModal} hidden={!view.id}><Icon path={mdiDelete} />Delete</Button>
                    <Button type={"button"} onClick={closeItem}><Icon path={mdiClose} />Close</Button>
                </ButtonGroup>
            );
        }
    }

    const AutoSaver = (formRenderProps) => {
        const isSubmittedRef = useRef(false);

        useEffect(() => {
            const {valid, touched, onSubmit} = formRenderProps;

            if (!!view.id && valid && touched && !isSubmittedRef.current){
                isSubmittedRef.current = true;
                onSubmit();
            }
        });
        return (
            <></>
        );
    }

    return (
        <div>
            <div className="form-container mealplan-container">
            <Form initialValues={view}  ref={formRef}
                    key={JSON.stringify(view)}
                    onSubmit={saveChanges}
                    render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                        <AutoSaver {...formRenderProps} />
                        <div className='row'>
                            <div className='col-md-6'>
                                <h1>{title}</h1>
                            </div>
                            <div className='col-md-6 text-right'>
                                <Buttons allowSubmit={formRenderProps.allowSubmit} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                            <FieldWrapper>
                                <Field name={"name"} component={Input} label={"Profile Name"} validator={FormValidation.notEmpty} />
                            </FieldWrapper>
                            {!id && 
                            <FieldWrapper>
                                <Field name={"numberOfDays"} component={DropDownList} data={numberOfDaysOptions} disabled={!!view.id} label={"Number of Days"} validator={FormValidation.numberGreaterThanZero} />
                            </FieldWrapper>
                            }
                            </div>
                        </div>
                        {view.id && (
                            <>
                            <div className='row'>
                                <FieldArray name={'columnLabels'} component={DayOfWeekLabels} key={'order'} />
                            </div>
                            <div className='row'>
                                <NutritionTable />
                            </div>
                                <FieldWrapper>
                                <FieldArray name={"rows"} component={MealPlanGrid} key={"id"} />
                            </FieldWrapper>
                            </>
                        )}
                    </FormElement>
                )} />
            <Alert ref={alertRef} />

            <ModalOkCancel ref={deleteModalRef}
                title={'Delete Meal Plan'}
                description={'Are you sure you want to delete this meal plan?'}
                okText={'Confirm Delete'} cancelText={'Cancel'}
                onOkClick={confirmDeleteItem}/>

            </div>
            <Spinner loading={loading} />
        </div>
    );
}