import { IApiResponse, IUserAuth } from 'common/Interfaces';
import { EditPasswordInitialState, EditProfileInitialState, IEditPassword, IEditProfile } from 'pages/Users/UserInterfaces';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserService } from 'services/UserService';
import { Alert } from 'components/Alert';
import { Input } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Form, Field, FieldWrapper, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { FormValidation } from 'services/FormValidation';
import { mdiCheck, mdiClose } from '@mdi/js'; 
import { Icon } from '@mdi/react';

export const EditPassword = (props: { auth: IUserAuth }) => {

    // Setup View
    const nav = useNavigate();
    const [view, setView] = useState<IEditPassword>(EditPasswordInitialState);

    // Alerts
    const alertRef = useRef(null);

    // Actions
    const saveChanges = (formData: IEditPassword) => {
        UserService.updatePassword(formData).then(result => handleResponseAndReload(result));
    }

    const handleResponseAndReload = (data: IApiResponse, successMessage?: string) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }

        alertRef.current.showSuccess(successMessage);
    };

    const closeItem = () => {
        nav(-1);
    };

    const Buttons = () => {
        return (
            <ButtonGroup>
                <Button type={"submit"}><Icon path={mdiCheck} />Change Password</Button>
                <Button type={"button"} onClick={closeItem}><Icon path={mdiClose} />Close</Button>
            </ButtonGroup>
        )
    }

    return (
        <div>
            <div className="form-container">
            <Form initialValues={view}
                    key={JSON.stringify(view)}
                    onSubmit={saveChanges}
                    render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h1>Change Password</h1>
                            </div>
                            <div className='col-md-6 text-right'>
                                <Buttons />
                            </div>
                        </div>
                        <FieldWrapper>
                            <Field name={"oldPassword"} component={Input} label={"Old Password"} type={'password'} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"newPassword"} component={Input} label={"New Password"} type={'password'} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"confirmPassword"} component={Input} label={"Confirm Password"} type={'password'} />
                        </FieldWrapper>
                    </FormElement>
                )} />
                <Alert ref={alertRef} />
            </div>
        </div>
    );
}