import { IApiResponse, IUserAuth } from 'common/Interfaces';
import { IIngredient, IngredientInitialState } from 'pages/Ingredients/IngredientInterfaces';
import { IDeleteIngredientCommand } from 'pages/Ingredients/IngredientCommands';
import { Alert } from 'components/Alert';
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Form, Field, FieldWrapper, FormElement, FieldRenderProps, FormRenderProps, } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IngredientService } from 'services/IngredientService';
import { FormValidation } from 'services/FormValidation';
import { mdiCheck, mdiDelete, mdiClose } from '@mdi/js'; 
import { Icon } from '@mdi/react';
import { ModalOkCancel } from 'components/ModalOkCancel';

interface IMetricField {
    name: string,
    label: string,
}

const metrics: IMetricField[] = [
    { name: 'volumeToWeightRatio', label: 'Volume to Weight Ratio (g/cup)'},
    { name: 'energy', label: 'Energy (kJ)'},
    { name: 'protein', label: 'Protein (g)'},
    { name: 'fat', label: 'Fat (g)'},
    { name: 'sugars', label: 'Sugars (g)'},
    { name: 'carbohydrates', label: 'Carbohydrates (g)'},
    { name: 'calcium', label: 'Calcium'},
    { name: 'copper', label: 'Copper' },
    { name: 'iodine', label: 'Iodine' },
    { name: 'iron', label: 'Iron' },
    { name: 'magnesium', label: 'Magnesium' },
    { name: 'potassium', label: 'Potassium' },
    { name: 'selenium', label: 'Selenium' },
    { name: 'sodium', label: 'Sodium' },
    { name: 'zinc', label: 'Zinc' },
    { name: 'retinol', label: 'Retinol' },
    { name: 'thiamin', label: 'Thiamin' },
    { name: 'riboflavin', label: 'Riboflavin' },
    { name: 'niacin', label: 'Niacin' },
    { name: 'pantothenicAcid', label: 'Patothenic Acid' },
    { name: 'pyridoxine', label: 'Pyridoxine' },
    { name: 'biotin', label: 'Biotin' },
    { name: 'cobalamin', label: 'Cobalimin' },
    { name: 'folateNatural', label: 'Folate Natural' },
    { name: 'vitaminC', label: 'Vitamin C' },
    { name: 'cholecalciferol', label: 'Cholecalciferol' },
    { name: 'vitaminE', label: 'Vitamin E' },
    { name: 'fattyAcidsTotalSaturated', label: 'Fatty Acids - Total Saturated' },
    { name: 'fattyAcidsTotalMonounsaturated', label: 'Fatty Acids - Total Monounsaturated' },
    { name: 'fattyAcidsTotalPolyunsaturated', label: 'Fatty Acids - Total Polyunsaturated' },
];

const categories = [
    'Fruits',
    'Vegetables',
    'Bread, dairy, and eggs',
    'Fish, poultry, and meat',
    'Boxed and canned',
    'Grains and dry goods',
    'Condiments and oils',
    'Seeds, nuts, and spices',
    'Frozen',
    'Cold',
    'Other',
];

export const EditIngredient = (props: { auth: IUserAuth }) => {

    // Setup View
    const { id } = useParams();
    const nav = useNavigate();
    const [view, setView] = useState<IIngredient>(IngredientInitialState);

    // Alerts
    const alertRef = useRef(null);

    // Delete Modal
    const deleteModalRef = useRef(null);

    // Load Data
    useEffect(() => {
        if (!!id){
            IngredientService.getById(id).then(result => setView(result));
        }
    }, []);

    // Actions
    const saveChanges = (formData: IIngredient) => {
        if (!view.id){
            IngredientService.create(formData).then(result => handleSaveResponse(result));
        } else {
            IngredientService.update(formData).then(result => handleSaveResponse(result));
        }
    }

    const handleSaveResponse = (data: IApiResponse) => {
        if (data.success){
            alertRef.current.showSuccess();
        }
        else {
            alertRef.current.showError(data.Title);
        }
    };

    const showDeleteModal = () => {
        deleteModalRef.current.showModal();
    }

    const confirmDeleteItem = () => {
        let command: IDeleteIngredientCommand = { id: view.id };
        IngredientService.del(command).then(result => handleDeleteResponse(result));
    }

    const handleDeleteResponse = (data: IApiResponse) => {
        if (data.success){
            closeItem();
        }
        else {
            alertRef.current.showError(data.Title);
        }
    };

    const closeItem = () => {
        nav('/Admin/Ingredients');
    };

    const MetricField = (fieldRenderProps: FieldRenderProps, props: IMetricField) => {
        const { validationMessage, visited, ...others } = fieldRenderProps;
        return (
            <FieldWrapper>
                <Field component={Input}  name={props.name} label={props.label}
                    validator={FormValidation.numberOrNull} {...others}/>
                    {visited && validationMessage && <Error>{validationMessage}</Error>}
            </FieldWrapper>
        );
    }

    return (
        <div>
            <div className="form-container">
            <Form initialValues={view}
                    key={JSON.stringify(view)}
                    onSubmit={saveChanges}
                    render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h1>Edit Ingredient</h1>
                            </div>
                            <div className='col-md-6 text-right'>
                                <ButtonGroup>
                                    <Button type={"submit"}><Icon path={mdiCheck} />Save Changes</Button>
                                    <Button type={"button"} onClick={showDeleteModal} hidden={!view.id}><Icon path={mdiDelete} />Delete</Button>
                                    <Button type={"button"} onClick={closeItem}><Icon path={mdiClose} />Close</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        
                        <FieldWrapper>
                            <Field name={"name"} component={Input} label={"Name"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"category"} component={DropDownList} data={categories} label={"Category"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"shortName"} component={Input} label={"Short Name"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"publicFoodKey"} component={Input} label={"Public Food Key"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"classificationId"} component={Input} label={"Classification Id"} />
                        </FieldWrapper>
                        {metrics.map((metric) => (
                            <Field component={MetricField} name={metric.name} label={metric.label} validator={FormValidation.numberOrNull} />
                        ))}
                        
                    </FormElement>
                )} />
            <Alert ref={alertRef} />

            <ModalOkCancel ref={deleteModalRef}
                    title={'Delete Ingredient'}
                    description={'Are you sure you want to delete this ingredient?'}
                    okText={'Confirm Delete'} cancelText={'Cancel'}
                    onOkClick={confirmDeleteItem}/>
            </div>
        </div>
    );
}