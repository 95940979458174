import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem } from 'reactstrap'
import { Icon } from '@mdi/react';
import { mdiAccountCircle, mdiTune, mdiShieldCrownOutline, mdiFoodAppleOutline, mdiLogout, mdiAccountGroup, mdiFormTextboxPassword, mdiEmail, mdiCardAccountDetailsStar } from '@mdi/js';
import { Link } from "react-router-dom";
import { IUserAuth } from 'common/Interfaces';

export const UserMenu = (props: { auth: IUserAuth }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const sendEmail = (e) => {
        window.location.href = 'mailto:support@wholisticmealplans.com.au';
        e.preventDefault();
    }

    const AdminMenuItems = () => {
        return (
            <div>
            <DropdownItem header>
                <Icon path={mdiShieldCrownOutline}/>Administration
            </DropdownItem>
            {/* <DropdownItem tag={Link} to='/'>
                <Icon path={mdiBookOpenPageVariantOutline}/>Recipes
            </DropdownItem> */}
            <DropdownItem tag={Link} to='/Admin/Ingredients'>
                <Icon path={mdiFoodAppleOutline}/>Ingredients
            </DropdownItem>
            <DropdownItem tag={Link} to='/Admin/Users'>
                <Icon path={mdiAccountGroup}/>Users
            </DropdownItem>
            <DropdownItem divider/>
            </div>
        );
    }

    return (
        <NavItem>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className='userMenu'>
                <DropdownToggle caret><Icon path={mdiAccountCircle}/>{props.auth.username}</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem tag={Link} to='/EditProfile'>
                        <Icon path={mdiTune}/>Edit Profile
                    </DropdownItem>
                    <DropdownItem tag={Link} to='/ManageSubscription'>
                        <Icon path={mdiCardAccountDetailsStar}/>Manage Subscription
                    </DropdownItem>
                    <DropdownItem tag={Link} to='/EditPassword'>
                        <Icon path={mdiFormTextboxPassword}/>Change Password
                    </DropdownItem>
                    <DropdownItem divider/>
                    {/* <DropdownItem tag={Link} to='#' onClick={sendEmail}>
                        <Icon path={mdiEmail}/>Contact Us
                    </DropdownItem> */}
                    <DropdownItem divider/>
                    {(props.auth.isAdmin) ? <AdminMenuItems /> : <></>}                
                    <DropdownItem tag={Link} to='/Logout'><Icon path={mdiLogout }/>Sign Out</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </NavItem>
    );
}