import { Container } from 'reactstrap';
import { NavMenu } from 'components/NavMenu';
import { Footer } from 'components/Footer';
import { Outlet } from 'react-router-dom';
import { IUserAuth } from 'common/Interfaces';

export const Layout = (props: { auth: IUserAuth }) => {
    return (
        <div className='d-flex flex-column min-vh-100'>
            <NavMenu auth={props.auth} />
            <Container className='main'>
                <Outlet />
            </Container>
            <Footer />
        </div>
    );
}