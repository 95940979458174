import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

export const Footer = () => {
    return (
        <footer className='mt-auto'>
            <Container>
                <p>| Copyright &copy; 2023 Wholistic Meal Plans | Version 1.2 | <Link to='/PrivacyPolicy'>Privacy Policy</Link> | <Link to='/TermsAndConditions'>Terms and Conditions</Link> |</p>
            </Container>
        </footer>
    );
}