import { IApiResponse, IUserAuth } from 'common/Interfaces';
import { EditProfileInitialState, IEditProfile } from 'pages/Users/UserInterfaces';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserService } from 'services/UserService';
import { Alert } from 'components/Alert';
import { Input } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Form, Field, FieldWrapper, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { FormValidation } from 'services/FormValidation';
import { mdiCheck, mdiClose } from '@mdi/js'; 
import { Icon } from '@mdi/react';
import { IImageSelectorProps } from 'pages/Recipes/RecipeInterfaces';
import { Upload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { Card, CardBody, CardFooter, Alert as ReactStrapAlert, Label } from 'reactstrap';
import { uploadHeaders, uploadUrl } from 'services/Middleware';
import { Spinner } from 'components/Spinner';
import { SubscriptionDetailsBanner } from 'components/SubscriptionDetailsBanner';

export const EditProfile = (props: { auth: IUserAuth }) => {

    // Setup View
    const { id } = useParams();
    const nav = useNavigate();
    const [view, setView] = useState<IEditProfile>(EditProfileInitialState);
    const [loading, setLoading] = useState(false);

    // Alerts
    const alertRef = useRef(null);

    // Load Data
    useEffect(() => {
        setLoading(true);
        UserService.getProfileForEdit()
            .then(result => setView(result))
            .finally(() => setLoading(false));
    }, []);

    // Actions
    const saveChanges = (formData: IEditProfile) => {
        UserService.updateProfile(formData).then(result => handleResponseAndReload(result));
    }

    const handleResponseAndReload = (data: IApiResponse, successMessage?: string) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }

        alertRef.current.showSuccess(successMessage);
        UserService.getProfileForEdit().then(result => setView(result));
    };

    const closeItem = () => {
        nav(-1);
    };

    const Buttons = () => {
        return (
            <ButtonGroup>
                <Button type={"submit"}><Icon path={mdiCheck} />Save Changes</Button>
                <Button type={"button"} onClick={closeItem}><Icon path={mdiClose} />Close</Button>
            </ButtonGroup>
        )
    }

    const ImageSelector = (props: IImageSelectorProps) => {

        const [lastImage, setLastImage] = useState<string>(view.imageUri);

        const onUploadCompleted = (e: UploadOnStatusChangeEvent) => {
            if (e.response && e.response.status == 200){

                // The title field has the imageUri
                const result = e.response.response.title;
                setLastImage(result);
                props.formRenderProps.onChange('imageId', { value: getImageIdFromUri(result)});
            }
        }

        const getImageIdFromUri = (uri: string) => {
            const fileName = uri.split('/').pop();
            return fileName.substring(0, fileName.lastIndexOf('.'));
        }

        return (
            <div className='row'>
                <div className='col-md-5'>
                <Card className='image-selector-item logo'>
                    <CardBody>
                        {lastImage && <img src={lastImage} />}
                    </CardBody>
                    <CardFooter></CardFooter>
                </Card>
                </div>
                <div className='col-md-7'>
                <Upload batch={false} multiple={false} saveUrl={uploadUrl}
                    defaultFiles={[]} onStatusChange={onUploadCompleted}
                    saveHeaders={uploadHeaders('UploadLogoImage', 'User', view.id)}
                    restrictions={{allowedExtensions: [".jpg", ".png"], maxFileSize: 6000000}}/>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="form-container">
            <Form initialValues={view}
                    key={JSON.stringify(view)}
                    onSubmit={saveChanges}
                    render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h1>Edit Profile</h1>
                            </div>
                            <div className='col-md-6 text-right'>
                                <Buttons />
                            </div>
                        </div>
                        <SubscriptionDetailsBanner expiry={view.expiry} />
                        <FieldWrapper>
                            <Field name={"email"} component={Input} disabled={true} label={"Email"} validator={FormValidation.notEmpty} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"firstName"} component={Input} label={"First Name"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"lastName"} component={Input} label={"Last Name"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"companyName"} component={Input} label={"Company"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"phoneNumber"} component={Input} label={"Phone Number"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"modality"} component={Input} label={"Your Role"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label className='mt-5'>Logo Image</Label>
                            <ImageSelector formRenderProps={formRenderProps} />
                        </FieldWrapper>
                    </FormElement>
                )} />
                <Alert ref={alertRef} />
            </div>
            <Spinner loading={loading} />
        </div>
    );
}