import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import { ListView, ListViewEvent } from '@progress/kendo-react-listview';
import { Input, InputChangeEvent, Slider } from "@progress/kendo-react-inputs";
import { MultiSelectFilterChangeEvent, MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { Label } from "@progress/kendo-react-labels";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { useNavigate } from 'react-router-dom';
import { mdiPlus, mdiFilterOffOutline } from '@mdi/js'; 
import { Icon } from '@mdi/react';
import _ from "lodash";
import { useListFunctions } from 'hooks/useListFunctions';
import { RecipeService } from 'pages/Recipes/RecipeService';
import { useEffect, useState } from 'react';
import { RecipeListItem } from './RecipeListItem';
import { Spinner } from 'components/Spinner';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { IUserAuth } from 'common/Interfaces';
import { useSubscription } from 'hooks/useSubscription';

export const Recipes = (props: { auth: IUserAuth }) => {
    const list = useListFunctions();

    // Navigation
    const navigate = useNavigate();
    const addRecipe = () => navigate('/Recipes/Edit');

    const [allTagOptions, setAllTagOptions] = useState([]);
    const [tagOptions, setTagOptions] = useState(allTagOptions);
    const [excludeTagOptions, setExcludeTagOptions] = useState(allTagOptions);

    const [searchText, setSearchText] = useSessionStorage('searchText', '');
    const [tags, setTags] = useSessionStorage('includeTags', []);
    const [excludeTags, setExcludeTags] = useSessionStorage('excludeTags', []);
    const [maxTime, setMaxTime] = useSessionStorage('maxTime', 120);

    // Remember scroll position
    const [recipeScroll, setRecipeScroll] = useSessionStorage('recipeScroll', 0);
    // Remember how many items are displayed on screen (required for remember scroll position)
    const [displayedRowsCount, setDisplayedRowsCount] = useSessionStorage('displayedRowsCount', 12);

    const [rowCount, setRowCount] = useState(0);

    // Infinite scrolling requires a separate set of rows to list.rows
    const [displayedRows, setDisplayedRows] = useState([]);

    // Check Subscription
    useSubscription(props.auth);

    // On Page Load
    useEffect(() => {
        RecipeService.getTags().then(results => {
            setAllTagOptions(results);
            setTagOptions(results);
            setExcludeTagOptions(results);
        });

        // Event listener to track scroll position
        const contentDiv = document.querySelector('.k-listview-content');
        contentDiv.addEventListener('scroll', event => {
            setRecipeScroll(contentDiv.scrollTop);
        }, { passive: true });

    }, []);

    // 1 Second Delay
    useDebouncedEffect(() => {
        runQuery();
    }, [searchText, tags, excludeTags, maxTime], 1000, list.firstCallMade);

    const runQuery = () => {

        // Items to display - Start with 12
        let displayCount = displayedRowsCount >= 12 ? displayedRowsCount : 12;  
        let scrollPosition = recipeScroll;

        if (list.firstCallMade) {
            setDisplayedRowsCount(12);
            setRecipeScroll(0);
            displayCount = 12;
            scrollPosition = 0;
        }

        list.setLoading(true);
        list.setFirstCallMade(true);
        list.setRows([]);

        const filteredMaxTime = maxTime == 120 ? null : maxTime;

        RecipeService.search(searchText, tags, excludeTags, filteredMaxTime).then(results => {
            
            // All items
            list.setRows(results);
            setRowCount(results.length);

            setDisplayedRows(results.splice(0, displayCount));
            setScroll(scrollPosition);

        }).finally(() => list.setLoading(false));
    };

    const searchTextChange = (event: InputChangeEvent) => {
        setSearchText(event.value);
    }

    // Tagging
    const filterTagsData = (filter: FilterDescriptor) => {
        return filterBy(allTagOptions.slice(), filter);
    }

    const onTagsFilterChange = (event: MultiSelectFilterChangeEvent) => {
        setTagOptions(filterTagsData(event.filter));
    }

    const onTagsChange = (e) => setTags(e.value);

    const filterExcludeTagsData = (filter: FilterDescriptor) => {
        return filterBy(allTagOptions.slice(), filter);
    }

    const onExcludeTagsFilterChange = (event: MultiSelectFilterChangeEvent) => {
        setExcludeTagOptions(filterExcludeTagsData(event.filter));
    }

    const onExcludeTagsChange = (e) => setExcludeTags(e.value);

    const resetFilters = () => {
        setSearchText('');
        setTags([]);
        setExcludeTags([]);
        setMaxTime(120);
        setRecipeScroll(0);
        setDisplayedRowsCount(12);
    }

    // Infinite Scrolling
    const scrollHandler = (event: ListViewEvent) => {
        const t = event.nativeEvent.target;
        
        if (t.scrollTop + 10 >= t.scrollHeight - t.clientHeight) {
            const moreData = list.rows.splice(0, 3);
            if (moreData.length > 0) {
                setDisplayedRows(displayedRows.concat(moreData));
                setDisplayedRowsCount(displayedRows.length);
            }
        }        
    };

    const setScroll = (position: number) => {
        const elements = document.getElementsByClassName("k-listview-content");
        if (!!elements && elements.length) {
            const content = _.first(elements);
            content.scrollTop = position;
        }
    }

    return (
        <div>
            <div className='row'>
                <div className='col-md-6'>
                    <h1>Recipes</h1>
                </div>
                <div className='col-md-6 text-right'>
                    <span className='px-4'>{rowCount} Results Found</span>
                    <ButtonGroup>
                        <Button type={'button'} onClick={() => resetFilters()}><Icon path={mdiFilterOffOutline} />Clear Filters</Button>
                        <Button type={"button"} onClick={() => addRecipe()} hidden={!props.auth.isAdmin}><Icon path={mdiPlus} />Create</Button>
                    </ButtonGroup>
                </div>
            </div>
            <div className='row recipe-filters'>
                <div className='col col-md-3'>
                    <Input onChange={searchTextChange} value={searchText} label='Title' />
                </div>
                <div className='col col-md-3'>
                    <MultiSelect data={tagOptions} value={tags} onChange={onTagsChange} filterable={true} onFilterChange={onTagsFilterChange} label='Tags' className='w-100' />
                </div>
                <div className='col col-md-3'>
                    <MultiSelect data={excludeTagOptions} value={excludeTags} onChange={onExcludeTagsChange} filterable={true} onFilterChange={onExcludeTagsFilterChange} label='Exclusions' className='w-100' />
                </div>
                <div className='col col-md-3'>
                    <Label>{maxTime == 120 ? 'No limit on time' : `Less than ${maxTime} mins`}</Label>
                    <Slider onChange={e => setMaxTime(Math.floor(e.value / 10) * 10)} min={10} max={120} value={maxTime} />
                </div>
            </div>
            <ListView className='recipe-listview' onScroll={scrollHandler} data={displayedRows} item={RecipeListItem} />
            <Spinner loading={list.loading} />
        </div>
    );
}