import { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Alert } from "reactstrap";
import { Spinner } from "components/Spinner";
import { IUpdatePaymentMethodCommand } from "./UserCommands";
import { UserService } from "services/UserService";
import { IApiResponse } from "common/Interfaces";

export const UpdatePaymentResultChild = (props: { clientSecret: string }) => {

    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [updated, setUpdated] = useState(false);

    const stripe = useStripe();
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (!stripe) return;

        stripe.retrieveSetupIntent(props.clientSecret)
            .then(({setupIntent}) => {
                switch (setupIntent.status) {
                    case 'succeeded':
                        updatePaymentMethod();
                        break;
                    case 'processing':
                        setMessage("Processing payment details. We'll update you when processing is complete...");
                        break;
                    case 'requires_payment_method':
                        setMessage('Payment method update in payment system failed. Please try again.');
                        setIsError(true);
                        setLoading(false);
                        break;
                }
            })

    }, [stripe]);

    // Update customer and subscription through command.
    const updatePaymentMethod = () => {
        const setupIntentId = new URLSearchParams(window.location.search).get('setup_intent');

        const command: IUpdatePaymentMethodCommand = {
            setupIntentId: setupIntentId,
        };

        UserService.updatePaymentMethod(command)
            .then(response => handleResponse(response))
            .finally(() => setLoading(false));
    }

    const handleResponse = (response: IApiResponse) => {
        if (response.success){
            setMessage("Payment details have been updated.");
            setUpdated(true);
        } else {
            setMessage('Payment method update failed: ' + response.Title);
            setIsError(true);
        }
    }

    return (
    <div className="row">
        <div className="col col-md-6">
            <h1>Updating Payment Method</h1>
            <Alert isOpen={!!isError} color="danger">{message}</Alert>
            <Alert isOpen={!!updated} color="success">{message}</Alert>
            <p hidden={!loading}>{message}</p>
            <Spinner loading={loading} />
        </div>
    </div>
    );
}