import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from "lodash";
import { uploadUrl, uploadHeaders } from 'services/Middleware';
import { Form, Field, FieldArray, FieldWrapper, FormElement, FormRenderProps, FieldArrayRenderProps, FieldRenderProps } from "@progress/kendo-react-form";
import { Input, Checkbox, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListFilterChangeEvent, MultiSelect, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { Alert } from 'components/Alert';
import { GridRowCommandsCell } from 'components/GridRowCommandsCell';
import { IApiResponse, IButtonGroupRenderProps, IUserAuth } from 'common/Interfaces';
import { IEditRecipe, EditRecipeInitialState, IEditRecipeIngredient, IImageSelectorProps } from 'pages/Recipes/RecipeInterfaces';
import { IArchiveRecipeCommand, IRestoreRecipeCommand, IDeleteRecipeCommand } from 'pages/Recipes/RecipeCommands';
import { RecipeService } from 'pages/Recipes/RecipeService';
import { FormValidation } from 'services/FormValidation';
import Icon from '@mdi/react';
import { mdiCheck, mdiArchive, mdiRestore, mdiDelete, mdiClose, mdiPlus } from '@mdi/js';
import { IngredientService } from 'services/IngredientService';
import { Upload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { Badge, Card, CardBody, CardFooter, Label } from 'reactstrap';
import { Spinner } from 'components/Spinner';
import { ModalOkCancel } from 'components/ModalOkCancel';

export const EditRecipe = (props: { auth: IUserAuth }) => {
    
    // Setup View
    const { id } = useParams();
    const nav = useNavigate();
    const [view, setView] = useState<IEditRecipe>(EditRecipeInitialState);
    const [ingredientOptions, setIngredientOptions] = useState([]);
    const [allTagOptions, setAllTagOptions] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);

    // Alerts
    const alertRef = useRef(null);

    // Delete Modal
    const deleteModalRef = useRef(null);

    // Load Data
    useEffect(() => {
        if (!!id){
            setLoading(true);
            RecipeService.getForEdit(id).then(result => {
                setView(result);

                if (!!result.ingredients){
                    const names = result.ingredients.map(i => i.ingredient);
                    names.forEach(i => updateTagSuggestions(i));
                }
            }).finally(() => setLoading(false));
        }
        
        IngredientService.getSelectItems('').then(results => {
            setIngredientOptions(results);
        });

        RecipeService.getTags().then(results => {
            setAllTagOptions(results);
            setTagOptions(results);
        })
    }, []);

    // Tag Suggestions
    const updateTagSuggestions = (item: string) => {
        if (!!item){
            const firstPhrase = item.split(',')[0].replace(/[\W_]+/g, "").toLowerCase();
            if (!_.includes(tagSuggestions, firstPhrase)){
                setTagSuggestions(old => [...old, firstPhrase]);
            }
        }
    }

    // Actions
    const saveChanges = (formData: IEditRecipe) => {
        
        formData = prepareForSave(formData);

        if (!view.id) {
            RecipeService.create(formData).then(result => handleResponseAndClose(result));
        } else {
            RecipeService.update(formData).then(result => handleResponseAndReload(result));
        }
    }

    const archiveItem = () => {
        let command: IArchiveRecipeCommand = { id: view.id };
        RecipeService.archive(command).then(result => handleResponseAndReload(result, 'Recipe archived.'));
    }

    const restoreItem = () => {
        let command: IRestoreRecipeCommand = { id: view.id };
        RecipeService.restore(command).then(result => handleResponseAndReload(result, 'Recipe restored.'));
    }

    const showDeleteModal = () => {
        deleteModalRef.current.showModal();
    }

    const confirmDeleteItem = () => {
        let command: IDeleteRecipeCommand = { id: view.id };
        RecipeService.del(command).then(result => handleResponseAndGoToList(result));
    }

    const handleResponseAndReload = (data: IApiResponse, successMessage?: string) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }

        alertRef.current.showSuccess(successMessage);
        RecipeService.getForEdit(view.id).then(result => setView(result));
        IngredientService.getSelectItems('').then(results => setIngredientOptions(results));
    };

    const handleResponseAndClose = (data: IApiResponse) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }
        closeItem();
    };

    const handleResponseAndGoToList = (data: IApiResponse) => {
        if (!data.success){
            alertRef.current.showError(data.Title);
            return;
        }
        nav('/Recipes');
    };

    const closeItem = () => {
        if (!!id){
            nav(`/Recipes/View/${id}`)
        } else {
            nav('/Recipes');
        }
    };

    const title = (!!view.id)
        ? (view.archived) ? `${view.name} (Archived)` : `${view.name}`
        : 'Create Recipe';

    const saveLabel = (!!view.id) ? 'Save Changes' : 'Create';

    const ParentFieldContext = createContext('');

    const calculateMils = (amount: number, measurementUnit: string) => {

        switch(measurementUnit) {
            case "Cup": return Math.round(amount * 240);
            case "Litre": return Math.round(amount * 1000);
            case "Tablespoon": return Math.round(amount * 20);
            case "Teaspoon": return Math.round(amount * 5);
            default: return 0;
        }
    }

    const TagSuggestions = (props: { formRenderProps: FormRenderProps }) => {

        const onClick = (e: { item: string }) => {
            const currentTags: string[] = props.formRenderProps.valueGetter("tags");
            props.formRenderProps.onChange("tags", { value: currentTags.concat(e.item)});
        }

        return (
            <div>
                {tagSuggestions.map((item, index) => <Badge onClick={() => onClick({item})}>+ {item}</Badge> )}
            </div>
        );
    }

    const calculateGrams = (dataItem: IEditRecipeIngredient) => {
        if (!!dataItem.millilitres && !!dataItem.volumeToWeightRatio){
            return Math.round((dataItem.millilitres * dataItem.volumeToWeightRatio) / 240);
        }
        return 0;
    }

    // Adjust the model before saving
    const prepareForSave = (formData: IEditRecipe) => {

        formData.ingredients.forEach((i, index) => {
            i.order = index;
        });

        return formData;
    }

    const filterTagsData = (filter: FilterDescriptor) => {
        return filterBy(allTagOptions.slice(), filter);
    }

    const onTagsFilterChange = (event: MultiSelectFilterChangeEvent) => {
        setTagOptions(filterTagsData(event.filter));
    }

    const TextCell = (props) => {
        const fieldArrayName = useContext(ParentFieldContext);
      
        return (
          <td>
            <Field
              component={Input}
              name={`${fieldArrayName}[${props.dataIndex}].${props.field}`}
            />
          </td>
        );
      };

    const AmountCell = (props: GridCellProps) => {

        const onChange = (e) => {
            if (props.dataItem.measurementUnit != 'Unit'){
                const calcMils = calculateMils(props.dataItem.amount, props.dataItem.measurementUnit)
                if (!!calcMils) {
                    props.dataItem.millilitres = calcMils;
                    props.dataItem.grams = calculateGrams(props.dataItem);
                }
            }
        }
        
        return (
            <td>
            <Field
                component={NumericTextBox}
                name={`ingredients[${props.dataIndex}].${props.field}`}
                onChange={onChange}
                validator={FormValidation.numberZeroOrGreater}
            />
            </td>
        );
    };

    const MilsCell = (props: GridCellProps) => {

        const onChange = (e) => {
            props.dataItem.grams = calculateGrams(props.dataItem);
        }
        
        return (
            <td>
            <Field
                component={NumericTextBox}
                name={`ingredients[${props.dataIndex}].${props.field}`}
                validator={FormValidation.numberZeroOrGreater}
                onChange={onChange}
                format={'n0'} // Whole numbers
            />
            </td>
        );
    };

    const GramsCell = (props) => {
        
        return (
            <td>
            <Field
                component={NumericTextBox}
                name={`ingredients[${props.dataIndex}].${props.field}`}
                validator={FormValidation.numberZeroOrGreater}
            />
            </td>
        );
    };

    const CheckboxCell = (props) => {
      
        return (
          <td className={'text-center'}>
            <Field
                component={Checkbox}
                name={`ingredients[${props.dataIndex}].${props.field}`}
            />
          </td>
        );
      };

    const MeasureDropDownCell = (props) => {

        const options = ['Unit', 'Cup', 'Litre', 'Tablespoon', 'Teaspoon']

        const onChange = (e) => {
            if (props.dataItem.measurementUnit != 'Unit'){
                const calc = calculateMils(props.dataItem.amount, props.dataItem.measurementUnit)
                if (calc != 0) {
                    props.dataItem.millilitres = calc;
                    props.dataItem.grams = calculateGrams(props.dataItem);
                }
            }
        }
        
        return (
            <td>
                <Field
                component={DropDownList}
                name={`ingredients[${props.dataIndex}].${props.field}`}
                data={options}
                onChange={onChange}
                />
            </td>
        );
    };

    const IngredientDropDownCell = (props: GridCellProps) => {

        const fieldArrayName = useContext(ParentFieldContext);
        const ingredientOptionsText = ingredientOptions.map(i => i.text);
        const [options, setOptions] = useState(ingredientOptionsText);

        const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
            return filterBy(ingredientOptionsText.slice(), filter);
        }
    
        const filterChange = (event: DropDownListFilterChangeEvent) => {
            setOptions(filterData(event.filter));
        }

        const onChange = (e) => {
            const parentItem = _.find(ingredientOptions, i => i.text == props.dataItem.ingredient);
            if (!!parentItem){
                props.dataItem.volumeToWeightRatio = parentItem.volumeToWeightRatio;
                props.dataItem.grams = calculateGrams(props.dataItem);
            }
            updateTagSuggestions(e.value);
        }
        
        return (
            <td>
                <Field
                component={DropDownList}
                name={`${fieldArrayName}[${props.dataIndex}].${props.field}`}
                data={options}
                filterable={true}
                onFilterChange={filterChange}
                onChange={onChange}
                />
            </td>
        );
    };

    const IngredientsGrid = (props: FieldArrayRenderProps) => {
        const { validationMessage, visited, name } = props;

        const generateNewRow = () => {
            return {
                id: '',
                friendlyName: '',
                measurementUnit: 'Unit',
                amount: 0,
                grams: 0,
                millilitres: 0,
                ingredient: '',
                volumeToWeightRatio: 0,
                inEdit: true,
                toBeDeleted: false,
            }
        }
        
        const onAdd = useCallback(e => {
            e.preventDefault();
            props.onPush({ value: generateNewRow() });
        }, [props.onPush]);

        const onRemove = useCallback(e => {
            props.onRemove({ index: e.dataIndex });
        }, [props.onRemove]);

        return (
            <>
                <Grid data={props.value}>
                    <GridColumn field="category" title="Category" cell={TextCell} width={150} />
                    <GridColumn field="friendlyName" title="Name" cell={TextCell} />
                    <GridColumn field='ingredient' title='Link to Ingredient' cell={IngredientDropDownCell} />
                    <GridColumn field='amount' title='Amount' cell={AmountCell} width={100} />
                    <GridColumn field='measurementUnit' title=' ' cell={MeasureDropDownCell} width={120} />
                    <GridColumn field='millilitres' title='Mls' cell={MilsCell} width={110} />
                    <GridColumn field='grams' title='Grams' cell={GramsCell} width={110} />
                    <GridColumn field='displayAsLiquid' title='Liquid' cell={CheckboxCell} width={60}/>
                    <GridColumn field='optional' title='Optional' cell={CheckboxCell} width={80}/>
                    <GridColumn cell={GridRowCommandsCell(onRemove)} width={70}/>
                </Grid>
                <Button className='w-100 mt-2' onClick={onAdd}><Icon path={mdiPlus} />Add Ingredient</Button>
            </>
        );
    }

    const InstructionsGrid = (props: FieldArrayRenderProps) => {
        const { validationMessage, visited, name } = props;

        const generateNewRow = () => {
            return { text: '' };
        };
        
        const onAdd = useCallback(e => {
            e.preventDefault();
            props.onPush({ value: generateNewRow() });
        }, [props.onPush]);

        const onRemove = useCallback(e => {
            props.onRemove({ index: e.dataIndex });
        }, [props.onRemove]);

        return (
            <>
                <Grid data={props.value} className='instructions-edit-table'>
                    <GridColumn field={'content'} title=" " cell={TextCell} />
                    <GridColumn cell={GridRowCommandsCell(onRemove)} width={70}/>
                </Grid>
                <Button className='w-100 mt-2' onClick={onAdd}><Icon path={mdiPlus} />Add Instruction</Button>
            </>
        );
    }

    const ImageSelector = (props: IImageSelectorProps) => {

        const [lastImage, setLastImage] = useState<string>(view.imageUri);

        const onUploadCompleted = (e: UploadOnStatusChangeEvent) => {
            if (e.response && e.response.status == 200){

                // The title field has the imageUri
                const result = e.response.response.title;
                setLastImage(result);
                props.formRenderProps.onChange('imageId', { value: getImageIdFromUri(result)});
            }
        }

        const getImageIdFromUri = (uri: string) => {
            const fileName = uri.split('/').pop();
            return fileName.substring(0, fileName.lastIndexOf('.'));
        }

        return (
            <div className='row'>
                <div className='col-md-2'>
                <Card className='image-selector-item'>
                    <CardBody>
                        <img src={lastImage} />
                    </CardBody>
                    <CardFooter></CardFooter>
                </Card>
                </div>
                <div className='col-md-10'>
                <Upload batch={false} multiple={false} saveUrl={uploadUrl}
                    defaultFiles={[]} onStatusChange={onUploadCompleted}
                    saveHeaders={uploadHeaders('UploadRecipeImage', 'Recipe', view.id)}
                    restrictions={{allowedExtensions: [".jpg"], maxFileSize: 6000000}}/>
                </div>
            </div>
        );
    }

    const Buttons = (buttonRenderProps: IButtonGroupRenderProps) => {
        if (view.archived) {
            return (
                <ButtonGroup>
                    <Button type={"button"} onClick={restoreItem} hidden={!view.id}><Icon path={mdiRestore} />Restore</Button>
                    <Button type={"button"} onClick={closeItem}><Icon path={mdiClose} />Close</Button>
                </ButtonGroup>
            );
        } else {
            return (
                <ButtonGroup>
                    <Button type={"submit"} disabled={!buttonRenderProps.allowSubmit}><Icon path={mdiCheck} />{saveLabel}</Button>
                    <Button type={"button"} onClick={archiveItem} hidden={!view.id}><Icon path={mdiArchive} />Archive</Button>
                    <Button type={"button"} onClick={showDeleteModal} hidden={!view.id}><Icon path={mdiDelete} />Delete</Button>
                    <Button type={"button"} onClick={closeItem}><Icon path={mdiClose} />Close</Button>
                </ButtonGroup>
            );
        }
    }

    return (
        <div>
            <div className="form-container">
            <Form initialValues={view}
                    key={JSON.stringify(view)}
                    onSubmit={saveChanges}
                    render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h1>{title}</h1>
                            </div>
                            <div className='col-md-6 text-right'>
                                <Buttons allowSubmit={formRenderProps.allowSubmit} />
                            </div>
                        </div>
                        
                        <FieldWrapper>
                            <Field name={"name"} component={Input} label={"Name"} validator={FormValidation.notEmpty} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"externalId"} component={Input} label={"Master Number"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"servings"} component={Input} label={"Servings"} validator={FormValidation.numberGreaterThanZero} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"preparationTime"} component={Input} label={"Preparation Time (Minutes)"} validator={FormValidation.numberZeroOrGreater} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"cookingTime"} component={Input} label={"Cooking Time (Minutes)"} validator={FormValidation.numberZeroOrGreater} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Field name={"tags"} component={MultiSelect} label={"Tags"} data={tagOptions} allowCustom={true} filterable={true} onFilterChange={onTagsFilterChange} validator={FormValidation.tagsValid} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <TagSuggestions formRenderProps={formRenderProps} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label className='mt-5'>Ingredients</Label>
                            <ParentFieldContext.Provider value={'ingredients'}>
                                <FieldArray name={"ingredients"} component={IngredientsGrid} key={"id"} />
                            </ParentFieldContext.Provider>
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label className='mt-5'>Directions</Label>
                            <ParentFieldContext.Provider value={'instructions'}>
                                <FieldArray name={'instructions'} component={InstructionsGrid} key={JSON.stringify(view.instructions)} />
                            </ParentFieldContext.Provider>
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label className='mt-5'>Notes</Label>
                            <Field name={"notes"} component={TextArea} label={"Notes"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label>Key Notes</Label>
                            <Field name={"keyNotes"} component={TextArea} label={"Key Notes"} />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label className='mt-5'>Image</Label>
                            <ImageSelector formRenderProps={formRenderProps} />
                        </FieldWrapper>
                    </FormElement>
                )} />
                <Alert ref={alertRef} />

                <ModalOkCancel ref={deleteModalRef}
                    title={'Delete Recipe'}
                    description={'Are you sure you want to delete this recipe?'}
                    okText={'Confirm Delete'} cancelText={'Cancel'}
                    onOkClick={confirmDeleteItem}/>
            </div>
            <Spinner loading={loading} />
        </div>
    );
}
