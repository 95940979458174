import { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { ISignUpViewModel } from "./AuthViewModels";
import { AuthService } from "services/AuthService";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { Spinner } from "components/Spinner";

export const PaymentStatusQuery = (props: { clientSecret: string }) => {

    const nav = useNavigate();

    const [loading, setLoading] = useState(false);
    const [lastError, setLastError] = useState('');
    const [signUpComplete, setSignUpComplete] = useState(false);

    const stripe = useStripe();
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (!stripe){
            return;
        }

        stripe.retrieveSetupIntent(props.clientSecret)
            .then(({setupIntent}) => {
                switch (setupIntent.status) {
                    case 'succeeded':
                        createAccount();
                        break;
                    case 'processing':
                        setMessage("Processing payment details. We'll update you when processing is complete.");
                        break;
                    case 'requires_payment_method':
                        setLastError('Payment failed. Please try again.');
                        break;
                }
            })

    }, [stripe]);

    const createAccount = () => {
        const setupIntent = new URLSearchParams(window.location.search).get('setup_intent');
        const userName = new URLSearchParams(window.location.search).get('userName');
        const email = new URLSearchParams(window.location.search).get('email');
        const firstName = new URLSearchParams(window.location.search).get('firstName');
        const lastName = new URLSearchParams(window.location.search).get('lastName');
        const companyName = new URLSearchParams(window.location.search).get('companyName');
        const phoneNumber = new URLSearchParams(window.location.search).get('phoneNumber');
        const modality = new URLSearchParams(window.location.search).get('modality');
        const modalityOther = new URLSearchParams(window.location.search).get('modalityOther');

        const model: ISignUpViewModel = {
            setupIntent: setupIntent,
            userName: userName,
            email: email,
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            phoneNumber: phoneNumber,
            modality: modality,
            modalityOther: modalityOther,
        }

        setLoading(true);

        AuthService.signUp(model)
          .then(response => {
            if (response.success){
                handleSuccess();                
            } else {
              handleFailure(response);
            }
          }).finally(() => setLoading(false));
    }

    function goToLogin(){
        nav('/Login');
    }

    function handleSuccess(){
        setLastError('');
        setSignUpComplete(true);
    }

    function handleFailure(response){
        setLastError(response.Title);
    }

    return (
    <div className="row">
        <div className="col col-md-12  col-lg-6 offset-lg-3">
            <div className="sign-up-tile">
                <h1>Create Account</h1>
                <p>{message}</p>
                <Alert isOpen={!!lastError} color="danger">{lastError}</Alert>
                <Alert isOpen={!!signUpComplete} color="success">Your account has been created and a password has been emailed to you.</Alert>
                <Button className='mt-4' hidden={!signUpComplete} type={'button'} onClick={goToLogin}>Go back to Login</Button>
                <Spinner loading={loading} />
            </div>
        </div>
    </div>
    );
}