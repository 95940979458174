import { IUserAuth } from "common/Interfaces";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useSubscription = (auth: IUserAuth) => {

    const nav = useNavigate();

    useEffect(() => {
        if (!!auth.authenticated && !auth.isActiveSubscription) {
            nav('/ManageSubscription');
        }
    }, [auth]);    
}