
export const PrivacyPolicy = () => {
    return (
<div className="row">
    <div className="col-md-12">
        <h1>Privacy Policy</h1>
        <p></p>
<ol>
	<li><strong>BACKGROUND</strong></li>
</ol>

<p>Wholistic Meal Plans Pty Ltd (ACN 662 157 735) (&ldquo;<strong>Wholistic Meal Plans</strong>&rdquo;) is committed to protecting the privacy of your personal information.</p>

<p>This Privacy Policy tells you how Wholistic Meal Plans and its related entities (together &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo; or &ldquo;<strong>we</strong>&rdquo;) will handle your personal information in accordance with the&nbsp;<em>Privacy Act&nbsp;</em>1988 (Cth) (&ldquo;<strong>Privacy Act</strong>&rdquo;), the Australian Privacy Principles (&ldquo;<strong>APPs</strong>&rdquo;).</p>

<p>This Privacy Policy applies when you visit the Site. By visiting the Site, you&nbsp;&nbsp; agree&nbsp;&nbsp; to&nbsp;&nbsp; the&nbsp;&nbsp; terms&nbsp;&nbsp; of&nbsp;&nbsp; this&nbsp;&nbsp; Privacy&nbsp;&nbsp; Policy.&nbsp; You should not use the Site if you do not agree with this Privacy Policy or our Terms of Use.</p>

<p>Capitalised terms in this Privacy Policy have the meaning given to those terms in the Definitions section (below) to the extent included in that section.</p>

<ol start={2}>
	<li><strong>DEFINITIONS</strong></li>
</ol>

<p>&ldquo;<strong>Customer</strong>&rdquo; means a person who uses the Site on any Device to purchase any of the products advertised on the Site or obtain and/or review any information contained on the Site.</p>

<p>&ldquo;<strong>Device</strong>&rdquo; means any type of device including a computer, mobile phone, tablet or console that meets the minimum specifications required to use the Site.</p>

<p>&ldquo;<strong>Device Information</strong>&rdquo; means data that can be automatically collected from any device used to access the Site, including your Device type, your Device&rsquo;s network connections, your Device&rsquo;s name, your Device&rsquo;s IP address, information about your Device&rsquo;s web browser and the internet connection used to access the Site, Geolocation Information, information about apps downloaded to your Device and biometric data (such as Touch ID/Fingerprint).</p>

<p>&ldquo;<strong>Geolocation Information</strong>&rdquo; means information that identifies your location by using via longitude and latitude coordinates obtained through GPS, Wi-Fi or cell site triangulation.</p>

<p>&ldquo;<strong>Site</strong>&rdquo; means the website operating from the domain at https://wholisticmealplans.com.au/.</p>

<p>&ldquo;<strong>Products</strong>&rdquo; means any and all products advertised on the Site.</p>

<p>&ldquo;<strong>Technical Usage Data</strong>&rdquo; means information we collect from your Device that you use to access the Site or search, review and purchase the Products such as what you have searched for and viewed on the Site, the length of your visit and the way you use the Site, including your IP address, statistics regarding how pages are loaded or viewed, the websites you visited before coming to the Sites and other usage and browsing information collected through cookies.</p>

<p>&ldquo;<strong>User</strong>&rdquo; means a Customer or any other user of the Site.</p>

<ol start={3}>
	<li><strong>WHAT PERSONAL INFORMATION DO WE COLLECT?</strong></li>
</ol>

<p>We collect and use personal information from Users and visitors of the Site. The specific type of personal information that we collect will depend on will depend on the reasons for, or circumstances of its collection and may include, but is not limited to, the following:</p>

<ul>
	<li><strong>Account registration and use information:&nbsp;</strong>name, telephone and mobile number, email address, residential and postal address, Facebook username (if you log in to the Application via Facebook) and contact list information (if your contact list is linked to your account);</li>
	<li><strong>Verification of identity information:&nbsp;</strong>age, date of birth, place of birth, details and copies of identity documents (such as passport, driver&rsquo;s licence, marriage certificate, change of name certificate, birth certificate, citizenship certificate, Medicare card, Centrelink card, Department of Veteran Affairs&rsquo; card and/or change of name certificate), tax file number, signature and photograph;</li>
	<li><strong>Payment and transactional information:&nbsp;</strong>banking, credit card or debit card details, billing information, bill details, Device information and Technical Usage Data;</li>
	<li><strong>Enquiries, communications and social media:&nbsp;</strong>information contained in any enquiry you submit to us regarding our Products and services, communication content, metadata associated with communications and information about you shared by social media platforms (if you communicate with us via a social media platform that we use); and&nbsp;</li>
	<li><strong>Other personal or sensitive information:</strong>&nbsp;health information and history, disability status, racial or ethnic origin(s), language(s) spoken, religious belief(s) and affiliation(s), gender(s), occupation(s), employment and qualification details, financial information and criminal history.</li>
</ul>

<p>We generally do not collect sensitive information (as defined under the Privacy Act) and restrict collection of such information to circumstances where we have either obtained your express consent or we are permitted by law.</p>

<p>If you do not allow us to collect all the personal information we reasonably request, we may not be able to deliver the Products to you.</p>

<ol start={4}>
	<li><strong>HOW WE COLLECT PERSONAL INFORMATION</strong></li>
</ol>

<p>We may collect your personal information directly from you or in the course of our dealings with you. For example, we collect personal information from you or about you from:</p>

<ul>
	<li>your use of the Site;</li>
	<li>correspondence between you and us;</li>
	<li>visits to and submissions you make on our Site;</li>
	<li>your interactions with our electronic direct mail and/or emails from our marketing campaigns (such as clicks on links included in these emails); and</li>
	<li>registration and forms you may fill in for our marketing-related activities and events.</li>
</ul>

<p>In some instances, we may receive personal information about you from third parties, including our related entities, government agencies and regulatory authorities. We may also receive personal information about you from your authorised third parties and publicly available sources.</p>

<ol start={5}>
	<li><strong>WHY WE COLLECT, HOLD AND USE PERSONAL INFORMATION</strong></li>
</ol>

<p>You permit us to collect, hold and use your personal information for purposes including, but not limited to, the following:</p>

<ul>
	<li>to provide you with use of our Site;</li>
	<li>supplying the services to you;</li>
	<li>managing our relationship with you (including maintaining a User profile), communicating with you, identifying you when you contact us, responding to your enquiries and keeping records;</li>
	<li>the performance of our contractual arrangements;</li>
	<li>ensuring the security of our Site and maintaining back-ups of our database(s);</li>
	<li>offering, promoting, advertising, marketing and selling relevant and suitable services or products to you;</li>
	<li>sending you relevant notifications, electronic direct mail, email marketing campaigns and/or newsletters;</li>
	<li>processing payments you have authorised; and</li>
	<li>any other purposes identified at the time of collecting your personal information.</li>
</ul>

<p>In addition to the above, you permit us to use your personal information:</p>

<ul>
	<li>where you have consented to its use or disclosure;</li>
	<li>to develop and improve our business, the Site and the Products;</li>
	<li>for monitoring, research and analysis in relation to our business, the Site, our services or products;</li>
	<li>to involve you in market research, gauging customer satisfaction and seeking feedback;</li>
	<li>for our internal accounting and administration;</li>
	<li>where we reasonably believe that use or disclosure is necessary to lessen or prevent a serious, immediate threat to someone&rsquo;s health or safety or the public&rsquo;s health or safety;</li>
	<li>where we reasonably suspect that unlawful activity has been, is being or may be engaged in and the use or disclosure is a necessary part of our investigation or in reporting the matter to the relevant authorities;</li>
	<li>in the preparation for, or conduct of, court proceedings or in an administrative or out-of-court procedure (or the implementation of orders of a court or tribunal or on behalf of an enforcement body);</li>
	<li>for the purpose of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice; and</li>
	<li>for any lawful purpose.</li>
</ul>

<p>Where we wish to use or disclose your personal information for other purposes, we will obtain your consent.</p>

<ol start={6}>
	<li><strong>DISCLOSURE TO THIRD PARTIES</strong></li>
</ol>

<p>By using the Site and/or by providing us with your personal information (or allowing another person to do so), you acknowledge and consent to us disclosing some or all of your personal information to third parties. This includes disclosure of your personal information and details:</p>

<ul>
	<li>to our related entities as necessary for the provision of products and services or to enable them to provide service offerings you have requested;</li>
	<li>to our third party provider of verification of identity services (in which case you will be asked to agree to the third party provider&rsquo;s applicable privacy policy and other policies);</li>
	<li>payment service providers for any payments you make via the Site (noting we use a third party payment service provider that is required to take reasonable steps to protect your information);</li>
	<li>to a person that uses the Products or Services on your behalf and/or a person you have authorised;</li>
	<li>if you enable third party applications to be used in conjunction with the Products and services, to those third party applications, including Facebook (if your account is linked to your Facebook login credentials);</li>
	<li>to our partners, contractors, suppliers, subcontractors and service providers, including without limitation our suppliers of IT based solutions that assist us in providing the Products and services, distributors of direct marketing communications; marketing agencies, insurers and external business advisors;</li>
	<li>in accordance with requirements or authorisations under applicable laws or to comply with our legal obligations; and</li>
	<li>to any other persons contemplated by this Privacy Policy.</li>
</ul>

<p>We take reasonable steps to ensure that third party recipients are obliged to protect the privacy and security of your personal information and use it only for the purpose for which it is disclosed.</p>

<p>Occasionally, we may be required to disclose your personal information to third parties are located outside of Australia.&nbsp;In this instance, we will take all reasonable steps to ensure that those third parties, in whichever jurisdiction, adhere to the terms of this Privacy Policy.</p>

<p>To the maximum extent provided by law, we are not responsible or liable for the protection and privacy of any personal information provided to third parties. You accept and agree that the disclosed personal information will be held by third parties and may be used by them in accordance with the Privacy Act and any privacy policy they may have, and in such circumstances, the third party recipient will be solely responsible for their use of this personal information.</p>

<ol start={7}>
	<li><strong>HOW WE HOLD AND STORE PERSONAL INFORMATION</strong></li>
</ol>

<p>Your personal information is held and stored on paper, by electronic means or both. We have physical, electronic and procedural safeguards in place for personal information and take reasonable steps to ensure that your personal information is protected from misuse, interference, loss and unauthorised access, modification and disclosure. In some cases, these facilities may be located offshore and/or in cloud-based servers.</p>

<p>Data held and stored on paper is stored in secure locked premises.</p>

<p>Data held and stored electronically is protected by internal and external firewalls, high encryption and all access to electronic data including databases requires password access that meets Microsoft complexity standards.</p>

<p>Access to personal information is restricted to staff and contractors whose job description requires access. Our employees and contractors are contractually obliged to maintain the confidentiality of any personal information held by us.</p>

<p>Data stored or archived off-site is contained within secure facilities. We also require our storage contractors to implement privacy safeguards.</p>

<p>We undertake regular data backups, with the data copied and backed up to multiple locations for redundancy purposes.</p>

<p>Our staff receive regular training on privacy procedures.</p>

<p>Where permitted by local data protection laws, we may use your personal information to send you targeted electronic marketing and promotional communications related to our Site and our Products and services (and those of third parties) that we think may interest you, unless you have requested not to receive such information.&nbsp; We will only use your personal information for targeted electronic marketing and promotional communications if you have consented to it (which consent may be express or, where permitted by local data laws, inferred).&nbsp; You can opt out of receiving any targeted electronic marketing or promotional communications by following the unsubscribe prompt provided in the communication.</p>

<ol start={8}>
	<li><strong>DESTRUCTION OF PERSONAL INFORMATION</strong></li>
</ol>

<p>We will retain your personal information whilst it is required for any of our business functions or for any other lawful purpose.</p>

<p>We will retain your personal information for the time periods required by law.</p>

<p>We use secure methods to destroy, desensitise or de-identify your personal information when it is no longer needed or legally required to be retained. Paper records are sent for secure destruction. In some instances, paper records and original documents will be returned to you and/or relevant third parties.</p>

<p>Electronic records may be archived to alternative storage and are subject to the procedural safeguards described above.</p>

<ol start={9}>
	<li><strong>ACCESS TO AND CORRECTION OF PERSONAL DATA</strong></li>
</ol>

<p>You have a right to request access to or correction of your personal information held by us.</p>

<p>If you wish to access, correct or update any personal information that we hold about you, please contact us via the details below.&nbsp;</p>

<p>We will respond to your request within a reasonable time of you making the request and give you access in the manner you requested unless it is unreasonable or impracticable for us to do so. There may be reasons why we cannot give you access to the information that you have requested, or we refuse to correct your personal information. In these instances, we will let you know these reasons in writing.</p>

<p>To assist us to keep our records up to date, please notify us of any changes to your personal information.</p>

<ol start={10}>
	<li><strong>DATA BREACH</strong></li>
</ol>

<p>We will take seriously and deal promptly with any accidental or unauthorised loss, use or disclosure of personal information.</p>

<p>We are subject to the Notifiable Data Breaches Scheme (&ldquo;<strong>NDB Scheme</strong>&rdquo;) under the Privacy Act. In assessing and responding to suspected notifiable data breaches, we will act in accordance with:</p>

<ul>
	<li>our applicable policies which incorporate the requirements of the NDB Scheme; and</li>
	<li>the guidance of the Office of the Australian Information Commissioner (&ldquo;<strong>OAIC</strong>&rdquo;).</li>
</ul>

<p>Where a breach of your personal information occurs that is likely to cause serious harm, we will notify you and make recommendations about the steps you should take in response to the breach. Where required by law, the OAIC will also be notified.</p>

<ol start={11}>
	<li><strong>FEEDBACK AND SURVEYS</strong></li>
</ol>

<p>From time to time, you may have the option to participate in surveys or provide feedback intended to improve the services offered by us which may involve providing additional personal information. Your participation in such activities is subject to your consent.</p>

<ol start={12}>
	<li><strong>DIRECT MARKETING</strong></li>
</ol>

<p>We may use and disclose your personal information for the purpose of direct marketing to you via direct mail, email, SMS, MMS, targeted digital advertising or any other means of marketing communication, where:</p>

<ul>
	<li>you have consented to us doing so; or</li>
	<li>it is otherwise permitted by law.</li>
</ul>

<p>You may opt out of direct marketing communications at any time by contacting us or by using opt-out facilities set out in the direct marketing communications.</p>

<ol start={13}>
	<li><strong>COOKIES</strong></li>
</ol>

<p>A cookie is a small data file that is placed on your computer or mobile device when you visit a website. Website owners widely use cookies in order to make their websites work, or to work more efficiently, as well as to provide reporting information. We use cookies to:</p>

<ul>
	<li>personalise your visit to our Site (as a cookie allows a web server to &lsquo;remember&rsquo; visitors on subsequent visits without having to prompt them for information previously supplied. A cookie can also remember courses previously viewed by a site visitor);</li>
	<li>provide information about us to you while you browse; and</li>
	<li>obtain non-identifying information about your demographic group and general interests.</li>
</ul>

<p>You may elect to disable cookies and/or geolocation sharing at any time.</p>

<p>Our Site may contain links to other websites of interest. However, we note that once you have used those links to leave our Site we do not have control over that other website and are not responsible for the protection and privacy of any information you provide whilst visiting such sites, and such sites are not governed by this Privacy Policy. You should exercise caution and look at the privacy policy applicable to the site(s) in question.</p>

<ol start={14}>
	<li><strong>CHANGES TO THIS PRIVACY POLICY</strong></li>
</ol>

<p>We may update our Privacy Policy from time to time by publishing a new version on our Site. Our Privacy Policy was last updated on 1<sup>st</sup> November 2022. By continuing to use our website or otherwise continuing to deal with us, you accept this Privacy Policy as it applies from time to time.</p>

<ol start={15}>
	<li><strong>COMPLAINTS</strong></li>
</ol>

<p>We have procedures in place for dealing with complaints and concerns about our practices in relation to the Privacy Act, the APPs, and any alleged breach of this Privacy Policy. We will respond to your complaint in accordance with the relevant provisions of the APPs. For further information, please contact us.</p>

<ol start={16}>
	<li><strong>CONTACT</strong></li>
</ol>

<p>You can contact us via:</p>

<p>Privacy Officer<br />
Wholistic Meal Plans Pty Ltd<br />
PO BOX 217<br />
Nobby Beach<br />
QLD 4209 Australia<br />
Phone: 1300 443 456<br />
Email: <a href="mailto:support@wholisticmealplans.com.au">support@wholisticmealplans.com.au</a></p>
    </div>
</div>
    );
}