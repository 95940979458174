import _ from "lodash";
import ServiceConfig from 'services/ServiceConfig';
import { AuthService } from 'services/AuthService';

// GET - Query
export const get = (path: string) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  const url: string = `${ServiceConfig.apiUrl}` + path;
  return fetch(url, requestOptions).then(handleResponse);
}

// GET - File Download
export const download = (path: string) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  const url: string = `${ServiceConfig.apiUrl}` + path;
  return fetch(url, requestOptions).then((response) => { return response.blob(); });
}

// POST - Command Executor
export const execute = (commandName: string, command) => {
  const headers = {
      Authorization: bearerToken(),
      'Content-Type': 'application/json',
      'Command-Name': commandName,
  }

  const body = JSON.stringify(command, replacer);    
  const requestOptions = { method: 'POST', headers: headers, body: body };
  const url: string = `${ServiceConfig.apiUrl}` + `/commands/execute`;
  return fetch(url, requestOptions).then(handleResponse);
}

// File Upload Component
export const uploadUrl = `${ServiceConfig.apiUrl}` + `/commands/upload`;
export const uploadHeaders = (commandName: string, parentType: string, parentId: string) => {
  return {
    Authorization: bearerToken(),
    'Content-Type': 'application/json',
    'Command-Name': commandName,
    'Parent-Type': parentType,
    'Parent-Id': parentId,
  }
}

export const post = (path: string, content) => {
  const headers = {
      Authorization: bearerToken(),
      'Content-Type': 'application/json',
  }

  const body = JSON.stringify(content, replacer);    
  const requestOptions = { method: 'POST', headers: headers, body: body };
  const url: string = ServiceConfig.apiUrl + path;
  return fetch(url, requestOptions).then(handleResponse);
}

export function handleResponse(response: Response) {

  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        AuthService.logout();
      }

      // // The old way to do this. Now we just return the error.
      // const error = (data && data.message) || response.statusText;
      // return Promise.reject(error);
    }

    return data;
  });
}

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = AuthService.currentUserValue;
    if (currentUser && currentUser.token) {
      return { Authorization: `Bearer ${currentUser.token}` };
    } else {
      return {};
    }
  }

export function bearerToken() {
  // return jwt token
  const currentUser = AuthService.currentUserValue;
  if (currentUser && currentUser.token) {
    return `Bearer ${currentUser.token}`;
  } else {
    return undefined;
  }
}

// Replacer to change values when creating Json objects for APIs
export const replacer = (key, value) => {

  if (value === null){
    return null;
  }

  if (!!value && value.type == 'doc'){
    return;
  }

  // Return empty number strings as null
  if (value === ''){
      return null;
  }

  if (key === 'displayAsLiquid' || key === 'optional'){
    return value == 1 ? true : false;
  }

  if (key === 'externalId'){
    return value;
  }

  const listTypes = [ 'ingredients', 'instructions', 'tags', 'rows', 'cells', 'recipes' ];

  if ((_.includes(listTypes, key)) && !value.length){
    return [];
  }

  return value;
}