export interface IUser {
    id: string;
    userName: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    companyName: string;
    role: string;
    archived: boolean;
    imageId: string;
    imageUri: string;
    password: string;
    confirmPassword: string;
    expiry: string | null;
    stripeSetupIntentId: string;
    stripeCustomerId: string;
    stripeSubscriptionId: string;
}

export const UserInitialState: IUser = {
    id: '',
    userName: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    companyName: '',
    role: '',
    archived: false,
    imageId: '',
    imageUri: '',
    password: '',
    confirmPassword: '',
    expiry: null,
    stripeSetupIntentId: '',
    stripeCustomerId: '',
    stripeSubscriptionId: '',
}

export interface IEditProfile {
    id: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    companyName: string;
    imageId: string;
    imageUri: string;
    expiry: string | null;
}

export const EditProfileInitialState: IEditProfile = {
    id: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    companyName: '',
    imageId: '',
    imageUri: '',
    expiry: '',
}

export interface IEditPassword {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export const EditPasswordInitialState: IEditPassword = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
}