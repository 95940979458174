import React, { useEffect } from 'react';
import { AuthService } from 'services/AuthService';

export const Logout = () => {

  useEffect(() => {
    AuthService.logout();
  }, []);

  return (
      <div>
        <p>Signing you out...</p>
      </div>
    );
}